import React, { forwardRef, useState } from 'react'
import { useField } from 'formik'
import cx from 'classnames'

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  id?: string
  name: string
  type: string
  placeholder: string
  label?: string
  startIcon?: string
  className?: string
  containerClass?: string
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    { id, name, type, placeholder, label, startIcon, className, containerClass, ...props },
    ref
  ) => {
    const [field, meta] = useField(name)
    const [didFocus, setDidFocus] = useState(false)
    const handleFocus = () => setDidFocus(true)
    const showFeedback = didFocus || meta.touched

    return (
      <>
      {label && (<label htmlFor={id} className="label-input">
          {label}
        </label>)}
        
        <div className={containerClass ? containerClass : "mb-8" + " h-100"} >
          {startIcon && (
            <i className={`${startIcon} position-absolute left-0`}></i>
          )}
          <input
            {...props}
            {...field}
            className={cx('with-border', className)}
            id={id}
            ref={ref}
            name={name}
            type={type}
            placeholder={placeholder}
            onFocus={handleFocus}
          />
          {showFeedback && meta.error && (
            <div
              className={cx('w-100  position-absolute text-sm', {
                'text-danger': meta.error,
                'text-light': !meta.error
              })}
              style={{
                top: '46px',
              }}
              id={`${id}-help`}
              tabIndex={-1}
            >
              {meta.error}
            </div>
          )}
        </div>
      </>
    )
  }
)

Input.displayName = 'Input' // Optional: Helps with debugging React components

export default Input
