import React from "react";
import { useState } from "react";
import { Form, Formik, FormikHelpers } from "formik";
import { useModal } from "@/contexts/ModalContext";
import { useNavigate } from "react-router-dom";
import { signinSchema } from "@/libs/schemes/signin";
import { ISignin } from "@/types/userInd";
import { fetchLogin } from "@/api/user";
import Input from "@/components/Input";

import { Link } from "react-router-dom";
import store, { setUser } from "@/store";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { setTempAccessToken } from "@/store/user";

const RegisterInterfaceComponent = () => {
  const { showModal } = useModal();
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  
  const handleSubmit = async (
    values: ISignin, // Burada ISignup türünü kullanıyoruz
    { setSubmitting }: FormikHelpers<ISignin>
  ) => {
    setError(false);
    try {
      console.log(values.email, values.password);
      const response: any = await fetchLogin(values.email, values.password);
      if(response.role == 'WORKER'){
        toast.error('Mobil uygulamamızı kullanarak iş arayan hesabınıza giriş yapabilirsiniz.');
        return
      }
      
      if (response.access_token) {
        
        if(response.verification_required === "SMS"){
          await store.dispatch(setTempAccessToken(response));
          navigate("/login/verify-phone");
        }else if(response.verification_required === "MAIL"){
          await store.dispatch(setTempAccessToken(response));
          navigate("/login/verify-email");
        }else{
          
          await store.dispatch(setUser(response));
          navigate("/");
        }

        
      } else {
        setError(true);
        toast.error(response.message);
      }
    } catch (error) {
      setError(true);
      console.error("Beklenmeyen bir hata oluştu:", error);
    } finally {
      setSubmitting(false);
    }
  };

  const handleOnClick = () => {
    navigate("/register");
  };

  return (
    // <form onSubmit={formik.handleSubmit}>
    <div>
      <div
        id="sign-in-dialog"
        className="zoom-anim-dialog col-xl-12 dialog-with-tabs margin-top-50"
      >
        <div className="sign-in-form">
          <div className="popup-tabs-container">
            {/* Login Tabı */}

            <div className="popup-tab-content margin-top-50" id="login">
              <div className="welcome-text">
                <h3>Sizi tekrar görmekten memnunuz!</h3>
                <span>
                  Hesabınız yok mu? <a href="/register">Kayıt ol</a>
                </span>
              </div>

              <Formik
                id="login-form"
                initialValues={{
                  email: "",
                  password: "",
                }}
                onSubmit={handleSubmit}
                validationSchema={signinSchema}
              >
                {({ errors, touched }) => (
                  <Form id="login-form">
                    <div className="input-with-icon-left">
                      <i className="icon-material-baseline-mail-outline"></i>
                      <Input
                        type="text"
                        className="input-text with-border"
                        name="email"
                        id="email"
                        placeholder="Email Adresinizi Giriniz!"
                        required
                      />
                      {/* {errors.email && touched.email ? (
                        <div className="error-message">{errors.email}</div>
                      ) : null} */}
                    </div>

                    <div className="input-with-icon-left">
                      <i className="icon-material-outline-lock"></i>
                      <Input
                        type="password"
                        className="input-text with-border"
                        name="password"
                        id="password"
                        placeholder="Şifrenizi Giriniz!"
                        required
                      />
                      {/* {errors.password && touched.password ? (
                        <div className="error-message">{errors.password}</div>
                      ) : null} */}
                    </div>
                    <a href="/forgot-password" className="forgot-password">
                      Şifremi Unuttum?
                    </a>
                    {/* <Link to={"/"}> */}
                    <button
                      className="button full-width button-sliding-icon ripple-effect"
                      type="submit"
                      form="login-form"
                    >
                      Giriş Yap{" "}
                      <i className="icon-material-outline-arrow-right-alt"></i>
                    </button>
                    {/* </Link> */}
                  </Form>
                )}
              </Formik>

              <div className="social-login-separator">
                <span> </span>
              </div>
              <div className="section-headline centered margin-bottom-5 margin-top-15">
                <span className="welcome-text" style={{ textAlign: "center" }}>
                  Henüz Bir Hesabın Yok mu?
                </span>
              </div>
              <br />
              <div className="welcome-text">
                <h3>
                  Yeteneklerin buluştuğu, fırsatların keşfedildiği platform!{" "}
                </h3>
                <h3>Hemen üye ol!</h3>
              </div>

              <div className="popup-tab-content" id="register">
                <div className="account-type">
                  <div>
                    <input
                      type="radio"
                      name="account-type-radio"
                      id="employer-radio"
                      className="account-type-radio"
                      onClick={handleOnClick}
                      defaultChecked
                    />
                    <label
                      htmlFor="employer-radio"
                      className="ripple-effect-dark"
                    >
                      <i className="icon-material-outline-business-center"></i>{" "}
                      İş Veren
                    </label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      name="account-type-radio"
                      id="freelancer-radio-job-seeker"
                      className="account-type-radio"
                    />

                    <label
                      htmlFor="freelancer-radio"
                      className="ripple-effect-dark"
                      onClick={() => showModal("ind-register-forward-modal")}
                    >
                      <i className="icon-material-outline-account-circle"></i>{" "}
                      İş Arayan
                    </label>
                  </div>
                </div>
              </div>
            </div>

            {/* Register Tabı */}
          </div>
        </div>
      </div>
    </div>
    // <div>
    //   <div
    //     id="sign-in-dialog"
    //     className="zoom-anim-dialog col-md-12 dialog-with-tabs"
    //   >
    //     <div className="sign-in-form">
    //       {/* popup-tabs-nav içindeki default seçili öge Giriş yap olmalı, 2. seçenek kayıt ol olmalı */}
    //       <ul className="popup-tabs-nav">
    //         <li>
    //           <a href="#login">Giriş Yap</a>
    //         </li>
    //         <li>
    //           <a href="#register">Kayıt Ol</a>
    //         </li>
    //       </ul>

    //       <div className="popup-tabs-container">
    //         {/*
    //     <!-- Login --> */}
    //         <div className="popup-tab-content" id="login">
    //           {/*
    //         <!-- Welcome Text --> */}
    //           <div className="welcome-text">
    //             <h3>Sizi tekrar görmekten memnunuz!</h3>
    //             <span>
    //               Hesabınız Var mı?{" "}
    //               <a href="#" className="register-tab">
    //                 Kayıt ol!
    //               </a>
    //             </span>
    //           </div>

    //           {/* <!-- Form --> */}
    //           <form method="post" id="login-form">
    //             <div className="input-with-icon-left">
    //               <i className="icon-material-baseline-mail-outline"></i>
    //               <input
    //                 type="text"
    //                 className="input-text with-border"
    //                 name="emailaddress"
    //                 id="emailaddress"
    //                 placeholder="Email Adresinizi Giriniz!"
    //                 required
    //               />
    //             </div>

    //             <div className="input-with-icon-left">
    //               <i className="icon-material-outline-lock"></i>
    //               <input
    //                 type="password"
    //                 className="input-text with-border"
    //                 name="password"
    //                 id="password"
    //                 placeholder="Şifrenizi Giriniz!"
    //                 required
    //               />
    //             </div>
    //             <a href="#" className="forgot-password">
    //               Şifremi Unuttum?
    //             </a>
    //           </form>

    //           {/* <!-- Button --> */}
    //           <button
    //             className="button full-width button-sliding-icon ripple-effect"
    //             type="submit"
    //             form="login-form"
    //           >
    //             Giriş Yap!{" "}
    //             <i className="icon-material-outline-arrow-right-alt"></i>
    //           </button>

    //           {/* <!-- Social Login --> */}
    //           <div className="social-login-separator">
    //             <span>veya</span>
    //           </div>
    //           <div className="social-login-buttons">
    //             <button className="facebook-login ripple-effect">
    //               <i className="icon-brand-facebook-f"></i> Facebook ile Grişi
    //               Yap!
    //             </button>
    //             <button className="google-login ripple-effect">
    //               <i className="icon-brand-google-plus-g"></i> Google ile Giriş
    //               Yap!
    //             </button>
    //           </div>
    //         </div>

    //         {/* <!-- Register -->  bu kısım sayfa yüklendiğinde görünmemeli, eğer kullanıcı kayıt ol butonuna basarsa görünmeli*/}
    //         <div className="popup-tab-content" id="register">
    //           {/* <!-- Account Type --> */}
    //           <div className="account-type">
    //             <div>
    //               <input
    //                 type="radio"
    //                 name="account-type-radio"
    //                 id="freelancer-radio"
    //                 className="account-type-radio"
    //                 checked
    //               />
    //               <label
    //                 htmlFor="freelancer-radio"
    //                 className="ripple-effect-dark"
    //               >
    //                 <i className="icon-material-outline-account-circle"></i> İş
    //                 Arayan!
    //               </label>
    //             </div>

    //             <div>
    //               <input
    //                 type="radio"
    //                 name="account-type-radio"
    //                 id="employer-radio"
    //                 className="account-type-radio"
    //               />
    //               <label
    //                 htmlFor="employer-radio"
    //                 className="ripple-effect-dark"
    //               >
    //                 <i className="icon-material-outline-business-center"></i> İş
    //                 Veren!
    //               </label>
    //             </div>
    //           </div>

    //           {/* <!-- Social Login --> */}
    //           {/* <div className="social-login-separator">
    //             <span>or</span>
    //           </div>
    //           <div className="social-login-buttons">
    //             <button className="facebook-login ripple-effect">
    //               <i className="icon-brand-facebook-f"></i> Register via
    //               Facebook
    //             </button>
    //             <button className="google-login ripple-effect">
    //               <i className="icon-brand-google-plus-g"></i> Register via
    //               Google+
    //             </button>
    //           </div> */}
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};

export default RegisterInterfaceComponent;
