// src/components/MobileNav.tsx
import React, { LegacyRef, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useMenu } from '../contexts/MenuContext';
import NavItem from './NavItem';
import { employerLinks, jobSeekerLinks } from '@/libs/constants';

const MobileNav: React.FC = () => {
  const { showMenu, setShowMenu } = useMenu();
  const wrapperRef: LegacyRef<HTMLDivElement> | undefined = useRef(null);
  const [openSubmenu, setOpenSubmenu] = useState<string | null>(null);

  const handleSubmenuToggle = (submenuId: string) => {
    setOpenSubmenu(submenuId);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
        setShowMenu(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setShowMenu]);

  useEffect(() => {
    const html = document.documentElement;
    if (showMenu) {
      html.classList.add('mm-blocking', 'mm-opened', 'mm-background', 'mm-opening');
      wrapperRef.current?.classList.add('mm-opened');
    } else {
      html.classList.remove('mm-opening');
      setTimeout(() => {
        html.classList.remove('mm-blocking', 'mm-opened', 'mm-background');
        wrapperRef.current?.classList.remove('mm-opened');
      }, 400);
    }
  }, [showMenu]);

  return (
    <nav ref={wrapperRef} className={`mmenu-init mm-menu mm-offcanvas`} id="mm-4">
      <div className="mm-panels">
        <div className="mm-panel mm-hasnavbar mm-opened" id="mm-5">
          <div className="mm-navbar">
            <a className="mm-title">Menü</a>
          </div>
          <ul className="mm-listview">
            <li>
              <Link to="#" className="current">
              <i className="icon-line-awesome-home"></i> Anasayfa
              </Link>
            </li>

            <li>
              <Link to="/job-list" className="current">
              <i className="icon-line-awesome-calendar-check-o"></i> İş İlanları
              </Link>
            </li>

            <li>
              <Link to="/employer/company-info/create-job-posting" className="current">
              <i className="icon-material-outline-library-add"></i> İlan Ver
                </Link>
            </li>

            <li>
            <Link to="/contact" className="current">
            <i className="icon-feather-message-square"></i> İletişim
                </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default MobileNav;