// import profileImg from "@/images/user-avatar-placeholder.png";
// import loopArrowImg from "@/images/icons/loop-arrow.png";
// import Input from "@/components/Input";
// import Dropdown from "@/components/Dropdown";
// import {
//   fetchBankDetails,
//   updateBankDetails,
//   updatePassword,
//   fetchCreditCardList,
// } from "@/api/account";
// import {
//   COUNTRIES_OPTIONS,
//   COUNTRY_CODE_OPTIONS,
//   DISTRICT_OPTIONS,
//   GENDER_OPTIONS,
// } from "@/libs/constants";
// import DropdownWithSearch from "@/components/DropdownWithSearch";
// import { useEffect, useState } from "react";
// import Textarea from "@/components/TextArea";
// import { Formik, Form, FormikHelpers } from "formik";
// import { userInformationSchema } from "@/libs/schemes/userInformation";
// import {
//   fetchAccountInfo,
//   updateAccountInfo,
//   uploadProfileImage,
// } from "@/api/account";
// import { debug } from "console";
// import { fetchProvince } from "@/api/public";
// import { BarLoader, BounceLoader, ClipLoader } from "react-spinners";
// import { toast } from "react-toastify";
// import { fetchDistrict } from "@/api/public";

// const UserInformation = () => {
//   const [request, setRequest] = useState({
//     business_area_id: 0,
//     province_id: 0,
//     district_id: 0,
//     title: "",
//     description: "",
//     start_date: "",
//     end_date: "",
//     female_employee_count: 0,
//     male_employee_count: 0,
//     internship: true,
//     disabled: true,
//     position: null,
//     total_working_hours: 0,
//     working_hours: [],
//     salary: 0,
//     salary_extras: [],
//     attributes: [],
//   });

//   const [districts, setDistricts] = useState<any[]>([]);
//   const loadDistricts = async (provinceId: number) => {
//     const districtResponse = await fetchDistrict(provinceId);
//     setDistricts(
//       districtResponse.data.districts.map((district: any) => ({
//         value: district.id,
//         label: district.name,
//       }))
//     );
//   };

//   const [error, setError] = useState(false);
//   const [profileImage, setProfileImage] = useState<string>("");

//   const [accountInfo, setAccountInfo] = useState({
//     first_name: "",
//     last_name: "",
//     email: "",
//     phone: "",
//     dob: "",
//     gender: "",
//   });

//   const [bankDetails, setBankDetails] = useState({
//     holder_name: "",
//     iban: "",
//   });

//   const [cardDetails, setCardDetails] = useState({
//     holder_name: "",
//     card_number: "",
//     expiry_date: "",
//     cvc: "",
//   });

//   const [creditCards, setCreditCards] = useState<any[]>([]);

//   const [isLoading, setIsLoading] = useState(true);

//   const [provinces, setProvinces] = useState([]);

//   useEffect(() => {
//     fetchBankDetails().then((response) => {
//       if (response.data) setBankDetails(response.data);
//     });
//   }, []);

//   const handleCardDetailSubmit = async (e: any) => {};

//   const handleBankDetailSubmit = async (e: any) => {
//     e.preventDefault();
//     const response = await updateBankDetails(
//       bankDetails.holder_name,
//       bankDetails.iban
//     );
//     if (response.status === "0")
//       toast.success("Banka detayları başarıyla güncellendi");
//     else toast.error("Banka detayları güncellenirken bir hata oluştu");
//   };

//   useEffect(() => {
//     const fetchAccount = async () => {
//       const response = await fetchAccountInfo();

//       setAccountInfo(response.data);
//     };
//     const handleFetchProvinces = async () => {
//       const response = await fetchProvince();
//       setProvinces(response.data.provinces);
//     };
//     const fetchCreditCards = async () => {
//       const response = await fetchCreditCardList();
//       if (response.data) setCreditCards(response.data);
//     };

//     Promise.all([
//       fetchAccount(),
//       handleFetchProvinces(),
//       fetchCreditCards(),
//     ]).then(() => {
//       setIsLoading(false);
//     });
//   }, []);

//   const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//     const file = event.target.files?.[0];
//     debugger;
//     if (file) {
//       const reader = new FileReader();
//       reader.onloadend = () => {
//         const base64 = reader.result as string;
//         const name = file.name;
//         const type = file.type;
//         uploadProfileImage(name, type, base64).then((response) => {
//           if (response.status === 0) {
//             setProfileImage(base64);
//             toast.success("Profil resminiz başarıyla güncellendi");
//           } else {
//             toast.error(response.message);
//           }
//         });
//       };
//       reader.readAsDataURL(file);
//     }
//   };

//   const handleSelect = (value: string) => {
//     console.log("Selected:", value);
//   };

//   const handleSubmit = async (
//     values: any,
//     formikHelpers: FormikHelpers<any>
//   ) => {
//     if (
//       accountInfo.first_name !== values.firstName ||
//       accountInfo.last_name !== values.lastName ||
//       accountInfo.dob !== values.dob ||
//       accountInfo.gender !== values.gender
//     ) {
//       const response = await updateAccountInfo({
//         first_name: values.firstName,
//         last_name: values.lastName,
//         dob: values.dob,
//         gender: values.gender,
//       });
//       if (response.status == 0) {
//         toast.success("Bilgileriniz başarıyla güncellendi");
//       } else {
//         toast.error(response.message);
//       }
//     }

//     console.log("values:", values);
//     // Handle submission logic here
//     // setError(false)
//     // formikHelpers.setSubmitting(false)
//   };

//   return (
//     <>
//       {isLoading ? (
//         <BarLoader
//           loading={isLoading}
//           aria-label="Loading Spinner"
//           width={"100%"}
//         />
//       ) : (
//         <Formik
//           initialValues={{
//             identificationNumber: null,
//             firstName: accountInfo.first_name,
//             lastName: accountInfo.last_name,
//             dob: "",
//             gender: "",
//             phoneNumber: accountInfo.phone,
//             email: accountInfo.email,
//             city: "",
//             province: "",
//           }}
//           onSubmit={handleSubmit}
//         >
//           {({ isSubmitting, setFieldValue, getFieldMeta }) => (
//             <Form>
//               <div className="row">
//                 <div className="col-md-12">
//                   <div className="col-md-12 dashboard-box margin-top-0">
//                     <div className="headline">
//                       <h3>
//                         <i className="icon-material-outline-account-circle"></i>{" "}
//                         Kimlik Bilgilerim
//                       </h3>
//                     </div>
//                     <div className="content with-padding padding-bottom-0">
//                       <div className="row">
//                         <div className="col-auto">
//                           <div
//                             className="avatar-wrapper"
//                             data-tippy-placement="bottom"
//                             data-tippy=""
//                             data-original-title="Profil Fotoğrafını Değiştir"
//                           >
//                             <img
//                               className="profile-pic"
//                               src={profileImage || profileImg}
//                               alt=""
//                             />

//                             <button
//                               data-v-profile-picture=""
//                               className="profile-photo"
//                             >
//                               <img
//                                 data-v-76dbb04c=""
//                                 src={loopArrowImg}
//                                 alt=""
//                                 className="pp-edit"
//                               />
//                             </button>
//                             <div className="upload-button">
//                               <input
//                                 id="file-upload"
//                                 name="file-upload"
//                                 type="file"
//                                 accept="image/*"
//                                 onChange={handleImageChange}
//                                 placeholder="click me"
//                                 className="position-absolute top-0 bottom-0"
//                               />
//                             </div>
//                           </div>
//                         </div>
//                         <div className="col">
//                           <div className="row">
//                             <div className="col-md-3">
//                               <div className="submit-field">
//                                 <Input
//                                   id="firstName"
//                                   name="firstName"
//                                   type="text"
//                                   placeholder="Ahmet"
//                                   label="İsim"
//                                   value={accountInfo.first_name}
//                                 />
//                               </div>
//                             </div>
//                             <div className="col-md-3">
//                               <div className="submit-field">
//                                 <Input
//                                   id="lastName"
//                                   name="lastName"
//                                   type="text"
//                                   placeholder="TANCI"
//                                   label="Soyisim"
//                                 />
//                               </div>
//                             </div>

//                             <div className="col-md-3">
//                               <div className="submit-field">
//                                 <Input
//                                   id="dob"
//                                   name="dob"
//                                   type="text"
//                                   placeholder="gg/aa/yyyy"
//                                   label="Doğum Tarihi"
//                                 />
//                               </div>
//                             </div>
//                             <div className="col-md-3">
//                               <div className="submit-field">
//                                 <Dropdown
//                                   options={GENDER_OPTIONS}
//                                   placeholder="Cinsiyet seçin"
//                                   onSelect={handleSelect}
//                                   className="mb-3"
//                                   label="Cinsiyet"
//                                 />
//                               </div>
//                             </div>
//                           </div>
//                           <div className="row">
//                             <div className="col-md-6">
//                               <div className="submit-field">
//                                 <Input
//                                   label="Telefon Numaranız"
//                                   id="phoneNumber"
//                                   name="phoneNumber"
//                                   type="number"
//                                   placeholder="(555) 555 55 55"
//                                   containerClass="mb-0"
//                                 />
//                                 <p className="notification-msg-text">
//                                   Teklifler bu telefon numarasına ulaşacak
//                                 </p>
//                               </div>
//                             </div>
//                             <div className="col-md-6">
//                               <div className="submit-field">
//                                 <Input
//                                   label="E-posta Adresiniz"
//                                   id="email"
//                                   name="email"
//                                   type="email"
//                                   placeholder="mail@mail.com"
//                                   containerClass="mb-0"
//                                 />
//                                 <p className="notification-msg-text">
//                                   Teklifler bu e-posta adresine ulaşacak
//                                 </p>
//                               </div>
//                             </div>
//                           </div>
//                         </div>
//                       </div>
//                       <div className="row">
//                         <div className="col-md-4">
//                           <button
//                             className="button full-width button-sliding-icon ripple-effect margin-top-30"
//                             type="submit"
//                           >
//                             Bilgilerimi Güncelle{" "}
//                             <i className="icon-material-outline-arrow-right-alt"></i>
//                           </button>
//                         </div>
//                       </div>

//                       <br />
//                     </div>
//                   </div>
//                 </div>

//                 {/* Adres Bilgilerini Güncelleme */}

//                 <div className="col-md-12">
//                   <div className="col-md-12  dashboard-box margin-top-30">
//                     <div className="headline">
//                       <h3>
//                         <i className="icon-line-awesome-bank"></i> Adres
//                         Bilgileriniz
//                       </h3>
//                     </div>

//                     <div className="content">
//                       <ul className="fields-ul">
//                         <li>
//                           <div className="row">
//                             <div className="col-md-4">
//                               <div className="submit-field">
//                                 <DropdownWithSearch
//                                   options={provinces}
//                                   placeholder="İl"
//                                   label="İl"
//                                   onSelect={(e) => {
//                                     loadDistricts(parseInt(e));
//                                     setRequest({
//                                       ...request,
//                                       province_id: parseInt(e),
//                                     });
//                                     formik.setFieldValue(
//                                       "province_id",
//                                       parseInt(e)
//                                     );
//                                   }}
//                                 />
//                               </div>
//                             </div>

//                             <div className="col-md-4">
//                               <div className="submit-field">
//                                 <DropdownWithSearch
//                                   options={districts}
//                                   placeholder="İlçe"
//                                   label="İlçe"
//                                   onSelect={(e) => {
//                                     setRequest({
//                                       ...request,
//                                       district_id: parseInt(e),
//                                     });
//                                     formik.setFieldValue(
//                                       "district_id",
//                                       parseInt(e)
//                                     );
//                                   }}
//                                 />
//                               </div>
//                             </div>

//                             <div className="col-md-6">
//                               <div className="submit-field">
//                                 <h5>IBAN</h5>
//                                 <div className="input-with-icon-left">
//                                   <i className="icon-line-awesome-bank"></i>
//                                   <input
//                                     onChange={(e) =>
//                                       setBankDetails({
//                                         ...bankDetails,
//                                         iban: e.target.value,
//                                       })
//                                     }
//                                     value={bankDetails.iban}
//                                     type="number"
//                                     className="input-text with-border"
//                                     placeholder="IBAN"
//                                   />
//                                 </div>
//                               </div>
//                             </div>
//                           </div>
//                         </li>
//                       </ul>
//                     </div>
//                     <div className="col-md-4">
//                       <button
//                         className="button full-width button-sliding-icon ripple-effect margin-top-30"
//                         type="submit"
//                         onClick={handleBankDetailSubmit}
//                       >
//                         Bilgilerimi Güncelle{" "}
//                         <i className="icon-material-outline-arrow-right-alt"></i>
//                       </button>
//                     </div>
//                     <br />
//                   </div>
//                 </div>

//                 {/* Adres Bilgileri  Güncelleme Bitişi */}

//                 <div className="col-md-12">
//                   <div className="col-md-12  dashboard-box margin-top-30">
//                     <div className="headline">
//                       <h3>
//                         <i className="icon-line-awesome-bank"></i> Banka
//                         Hesabını Tanımla
//                       </h3>
//                       <div className="bank-account-line-d">
//                         <ul>
//                           <li className="bank-account-line-d">
//                             Hak ettiğin tüm ücretler tanımladığın banka hesabına
//                             aktarılacak
//                           </li>
//                         </ul>
//                       </div>
//                     </div>

//                     <div className="content">
//                       <ul className="fields-ul">
//                         <li>
//                           <div className="row">
//                             <div className="col-md-6">
//                               <div className="submit-field">
//                                 <h5>Adınız Soyadınız</h5>
//                                 <div className="input-with-icon-left">
//                                   <i className="icon-material-outline-account-circle"></i>
//                                   <input
//                                     value={bankDetails.holder_name}
//                                     onChange={(e) =>
//                                       setBankDetails({
//                                         ...bankDetails,
//                                         holder_name: e.target.value,
//                                       })
//                                     }
//                                     type="text"
//                                     className="input-text with-border"
//                                     placeholder="Adınız Soyadınız"
//                                   />
//                                 </div>
//                               </div>
//                             </div>

//                             <div className="col-md-6">
//                               <div className="submit-field">
//                                 <h5>IBAN</h5>
//                                 <div className="input-with-icon-left">
//                                   <i className="icon-line-awesome-bank"></i>
//                                   <input
//                                     onChange={(e) =>
//                                       setBankDetails({
//                                         ...bankDetails,
//                                         iban: e.target.value,
//                                       })
//                                     }
//                                     value={bankDetails.iban}
//                                     type="number"
//                                     className="input-text with-border"
//                                     placeholder="IBAN"
//                                   />
//                                 </div>
//                               </div>
//                             </div>
//                           </div>
//                         </li>
//                       </ul>
//                     </div>
//                     <div className="col-md-4">
//                       <button
//                         className="button full-width button-sliding-icon ripple-effect margin-top-30"
//                         type="submit"
//                         onClick={handleBankDetailSubmit}
//                       >
//                         Bilgilerimi Güncelle{" "}
//                         <i className="icon-material-outline-arrow-right-alt"></i>
//                       </button>
//                     </div>
//                     <br />
//                   </div>
//                 </div>

//                 <div className="col-md-12">
//                   <div className="col-md-12  dashboard-box margin-top-30">
//                     <div className="headline">
//                       <h3>
//                         <i className="icon-material-outline-credit-card"></i>{" "}
//                         Kredi Kartı Ekle
//                       </h3>
//                       <div className="bank-account-line-d">
//                         <ul>
//                           <li className="bank-account-line-d">
//                             Buradan sisteme yeni kredi kartı kaydedebilirsiniz
//                           </li>
//                         </ul>
//                       </div>
//                     </div>

//                     <div className="content">
//                       <ul className="fields-ul">
//                         <li>
//                           <div className="row">
//                             <div className="col-md-4">
//                               <div className="submit-field">
//                                 <h5>Adınız Soyadınız</h5>
//                                 <div className="input-with-icon-left">
//                                   <i className="icon-material-outline-account-circle"></i>
//                                   <input
//                                     value={cardDetails.holder_name}
//                                     onChange={(e) =>
//                                       setCardDetails({
//                                         ...cardDetails,
//                                         holder_name: e.target.value,
//                                       })
//                                     }
//                                     type="text"
//                                     className="input-text with-border"
//                                     placeholder="Adınız Soyadınız"
//                                   />
//                                 </div>
//                               </div>
//                             </div>

//                             <div className="col-md-4">
//                               <div className="submit-field">
//                                 <h5>Kredi Kart Numaranız</h5>
//                                 <div className="input-with-icon-left">
//                                   <i className="icon-line-awesome-bank"></i>
//                                   <input
//                                     onChange={(e) =>
//                                       setCardDetails({
//                                         ...cardDetails,
//                                         card_number: e.target.value,
//                                       })
//                                     }
//                                     value={cardDetails.card_number}
//                                     type="number"
//                                     className="input-text with-border"
//                                     placeholder="Kredi Kartı Numarası"
//                                   />
//                                 </div>
//                               </div>
//                             </div>

//                             <div className="col-md-2">
//                               <div className="submit-field">
//                                 <h5>SKT</h5>
//                                 <div className="input-with-icon-left">
//                                   <i className="icon-line-awesome-bank"></i>
//                                   <input
//                                     onChange={(e) =>
//                                       setCardDetails({
//                                         ...cardDetails,
//                                         expiry_date: e.target.value,
//                                       })
//                                     }
//                                     value={cardDetails.expiry_date}
//                                     type="number"
//                                     className="input-text with-border"
//                                     placeholder="01/27"
//                                   />
//                                 </div>
//                               </div>
//                             </div>

//                             <div className="col-md-2">
//                               <div className="submit-field">
//                                 <h5>CVC</h5>
//                                 <div className="input-with-icon-left">
//                                   <i className="icon-line-awesome-bank"></i>
//                                   <input
//                                     onChange={(e) =>
//                                       setCardDetails({
//                                         ...cardDetails,
//                                         cvc: e.target.value,
//                                       })
//                                     }
//                                     value={cardDetails.cvc}
//                                     type="number"
//                                     className="input-text with-border"
//                                     placeholder="CVC"
//                                   />
//                                 </div>
//                               </div>
//                             </div>
//                           </div>
//                         </li>
//                       </ul>
//                     </div>
//                     <div className="col-md-4">
//                       <button
//                         className="button full-width button-sliding-icon ripple-effect margin-top-30"
//                         type="submit"
//                         onClick={handleCardDetailSubmit}
//                       >
//                         Kredi Kartı Bilgilerimi Kaydet{" "}
//                         <i className="icon-material-outline-arrow-right-alt"></i>
//                       </button>
//                     </div>
//                     <br />
//                   </div>
//                 </div>

//                 <div className="col-md-12">
//                   <div className="col-md-12  dashboard-box margin-top-30">
//                     <div className="headline">
//                       <h3>
//                         <i className="icon-material-outline-credit-card"></i>{" "}
//                         Kredi Kartlarım
//                       </h3>
//                       <div className="bank-account-line-d">
//                         <ul>
//                           <li className="bank-account-line-d">
//                             Sisteme kayıtlı kredi kartlarınızı buradan
//                             görüntüleyebilirsiniz
//                           </li>
//                         </ul>
//                       </div>
//                     </div>

//                     <div className="content">
//                       <ul className="dashboard-box-list">
//                         {/* Render credit card list dynamically */}
//                         {creditCards.length > 0 ? (
//                           creditCards.map((card, index) => (
//                             <li key={index}>
//                               <div className="invoice-list-item">
//                                 <strong>
//                                   {card.holder_name || "Ad Soyad"}
//                                 </strong>
//                                 <ul>
//                                   <li>
//                                     <span className="paid">
//                                       {card.card_type || "Kredi Kartı"}
//                                     </span>
//                                   </li>
//                                   <li>SKT: {card.expiry_date || "N/A"}</li>
//                                   <li>
//                                     NO:{" "}
//                                     {card.card_number || "****-****-****-****"}
//                                   </li>
//                                 </ul>
//                               </div>
//                               <div className="buttons-to-right single-right-button">
//                                 <a
//                                   href="#"
//                                   className="button red ripple-effect ico"
//                                 >
//                                   <i className="icon-feather-trash-2"></i>
//                                 </a>
//                               </div>
//                             </li>
//                           ))
//                         ) : (
//                           <li>Kayıtlı kredi kartı yok!</li>
//                         )}
//                       </ul>
//                     </div>

//                     <br />
//                   </div>
//                 </div>
//               </div>
//             </Form>
//           )}
//         </Formik>
//       )}
//     </>
//   );
// };

// export default UserInformation;

// import profileImg from "@/images/user-avatar-placeholder.png";
// import loopArrowImg from "@/images/icons/loop-arrow.png";
// import Input from "@/components/Input";
// import Dropdown from "@/components/Dropdown";
// import {
//   fetchBankDetails,
//   updateBankDetails,
//   updatePassword,
//   fetchCreditCardList,
// } from "@/api/account";
// import {
//   COUNTRIES_OPTIONS,
//   COUNTRY_CODE_OPTIONS,
//   DISTRICT_OPTIONS,
//   GENDER_OPTIONS,
// } from "@/libs/constants";
// import DropdownWithSearch from "@/components/DropdownWithSearch";
// import { useEffect, useState } from "react";
// import Textarea from "@/components/TextArea";
// import { Formik, Form, FormikHelpers } from "formik";
// import { userInformationSchema } from "@/libs/schemes/userInformation";
// import {
//   fetchAccountInfo,
//   updateAccountInfo,
//   uploadProfileImage,
// } from "@/api/account";
// import { fetchProvince, fetchDistrict } from "@/api/public";
// import { BarLoader } from "react-spinners";
// import { toast } from "react-toastify";

// const UserInformation = () => {
//   const [request, setRequest] = useState({
//     business_area_id: 0,
//     province_id: 0,
//     district_id: 0,
//     title: "",
//     description: "",
//     start_date: "",
//     end_date: "",
//     female_employee_count: 0,
//     male_employee_count: 0,
//     internship: true,
//     disabled: true,
//     position: null,
//     total_working_hours: 0,
//     working_hours: [],
//     salary: 0,
//     salary_extras: [],
//     attributes: [],
//   });

//   const [districts, setDistricts] = useState<any[]>([]);
//   const loadDistricts = async (provinceId: number) => {
//     const districtResponse = await fetchDistrict(provinceId);
//     setDistricts(
//       districtResponse.data.districts.map((district: any) => ({
//         value: district.id,
//         label: district.name,
//       }))
//     );
//   };

//   const [error, setError] = useState(false);
//   const [profileImage, setProfileImage] = useState<string>("");

//   const [accountInfo, setAccountInfo] = useState({
//     first_name: "",
//     last_name: "",
//     email: "",
//     phone: "",
//     dob: "",
//     gender: "",
//   });

//   const [bankDetails, setBankDetails] = useState({
//     holder_name: "",
//     iban: "",
//   });

//   const [cardDetails, setCardDetails] = useState({
//     holder_name: "",
//     card_number: "",
//     expiry_date: "",
//     cvc: "",
//   });

//   const [creditCards, setCreditCards] = useState<any[]>([]);

//   const [isLoading, setIsLoading] = useState(true);

//   const [provinces, setProvinces] = useState([]);

//   useEffect(() => {
//     fetchBankDetails().then((response) => {
//       if (response.data) setBankDetails(response.data);
//     });
//   }, []);

//   const handleCardDetailSubmit = async (e: any) => {};

//   const handleBankDetailSubmit = async (e: any) => {
//     e.preventDefault();
//     const response = await updateBankDetails(
//       bankDetails.holder_name,
//       bankDetails.iban
//     );
//     if (response.status === "0")
//       toast.success("Banka detayları başarıyla güncellendi");
//     else toast.error("Banka detayları güncellenirken bir hata oluştu");
//   };

//   useEffect(() => {
//     const fetchAccount = async () => {
//       const response = await fetchAccountInfo();

//       setAccountInfo(response.data);
//     };
//     const handleFetchProvinces = async () => {
//       const response = await fetchProvince();
//       setProvinces(response.data.provinces);
//     };
//     const fetchCreditCards = async () => {
//       const response = await fetchCreditCardList();
//       if (response.data) setCreditCards(response.data);
//     };

//     Promise.all([
//       fetchAccount(),
//       handleFetchProvinces(),
//       fetchCreditCards(),
//     ]).then(() => {
//       setIsLoading(false);
//     });
//   }, []);

//   const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//     const file = event.target.files?.[0];
//     if (file) {
//       const reader = new FileReader();
//       reader.onloadend = () => {
//         const base64 = reader.result as string;
//         const name = file.name;
//         const type = file.type;
//         uploadProfileImage(name, type, base64).then((response) => {
//           if (response.status === 0) {
//             setProfileImage(base64);
//             toast.success("Profil resminiz başarıyla güncellendi");
//           } else {
//             toast.error(response.message);
//           }
//         });
//       };
//       reader.readAsDataURL(file);
//     }
//   };

//   const handleSelect = (value: string) => {
//     console.log("Selected:", value);
//   };

//   const handleSubmit = async (
//     values: any,
//     formikHelpers: FormikHelpers<any>
//   ) => {
//     if (
//       accountInfo.first_name !== values.firstName ||
//       accountInfo.last_name !== values.lastName ||
//       accountInfo.dob !== values.dob ||
//       accountInfo.gender !== values.gender
//     ) {
//       const response = await updateAccountInfo({
//         first_name: values.firstName,
//         last_name: values.lastName,
//         dob: values.dob,
//         gender: values.gender,
//       });
//       if (response.status == 0) {
//         toast.success("Bilgileriniz başarıyla güncellendi");
//       } else {
//         toast.error(response.message);
//       }
//     }

//     console.log("values:", values);
//   };

//   return (
//     <>
//       {isLoading ? (
//         <BarLoader
//           loading={isLoading}
//           aria-label="Loading Spinner"
//           width={"100%"}
//         />
//       ) : (
//         <Formik
//           initialValues={{
//             identificationNumber: null,
//             firstName: accountInfo.first_name,
//             lastName: accountInfo.last_name,
//             dob: "",
//             gender: "",
//             phoneNumber: accountInfo.phone,
//             email: accountInfo.email,
//             city: "",
//             province: "",
//           }}
//           onSubmit={handleSubmit}
//         >
//           {({ isSubmitting, setFieldValue, getFieldMeta }) => (
//             <Form>
//               <div className="row">
//                 <div className="col-md-12">
//                   <div className="col-md-12 dashboard-box margin-top-0">
//                     <div className="headline">
//                       <h3>
//                         <i className="icon-material-outline-account-circle"></i>{" "}
//                         Kimlik Bilgilerim
//                       </h3>
//                     </div>
//                     <div className="content with-padding padding-bottom-0">
//                       <div className="row">
//                         <div className="col-auto">
//                           <div
//                             className="avatar-wrapper"
//                             data-tippy-placement="bottom"
//                             data-tippy=""
//                             data-original-title="Profil Fotoğrafını Değiştir"
//                           >
//                             <img
//                               className="profile-pic"
//                               src={profileImage || profileImg}
//                               alt=""
//                             />

//                             <button
//                               data-v-profile-picture=""
//                               className="profile-photo"
//                             >
//                               <img
//                                 data-v-76dbb04c=""
//                                 src={loopArrowImg}
//                                 alt=""
//                                 className="pp-edit"
//                               />
//                             </button>
//                             <div className="upload-button">
//                               <input
//                                 id="file-upload"
//                                 name="file-upload"
//                                 type="file"
//                                 accept="image/*"
//                                 onChange={handleImageChange}
//                                 placeholder="click me"
//                                 className="position-absolute top-0 bottom-0"
//                               />
//                             </div>
//                           </div>
//                         </div>
//                         <div className="col">
//                           <div className="row">
//                             <div className="col-md-3">
//                               <div className="submit-field">
//                                 <Input
//                                   id="firstName"
//                                   name="firstName"
//                                   type="text"
//                                   placeholder="Ahmet"
//                                   label="İsim"
//                                   value={accountInfo.first_name}
//                                 />
//                               </div>
//                             </div>
//                             <div className="col-md-3">
//                               <div className="submit-field">
//                                 <Input
//                                   id="lastName"
//                                   name="lastName"
//                                   type="text"
//                                   placeholder="TANCI"
//                                   label="Soyisim"
//                                 />
//                               </div>
//                             </div>

//                             <div className="col-md-3">
//                               <div className="submit-field">
//                                 <Input
//                                   id="dob"
//                                   name="dob"
//                                   type="text"
//                                   placeholder="gg/aa/yyyy"
//                                   label="Doğum Tarihi"
//                                 />
//                               </div>
//                             </div>
//                             <div className="col-md-3">
//                               <div className="submit-field">
//                                 <Dropdown
//                                   options={GENDER_OPTIONS}
//                                   placeholder="Cinsiyet seçin"
//                                   onSelect={handleSelect}
//                                   className="mb-3"
//                                   label="Cinsiyet"
//                                 />
//                               </div>
//                             </div>
//                           </div>
//                           <div className="row">
//                             <div className="col-md-6">
//                               <div className="submit-field">
//                                 <Input
//                                   label="Telefon Numaranız"
//                                   id="phoneNumber"
//                                   name="phoneNumber"
//                                   type="number"
//                                   placeholder="(555) 555 55 55"
//                                   containerClass="mb-0"
//                                 />
//                                 <p className="notification-msg-text">
//                                   Teklifler bu telefon numarasına ulaşacak
//                                 </p>
//                               </div>
//                             </div>
//                             <div className="col-md-6">
//                               <div className="submit-field">
//                                 <Input
//                                   label="E-posta Adresiniz"
//                                   id="email"
//                                   name="email"
//                                   type="email"
//                                   placeholder="mail@mail.com"
//                                   containerClass="mb-0"
//                                 />
//                                 <p className="notification-msg-text">
//                                   Teklifler bu e-posta adresine ulaşacak
//                                 </p>
//                               </div>
//                             </div>
//                           </div>
//                         </div>
//                       </div>
//                       <div className="row">
//                         <div className="col-md-4">
//                           <button
//                             className="button full-width button-sliding-icon ripple-effect margin-top-30"
//                             type="submit"
//                           >
//                             Bilgilerimi Güncelle{" "}
//                             <i className="icon-material-outline-arrow-right-alt"></i>
//                           </button>
//                         </div>
//                       </div>

//                       <br />
//                     </div>
//                   </div>
//                 </div>

//                 {/* Adres Bilgilerini Güncelleme */}

//                 <div className="col-md-12">
//                   <div className="col-md-12  dashboard-box margin-top-30">
//                     <div className="headline">
//                       <h3>
//                         <i className="icon-line-awesome-bank"></i> Adres
//                         Bilgileriniz
//                       </h3>
//                     </div>

//                     <div className="content">
//                       <ul className="fields-ul">
//                         <li>
//                           <div className="row">
//                             <div className="col-md-4">
//                               <div className="submit-field">
//                                 <DropdownWithSearch
//                                   options={provinces}
//                                   placeholder="İl"
//                                   label="İl"
//                                   onSelect={(e) => {
//                                     loadDistricts(parseInt(e));
//                                     setRequest({
//                                       ...request,
//                                       province_id: parseInt(e),
//                                     });
//                                     setFieldValue("province_id", parseInt(e));
//                                   }}
//                                 />
//                               </div>
//                             </div>

//                             <div className="col-md-4">
//                               <div className="submit-field">
//                                 <DropdownWithSearch
//                                   options={districts}
//                                   placeholder="İlçe"
//                                   label="İlçe"
//                                   onSelect={(e) => {
//                                     setRequest({
//                                       ...request,
//                                       district_id: parseInt(e),
//                                     });
//                                     setFieldValue("district_id", parseInt(e));
//                                   }}
//                                 />
//                               </div>
//                             </div>

//                             <Textarea
//                               placeholder="Açık adresinizi yazınız!"
//                               className="with-border"
//                             />
//                           </div>
//                         </li>
//                       </ul>
//                     </div>
//                     <div className="col-md-4">
//                       <button
//                         className="button full-width button-sliding-icon ripple-effect margin-top-30"
//                         type="submit"
//                         onClick={handleBankDetailSubmit}
//                       >
//                         Bilgilerimi Güncelle{" "}
//                         <i className="icon-material-outline-arrow-right-alt"></i>
//                       </button>
//                     </div>
//                     <br />
//                   </div>
//                 </div>

//                 {/* Adres Bilgileri  Güncelleme Bitişi */}

//                 <div className="col-md-12">
//                   <div className="col-md-12  dashboard-box margin-top-30">
//                     <div className="headline">
//                       <h3>
//                         <i className="icon-line-awesome-bank"></i> Banka
//                         Hesabını Tanımla
//                       </h3>
//                       <div className="bank-account-line-d">
//                         <ul>
//                           <li className="bank-account-line-d">
//                             Hak ettiğin tüm ücretler tanımladığın banka hesabına
//                             aktarılacak
//                           </li>
//                         </ul>
//                       </div>
//                     </div>

//                     <div className="content">
//                       <ul className="fields-ul">
//                         <li>
//                           <div className="row">
//                             <div className="col-md-6">
//                               <div className="submit-field">
//                                 <h5>Adınız Soyadınız</h5>
//                                 <div className="input-with-icon-left">
//                                   <i className="icon-material-outline-account-circle"></i>
//                                   <input
//                                     value={bankDetails.holder_name}
//                                     onChange={(e) =>
//                                       setBankDetails({
//                                         ...bankDetails,
//                                         holder_name: e.target.value,
//                                       })
//                                     }
//                                     type="text"
//                                     className="input-text with-border"
//                                     placeholder="Adınız Soyadınız"
//                                   />
//                                 </div>
//                               </div>
//                             </div>

//                             <div className="col-md-6">
//                               <div className="submit-field">
//                                 <h5>IBAN</h5>
//                                 <div className="input-with-icon-left">
//                                   <i className="icon-line-awesome-bank"></i>
//                                   <input
//                                     onChange={(e) =>
//                                       setBankDetails({
//                                         ...bankDetails,
//                                         iban: e.target.value,
//                                       })
//                                     }
//                                     value={bankDetails.iban}
//                                     type="number"
//                                     className="input-text with-border"
//                                     placeholder="IBAN"
//                                   />
//                                 </div>
//                               </div>
//                             </div>
//                           </div>
//                         </li>
//                       </ul>
//                     </div>
//                     <div className="col-md-4">
//                       <button
//                         className="button full-width button-sliding-icon ripple-effect margin-top-30"
//                         type="submit"
//                         onClick={handleBankDetailSubmit}
//                       >
//                         Bilgilerimi Güncelle{" "}
//                         <i className="icon-material-outline-arrow-right-alt"></i>
//                       </button>
//                     </div>
//                     <br />
//                   </div>
//                 </div>

//                 <div className="col-md-12">
//                   <div className="col-md-12  dashboard-box margin-top-30">
//                     <div className="headline">
//                       <h3>
//                         <i className="icon-material-outline-credit-card"></i>{" "}
//                         Kredi Kartı Ekle
//                       </h3>
//                       <div className="bank-account-line-d">
//                         <ul>
//                           <li className="bank-account-line-d">
//                             Buradan sisteme yeni kredi kartı kaydedebilirsiniz
//                           </li>
//                         </ul>
//                       </div>
//                     </div>

//                     <div className="content">
//                       <ul className="fields-ul">
//                         <li>
//                           <div className="row">
//                             <div className="col-md-4">
//                               <div className="submit-field">
//                                 <h5>Adınız Soyadınız</h5>
//                                 <div className="input-with-icon-left">
//                                   <i className="icon-material-outline-account-circle"></i>
//                                   <input
//                                     value={cardDetails.holder_name}
//                                     onChange={(e) =>
//                                       setCardDetails({
//                                         ...cardDetails,
//                                         holder_name: e.target.value,
//                                       })
//                                     }
//                                     type="text"
//                                     className="input-text with-border"
//                                     placeholder="Adınız Soyadınız"
//                                   />
//                                 </div>
//                               </div>
//                             </div>

//                             <div className="col-md-4">
//                               <div className="submit-field">
//                                 <h5>Kredi Kart Numaranız</h5>
//                                 <div className="input-with-icon-left">
//                                   <i className="icon-line-awesome-bank"></i>
//                                   <input
//                                     onChange={(e) =>
//                                       setCardDetails({
//                                         ...cardDetails,
//                                         card_number: e.target.value,
//                                       })
//                                     }
//                                     value={cardDetails.card_number}
//                                     type="number"
//                                     className="input-text with-border"
//                                     placeholder="Kredi Kartı Numarası"
//                                   />
//                                 </div>
//                               </div>
//                             </div>

//                             <div className="col-md-2">
//                               <div className="submit-field">
//                                 <h5>SKT</h5>
//                                 <div className="input-with-icon-left">
//                                   <i className="icon-line-awesome-bank"></i>
//                                   <input
//                                     onChange={(e) =>
//                                       setCardDetails({
//                                         ...cardDetails,
//                                         expiry_date: e.target.value,
//                                       })
//                                     }
//                                     value={cardDetails.expiry_date}
//                                     type="number"
//                                     className="input-text with-border"
//                                     placeholder="01/27"
//                                   />
//                                 </div>
//                               </div>
//                             </div>

//                             <div className="col-md-2">
//                               <div className="submit-field">
//                                 <h5>CVC</h5>
//                                 <div className="input-with-icon-left">
//                                   <i className="icon-line-awesome-bank"></i>
//                                   <input
//                                     onChange={(e) =>
//                                       setCardDetails({
//                                         ...cardDetails,
//                                         cvc: e.target.value,
//                                       })
//                                     }
//                                     value={cardDetails.cvc}
//                                     type="number"
//                                     className="input-text with-border"
//                                     placeholder="CVC"
//                                   />
//                                 </div>
//                               </div>
//                             </div>
//                           </div>
//                         </li>
//                       </ul>
//                     </div>
//                     <div className="col-md-4">
//                       <button
//                         className="button full-width button-sliding-icon ripple-effect margin-top-30"
//                         type="submit"
//                         onClick={handleCardDetailSubmit}
//                       >
//                         Kredi Kartı Bilgilerimi Kaydet{" "}
//                         <i className="icon-material-outline-arrow-right-alt"></i>
//                       </button>
//                     </div>
//                     <br />
//                   </div>
//                 </div>

//                 <div className="col-md-12">
//                   <div className="col-md-12  dashboard-box margin-top-30">
//                     <div className="headline">
//                       <h3>
//                         <i className="icon-material-outline-credit-card"></i>{" "}
//                         Kredi Kartlarım
//                       </h3>
//                       <div className="bank-account-line-d">
//                         <ul>
//                           <li className="bank-account-line-d">
//                             Sisteme kayıtlı kredi kartlarınızı buradan
//                             görüntüleyebilirsiniz
//                           </li>
//                         </ul>
//                       </div>
//                     </div>

//                     <div className="content">
//                       <ul className="dashboard-box-list">
//                         {creditCards.length > 0 ? (
//                           creditCards.map((card, index) => (
//                             <li key={index}>
//                               <div className="invoice-list-item">
//                                 <strong>
//                                   {card.holder_name || "Ad Soyad"}
//                                 </strong>
//                                 <ul>
//                                   <li>
//                                     <span className="paid">
//                                       {card.card_type || "Kredi Kartı"}
//                                     </span>
//                                   </li>
//                                   <li>SKT: {card.expiry_date || "N/A"}</li>
//                                   <li>
//                                     NO:{" "}
//                                     {card.card_number || "****-****-****-****"}
//                                   </li>
//                                 </ul>
//                               </div>
//                               <div className="buttons-to-right single-right-button">
//                                 <a
//                                   href="#"
//                                   className="button red ripple-effect ico"
//                                 >
//                                   <i className="icon-feather-trash-2"></i>
//                                 </a>
//                               </div>
//                             </li>
//                           ))
//                         ) : (
//                           <li>Kayıtlı kredi kartı yok!</li>
//                         )}
//                       </ul>
//                     </div>

//                     <br />
//                   </div>
//                 </div>
//               </div>
//             </Form>
//           )}
//         </Formik>
//       )}
//     </>
//   );
// };

// // export default UserInformation;
// import profileImg from "@/images/user-avatar-placeholder.png";
// import loopArrowImg from "@/images/icons/loop-arrow.png";
// import Input from "@/components/Input";
// import Dropdown from "@/components/Dropdown";
// import DropdownWithSearch from "@/components/DropdownWithSearch";
// import Textarea from "@/components/TextArea";
// import { useEffect, useState } from "react";
// import { Formik, Form, FormikHelpers } from "formik";
// import { BarLoader } from "react-spinners";
// import { toast } from "react-toastify";
// import { GENDER_OPTIONS } from "@/libs/constants";

// import {
//   fetchBankDetails,
//   updateBankDetails,
//   fetchCreditCardList,
//   fetchAccountInfo,
//   updateAccountInfo,
//   uploadProfileImage,
// } from "@/api/account";

// import { fetchProvince, fetchDistrict } from "@/api/public";
// import { updateAccountAddress } from "@/api/account";

// const UserInformation = () => {
//   const [request, setRequest] = useState({
//     business_area_id: 0,
//     province_id: 0,
//     district_id: 0,
//     title: "",
//     description: "",
//     start_date: "",
//     end_date: "",
//     female_employee_count: 0,
//     male_employee_count: 0,
//     internship: true,
//     disabled: true,
//     position: null,
//     total_working_hours: 0,
//     working_hours: [],
//     salary: 0,
//     salary_extras: [],
//     attributes: [],
//   });

//   const [districts, setDistricts] = useState<any[]>([]);
//   const loadDistricts = async (provinceId: number) => {
//     const districtResponse = await fetchDistrict(provinceId);
//     setDistricts(
//       districtResponse.data.districts.map((district: any) => ({
//         value: district.id,
//         label: district.name,
//       }))
//     );
//   };

//   const [error, setError] = useState(false);
//   const [profileImage, setProfileImage] = useState<string>("");
//   const [accountInfo, setAccountInfo] = useState({
//     first_name: "",
//     last_name: "",
//     email: "",
//     phone: "",
//     dob: "",
//     gender: "",
//   });

//   const [bankDetails, setBankDetails] = useState({
//     holder_name: "",
//     iban: "",
//   });

//   const [cardDetails, setCardDetails] = useState({
//     holder_name: "",
//     card_number: "",
//     expiry_date: "",
//     cvc: "",
//   });

//   const [creditCards, setCreditCards] = useState<any[]>([]);
//   const [isLoading, setIsLoading] = useState(true);
//   const [provinces, setProvinces] = useState([]);

//   useEffect(() => {
//     fetchBankDetails().then((response) => {
//       if (response.data) setBankDetails(response.data);
//     });
//   }, []);

//   const handleCardDetailSubmit = async (e: any) => {
//     e.preventDefault();
//     // Add your card submission logic here
//   };

//   const handleBankDetailSubmit = async (e: any) => {
//     e.preventDefault();
//     const response = await updateBankDetails(
//       bankDetails.holder_name,
//       bankDetails.iban
//     );
//     if (response.status === "0") {
//       toast.success("Banka detayları başarıyla güncellendi");
//     } else {
//       toast.error("Banka detayları güncellenirken bir hata oluştu");
//     }
//   };

//   useEffect(() => {
//     const fetchAccount = async () => {
//       const response = await fetchAccountInfo();
//       setAccountInfo(response.data);
//     };

//     const handleFetchProvinces = async () => {
//       const response = await fetchProvince();
//       setProvinces(
//         response.data.provinces.map((province: any) => ({
//           value: province.id,
//           label: province.name,
//         }))
//       );
//     };

//     const fetchCreditCards = async () => {
//       const response = await fetchCreditCardList();
//       if (response.data) setCreditCards(response.data);
//     };

//     Promise.all([
//       fetchAccount(),
//       handleFetchProvinces(),
//       fetchCreditCards(),
//     ]).then(() => {
//       setIsLoading(false);
//     });
//   }, []);

//   const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//     const file = event.target.files?.[0];
//     if (file) {
//       const reader = new FileReader();
//       reader.onloadend = () => {
//         const base64 = reader.result as string;
//         const name = file.name;
//         const type = file.type;
//         uploadProfileImage(name, type, base64).then((response) => {
//           if (response.status === 0) {
//             setProfileImage(base64);
//             toast.success("Profil resminiz başarıyla güncellendi");
//           } else {
//             toast.error(response.message);
//           }
//         });
//       };
//       reader.readAsDataURL(file);
//     }
//   };

//   const handleSelect = (value: string) => {
//     console.log("Selected:", value);
//   };

//   const handleSubmit = async (
//     values: any,
//     formikHelpers: FormikHelpers<any>
//   ) => {
//     if (
//       accountInfo.first_name !== values.firstName ||
//       accountInfo.last_name !== values.lastName ||
//       accountInfo.dob !== values.dob ||
//       accountInfo.gender !== values.gender
//     ) {
//       const response = await updateAccountInfo({
//         first_name: values.firstName,
//         last_name: values.lastName,
//         dob: values.dob,
//         gender: values.gender,
//       });
//       if (response.status == 0) {
//         toast.success("Bilgileriniz başarıyla güncellendi");
//       } else {
//         toast.error(response.message);
//       }
//     }
//   };

//   return (
//     <>
//       {isLoading ? (
//         <BarLoader
//           loading={isLoading}
//           aria-label="Loading Spinner"
//           width={"100%"}
//         />
//       ) : (
//         <Formik
//           initialValues={{
//             identificationNumber: null,
//             firstName: accountInfo.first_name,
//             lastName: accountInfo.last_name,
//             dob: "",
//             gender: "",
//             phoneNumber: accountInfo.phone,
//             email: accountInfo.email,
//             city: "",
//             province: "",
//           }}
//           onSubmit={handleSubmit}
//         >
//           {({ isSubmitting, setFieldValue }) => (
//             <Form>
//               <div className="row">
//                 <div className="col-md-12">
//                   <div className="col-md-12 dashboard-box margin-top-0">
//                     <div className="headline">
//                       <h3>
//                         <i className="icon-material-outline-account-circle"></i>{" "}
//                         Kimlik Bilgilerim
//                       </h3>
//                     </div>
//                     <div className="content with-padding padding-bottom-0">
//                       <div className="row">
//                         <div className="col-auto">
//                           <div
//                             className="avatar-wrapper"
//                             data-tippy-placement="bottom"
//                           >
//                             <img
//                               className="profile-pic"
//                               src={profileImage || profileImg}
//                               alt=""
//                             />
//                             <button className="profile-photo">
//                               <img
//                                 src={loopArrowImg}
//                                 alt=""
//                                 className="pp-edit"
//                               />
//                             </button>
//                             <div className="upload-button">
//                               <input
//                                 id="file-upload"
//                                 name="file-upload"
//                                 type="file"
//                                 accept="image/*"
//                                 onChange={handleImageChange}
//                                 placeholder="click me"
//                                 className="position-absolute top-0 bottom-0"
//                               />
//                             </div>
//                           </div>
//                         </div>
//                         <div className="col">
//                           <div className="row">
//                             <div className="col-md-3">
//                               <div className="submit-field">
//                                 <Input
//                                   id="firstName"
//                                   name="firstName"
//                                   type="text"
//                                   placeholder="Ahmet"
//                                   label="İsim"
//                                   value={accountInfo.first_name}
//                                 />
//                               </div>
//                             </div>
//                             <div className="col-md-3">
//                               <div className="submit-field">
//                                 <Input
//                                   id="lastName"
//                                   name="lastName"
//                                   type="text"
//                                   placeholder="TANCI"
//                                   label="Soyisim"
//                                 />
//                               </div>
//                             </div>

//                             <div className="col-md-3">
//                               <div className="submit-field">
//                                 <Input
//                                   id="dob"
//                                   name="dob"
//                                   type="text"
//                                   placeholder="gg/aa/yyyy"
//                                   label="Doğum Tarihi"
//                                 />
//                               </div>
//                             </div>
//                             <div className="col-md-3">
//                               <div className="submit-field">
//                                 <Dropdown
//                                   options={GENDER_OPTIONS}
//                                   placeholder="Cinsiyet seçin"
//                                   onSelect={handleSelect}
//                                   className="mb-3"
//                                   label="Cinsiyet"
//                                 />
//                               </div>
//                             </div>
//                           </div>

//                           <div className="row">
//                             <div className="col-md-6">
//                               <div className="submit-field">
//                                 <Input
//                                   label="Telefon Numaranız"
//                                   id="phoneNumber"
//                                   name="phoneNumber"
//                                   type="number"
//                                   placeholder="(555) 555 55 55"
//                                   containerClass="mb-0"
//                                 />
//                                 <p className="notification-msg-text">
//                                   Teklifler bu telefon numarasına ulaşacak
//                                 </p>
//                               </div>
//                             </div>
//                             <div className="col-md-6">
//                               <div className="submit-field">
//                                 <Input
//                                   label="E-posta Adresiniz"
//                                   id="email"
//                                   name="email"
//                                   type="email"
//                                   placeholder="mail@mail.com"
//                                   containerClass="mb-0"
//                                 />
//                                 <p className="notification-msg-text">
//                                   Teklifler bu e-posta adresine ulaşacak
//                                 </p>
//                               </div>
//                             </div>
//                           </div>
//                         </div>
//                       </div>

//                       <div className="row">
//                         <div className="col-md-4">
//                           <button
//                             className="button full-width button-sliding-icon ripple-effect margin-top-30"
//                             type="submit"
//                           >
//                             Bilgilerimi Güncelle{" "}
//                             <i className="icon-material-outline-arrow-right-alt"></i>
//                           </button>
//                           <br />
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>

//                 {/* Adres Bilgilerini Güncelleme */}
//                 <div className="col-md-12">
//                   <div className="col-md-12  dashboard-box margin-top-30">
//                     <div className="headline">
//                       <h3>
//                         <i className="icon-line-awesome-bank"></i> Adres
//                         Bilgileriniz
//                       </h3>
//                     </div>

//                     <div className="content">
//                       <ul className="fields-ul">
//                         <li>
//                           <div className="row">
//                             <div className="col-md-4">
//                               <div className="submit-field">
//                                 <DropdownWithSearch
//                                   options={provinces}
//                                   placeholder="İl"
//                                   label="İl"
//                                   onSelect={(e) => {
//                                     loadDistricts(parseInt(e));
//                                     setRequest({
//                                       ...request,
//                                       province_id: parseInt(e),
//                                     });
//                                     setFieldValue("province_id", parseInt(e));
//                                   }}
//                                 />
//                               </div>
//                             </div>

//                             <div className="col-md-4">
//                               <div className="submit-field">
//                                 <DropdownWithSearch
//                                   options={districts}
//                                   placeholder="İlçe"
//                                   label="İlçe"
//                                   onSelect={(e) => {
//                                     setRequest({
//                                       ...request,
//                                       district_id: parseInt(e),
//                                     });
//                                     setFieldValue("district_id", parseInt(e));
//                                   }}
//                                 />
//                               </div>
//                             </div>

//                             <Textarea
//                               placeholder="Açık adresinizi yazınız!"
//                               className="with-border"
//                             />
//                           </div>
//                         </li>
//                       </ul>
//                     </div>

//                     <div className="col-md-4">
//                       <button
//                         className="button full-width button-sliding-icon ripple-effect margin-top-30"
//                         type="submit"
//                         onClick={handleBankDetailSubmit}
//                       >
//                         Bilgilerimi Güncelle{" "}
//                         <i className="icon-material-outline-arrow-right-alt"></i>
//                       </button>
//                       <br />
//                     </div>
//                   </div>
//                 </div>

//                 {/* Kredi Kartı Bilgileri */}
//                 <div className="col-md-12">
//                   <div className="col-md-12  dashboard-box margin-top-30">
//                     <div className="headline">
//                       <h3>
//                         <i className="icon-material-outline-credit-card"></i>{" "}
//                         Kredi Kartı Ekle
//                       </h3>
//                       <div className="bank-account-line-d">
//                         <ul>
//                           <li className="bank-account-line-d">
//                             Buradan sisteme yeni kredi kartı kaydedebilirsiniz
//                           </li>
//                         </ul>
//                       </div>
//                     </div>

//                     <div className="content">
//                       <ul className="fields-ul">
//                         <li>
//                           <div className="row">
//                             <div className="col-md-4">
//                               <div className="submit-field">
//                                 <h5>Adınız Soyadınız</h5>
//                                 <div className="input-with-icon-left">
//                                   <i className="icon-material-outline-account-circle"></i>
//                                   <input
//                                     value={cardDetails.holder_name}
//                                     onChange={(e) =>
//                                       setCardDetails({
//                                         ...cardDetails,
//                                         holder_name: e.target.value,
//                                       })
//                                     }
//                                     type="text"
//                                     className="input-text with-border"
//                                     placeholder="Adınız Soyadınız"
//                                   />
//                                 </div>
//                               </div>
//                             </div>

//                             <div className="col-md-4">
//                               <div className="submit-field">
//                                 <h5>Kredi Kart Numaranız</h5>
//                                 <div className="input-with-icon-left">
//                                   <i className="icon-line-awesome-bank"></i>
//                                   <input
//                                     onChange={(e) =>
//                                       setCardDetails({
//                                         ...cardDetails,
//                                         card_number: e.target.value,
//                                       })
//                                     }
//                                     value={cardDetails.card_number}
//                                     type="number"
//                                     className="input-text with-border"
//                                     placeholder="Kredi Kartı Numarası"
//                                   />
//                                 </div>
//                               </div>
//                             </div>

//                             <div className="col-md-2">
//                               <div className="submit-field">
//                                 <h5>SKT</h5>
//                                 <div className="input-with-icon-left">
//                                   <i className="icon-line-awesome-bank"></i>
//                                   <input
//                                     onChange={(e) =>
//                                       setCardDetails({
//                                         ...cardDetails,
//                                         expiry_date: e.target.value,
//                                       })
//                                     }
//                                     value={cardDetails.expiry_date}
//                                     type="number"
//                                     className="input-text with-border"
//                                     placeholder="01/27"
//                                   />
//                                 </div>
//                               </div>
//                             </div>

//                             <div className="col-md-2">
//                               <div className="submit-field">
//                                 <h5>CVC</h5>
//                                 <div className="input-with-icon-left">
//                                   <i className="icon-line-awesome-bank"></i>
//                                   <input
//                                     onChange={(e) =>
//                                       setCardDetails({
//                                         ...cardDetails,
//                                         cvc: e.target.value,
//                                       })
//                                     }
//                                     value={cardDetails.cvc}
//                                     type="number"
//                                     className="input-text with-border"
//                                     placeholder="CVC"
//                                   />
//                                 </div>
//                               </div>
//                             </div>
//                           </div>
//                         </li>
//                       </ul>
//                     </div>

//                     <div className="col-md-4">
//                       <button
//                         className="button full-width button-sliding-icon ripple-effect margin-top-30"
//                         type="submit"
//                         onClick={handleCardDetailSubmit}
//                       >
//                         Kredi Kartı Bilgilerimi Kaydet{" "}
//                         <i className="icon-material-outline-arrow-right-alt"></i>
//                       </button>
//                       <br />
//                     </div>
//                   </div>
//                 </div>

//                 {/* Kayıtlı Kredi Kartlarım */}
//                 <div className="col-md-12">
//                   <div className="col-md-12  dashboard-box margin-top-30">
//                     <div className="headline">
//                       <h3>
//                         <i className="icon-material-outline-credit-card"></i>{" "}
//                         Kredi Kartlarım
//                       </h3>
//                       <div className="bank-account-line-d">
//                         <ul>
//                           <li className="bank-account-line-d">
//                             Sisteme kayıtlı kredi kartlarınızı buradan
//                             görüntüleyebilirsiniz
//                           </li>
//                         </ul>
//                       </div>
//                     </div>

//                     <div className="content">
//                       <ul className="dashboard-box-list">
//                         {creditCards.length > 0 ? (
//                           creditCards.map((card, index) => (
//                             <li key={index}>
//                               <div className="invoice-list-item">
//                                 <strong>
//                                   {card.holder_name || "Ad Soyad"}
//                                 </strong>
//                                 <ul>
//                                   <li>
//                                     <span className="paid">
//                                       {card.card_type || "Kredi Kartı"}
//                                     </span>
//                                   </li>
//                                   <li>SKT: {card.expiry_date || "N/A"}</li>
//                                   <li>
//                                     NO:{" "}
//                                     {card.card_number || "****-****-****-****"}
//                                   </li>
//                                 </ul>
//                               </div>
//                               <div className="buttons-to-right single-right-button">
//                                 <a
//                                   href="#"
//                                   className="button red ripple-effect ico"
//                                 >
//                                   <i className="icon-feather-trash-2"></i>
//                                 </a>
//                               </div>
//                             </li>
//                           ))
//                         ) : (
//                           <li>Kayıtlı kredi kartı yok!</li>
//                         )}
//                       </ul>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </Form>
//           )}
//         </Formik>
//       )}
//     </>
//   );
// };

// export default UserInformation;

// import profileImg from "@/images/user-avatar-placeholder.png";
// import loopArrowImg from "@/images/icons/loop-arrow.png";
// import Input from "@/components/Input";
// import Dropdown from "@/components/Dropdown";
// import DropdownWithSearch from "@/components/DropdownWithSearch";
// import Textarea from "@/components/TextArea";
// import { useEffect, useState } from "react";
// import { Formik, Form, FormikHelpers } from "formik";
// import { BarLoader } from "react-spinners";
// import { toast } from "react-toastify";
// import { GENDER_OPTIONS } from "@/libs/constants";

// import {
//   fetchBankDetails,
//   updateBankDetails,
//   fetchCreditCardList,
//   fetchAccountInfo,
//   updateAccountInfo,
//   uploadProfileImage,
//   updateAccountAddress,
// } from "@/api/account";

// import { fetchProvince, fetchDistrict } from "@/api/public";

// const UserInformation = () => {
//   const [request, setRequest] = useState({
//     business_area_id: 0,
//     province_id: 0,
//     district_id: 0,
//     title: "",
//     description: "",
//     start_date: "",
//     end_date: "",
//     female_employee_count: 0,
//     male_employee_count: 0,
//     internship: true,
//     disabled: true,
//     position: null,
//     total_working_hours: 0,
//     working_hours: [],
//     salary: 0,
//     salary_extras: [],
//     attributes: [],
//   });

//   const [districts, setDistricts] = useState<any[]>([]);
//   const loadDistricts = async (provinceId: number) => {
//     const districtResponse = await fetchDistrict(provinceId);
//     setDistricts(
//       districtResponse.data.districts.map((district: any) => ({
//         value: district.id,
//         label: district.name,
//       }))
//     );
//   };

//   const [profileImage, setProfileImage] = useState<string>("");
//   const [accountInfo, setAccountInfo] = useState({
//     first_name: "",
//     last_name: "",
//     email: "",
//     phone: "",
//     dob: "",
//     gender: "",
//   });

//   const [bankDetails, setBankDetails] = useState({
//     holder_name: "",
//     iban: "",
//   });

//   const [cardDetails, setCardDetails] = useState({
//     holder_name: "",
//     card_number: "",
//     expiry_date: "",
//     cvc: "",
//   });

//   const [creditCards, setCreditCards] = useState<any[]>([]);
//   const [isLoading, setIsLoading] = useState(true);
//   const [provinces, setProvinces] = useState([]);

//   useEffect(() => {
//     fetchBankDetails().then((response) => {
//       if (response.data) setBankDetails(response.data);
//     });
//   }, []);

//   const handleCardDetailSubmit = async (e: any) => {
//     e.preventDefault();
//     // Add your card submission logic here
//   };

//   const handleBankDetailSubmit = async (e: any) => {
//     e.preventDefault();
//     const response = await updateBankDetails(
//       bankDetails.holder_name,
//       bankDetails.iban
//     );
//     if (response.status === "0") {
//       toast.success("Banka detayları başarıyla güncellendi");
//     } else {
//       toast.error("Banka detayları güncellenirken bir hata oluştu");
//     }
//   };

//   const handleAddressSubmit = async () => {
//     const response = await updateAccountAddress({
//       id: 0,
//       address: "Açık adresinizi yazınız!",
//       province_id: request.province_id,
//       district_id: request.district_id,
//     });

//     if (response.status === "0") {
//       toast.success("Adres bilgileriniz başarıyla güncellendi");
//     } else {
//       toast.error("Adres güncellenirken bir hata oluştu");
//     }
//   };

//   useEffect(() => {
//     const fetchAccount = async () => {
//       const response = await fetchAccountInfo();
//       setAccountInfo(response.data);
//     };

//     const handleFetchProvinces = async () => {
//       const response = await fetchProvince();
//       setProvinces(
//         response.data.provinces.map((province: any) => ({
//           value: province.id,
//           label: province.name,
//         }))
//       );
//     };

//     const fetchCreditCards = async () => {
//       const response = await fetchCreditCardList();
//       if (response.data) setCreditCards(response.data);
//     };

//     Promise.all([
//       fetchAccount(),
//       handleFetchProvinces(),
//       fetchCreditCards(),
//     ]).then(() => {
//       setIsLoading(false);
//     });
//   }, []);

//   const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//     const file = event.target.files?.[0];
//     if (file) {
//       const reader = new FileReader();
//       reader.onloadend = () => {
//         const base64 = reader.result as string;
//         const name = file.name;
//         const type = file.type;
//         uploadProfileImage(name, type, base64).then((response) => {
//           if (response.status === 0) {
//             setProfileImage(base64);
//             toast.success("Profil resminiz başarıyla güncellendi");
//           } else {
//             toast.error(response.message);
//           }
//         });
//       };
//       reader.readAsDataURL(file);
//     }
//   };

//   const handleSelect = (value: string) => {
//     console.log("Selected:", value);
//   };

//   const handleSubmit = async (
//     values: any,
//     formikHelpers: FormikHelpers<any>
//   ) => {
//     if (
//       accountInfo.first_name !== values.firstName ||
//       accountInfo.last_name !== values.lastName ||
//       accountInfo.dob !== values.dob ||
//       accountInfo.gender !== values.gender
//     ) {
//       const response = await updateAccountInfo({
//         first_name: values.firstName,
//         last_name: values.lastName,
//         dob: values.dob,
//         gender: values.gender,
//       });
//       if (response.status == 0) {
//         toast.success("Bilgileriniz başarıyla güncellendi");
//       } else {
//         toast.error(response.message);
//       }
//     }
//   };

//   return (
//     <>
//       {isLoading ? (
//         <BarLoader
//           loading={isLoading}
//           aria-label="Loading Spinner"
//           width={"100%"}
//         />
//       ) : (
//         <Formik
//           initialValues={{
//             identificationNumber: null,
//             firstName: accountInfo.first_name,
//             lastName: accountInfo.last_name,
//             dob: "",
//             gender: "",
//             phoneNumber: accountInfo.phone,
//             email: accountInfo.email,
//             city: "",
//             province: "",
//           }}
//           onSubmit={handleSubmit}
//         >
//           {({ isSubmitting, setFieldValue }) => (
//             <Form>
//               <div className="row">
//                 <div className="col-md-12">
//                   <div className="col-md-12 dashboard-box margin-top-0">
//                     <div className="headline">
//                       <h3>
//                         <i className="icon-material-outline-account-circle"></i>{" "}
//                         Kimlik Bilgilerim
//                       </h3>
//                     </div>
//                     <div className="content with-padding padding-bottom-0">
//                       <div className="row">
//                         <div className="col-auto">
//                           <div
//                             className="avatar-wrapper"
//                             data-tippy-placement="bottom"
//                           >
//                             <img
//                               className="profile-pic"
//                               src={profileImage || profileImg}
//                               alt=""
//                             />
//                             <button className="profile-photo">
//                               <img
//                                 src={loopArrowImg}
//                                 alt=""
//                                 className="pp-edit"
//                               />
//                             </button>
//                             <div className="upload-button">
//                               <input
//                                 id="file-upload"
//                                 name="file-upload"
//                                 type="file"
//                                 accept="image/*"
//                                 onChange={handleImageChange}
//                                 placeholder="click me"
//                                 className="position-absolute top-0 bottom-0"
//                               />
//                             </div>
//                           </div>
//                         </div>
//                         <div className="col">
//                           <div className="row">
//                             <div className="col-md-3">
//                               <div className="submit-field">
//                                 <Input
//                                   id="firstName"
//                                   name="firstName"
//                                   type="text"
//                                   placeholder="Ahmet"
//                                   label="İsim"
//                                   value={accountInfo.first_name}
//                                 />
//                               </div>
//                             </div>
//                             <div className="col-md-3">
//                               <div className="submit-field">
//                                 <Input
//                                   id="lastName"
//                                   name="lastName"
//                                   type="text"
//                                   placeholder="TANCI"
//                                   label="Soyisim"
//                                 />
//                               </div>
//                             </div>

//                             <div className="col-md-3">
//                               <div className="submit-field">
//                                 <Input
//                                   id="dob"
//                                   name="dob"
//                                   type="text"
//                                   placeholder="gg/aa/yyyy"
//                                   label="Doğum Tarihi"
//                                 />
//                               </div>
//                             </div>
//                             <div className="col-md-3">
//                               <div className="submit-field">
//                                 <Dropdown
//                                   options={GENDER_OPTIONS}
//                                   placeholder="Cinsiyet seçin"
//                                   onSelect={handleSelect}
//                                   className="mb-3"
//                                   label="Cinsiyet"
//                                 />
//                               </div>
//                             </div>
//                           </div>

//                           <div className="row">
//                             <div className="col-md-6">
//                               <div className="submit-field">
//                                 <Input
//                                   label="Telefon Numaranız"
//                                   id="phoneNumber"
//                                   name="phoneNumber"
//                                   type="number"
//                                   placeholder="(555) 555 55 55"
//                                   containerClass="mb-0"
//                                 />
//                                 <p className="notification-msg-text">
//                                   Teklifler bu telefon numarasına ulaşacak
//                                 </p>
//                               </div>
//                             </div>
//                             <div className="col-md-6">
//                               <div className="submit-field">
//                                 <Input
//                                   label="E-posta Adresiniz"
//                                   id="email"
//                                   name="email"
//                                   type="email"
//                                   placeholder="mail@mail.com"
//                                   containerClass="mb-0"
//                                 />
//                                 <p className="notification-msg-text">
//                                   Teklifler bu e-posta adresine ulaşacak
//                                 </p>
//                               </div>
//                             </div>
//                           </div>
//                         </div>
//                       </div>

//                       <div className="row">
//                         <div className="col-md-4">
//                           <button
//                             className="button full-width button-sliding-icon ripple-effect margin-top-30"
//                             type="submit"
//                           >
//                             Bilgilerimi Güncelle{" "}
//                             <i className="icon-material-outline-arrow-right-alt"></i>
//                           </button>
//                           <br />
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>

//                 {/* Adres Bilgilerini Güncelleme */}
//                 <div className="col-md-12">
//                   <div className="col-md-12  dashboard-box margin-top-30">
//                     <div className="headline">
//                       <h3>
//                         <i className="icon-line-awesome-bank"></i> Adres
//                         Bilgileriniz
//                       </h3>
//                     </div>

//                     <div className="content">
//                       <ul className="fields-ul">
//                         <li>
//                           <div className="row">
//                             <div className="col-md-4">
//                               <div className="submit-field">
//                                 <DropdownWithSearch
//                                   options={provinces}
//                                   placeholder="İl"
//                                   label="İl"
//                                   onSelect={(e) => {
//                                     loadDistricts(parseInt(e));
//                                     setRequest({
//                                       ...request,
//                                       province_id: parseInt(e),
//                                     });
//                                     setFieldValue("province_id", parseInt(e));
//                                   }}
//                                 />
//                               </div>
//                             </div>

//                             <div className="col-md-4">
//                               <div className="submit-field">
//                                 <DropdownWithSearch
//                                   options={districts}
//                                   placeholder="İlçe"
//                                   label="İlçe"
//                                   onSelect={(e) => {
//                                     setRequest({
//                                       ...request,
//                                       district_id: parseInt(e),
//                                     });
//                                     setFieldValue("district_id", parseInt(e));
//                                   }}
//                                 />
//                               </div>
//                             </div>

//                             <Textarea
//                               placeholder="Açık adresinizi yazınız!"
//                               className="with-border"
//                             />
//                           </div>
//                         </li>
//                       </ul>
//                     </div>

//                     <div className="col-md-4">
//                       <button
//                         className="button full-width button-sliding-icon ripple-effect margin-top-30"
//                         type="submit"
//                         onClick={handleAddressSubmit}
//                       >
//                         Bilgilerimi Güncelle{" "}
//                         <i className="icon-material-outline-arrow-right-alt"></i>
//                       </button>
//                       <br />
//                     </div>
//                   </div>
//                 </div>

//                 {/* Kredi Kartı Bilgileri */}
//                 <div className="col-md-12">
//                   <div className="col-md-12  dashboard-box margin-top-30">
//                     <div className="headline">
//                       <h3>
//                         <i className="icon-material-outline-credit-card"></i>{" "}
//                         Kredi Kartı Ekle
//                       </h3>
//                       <div className="bank-account-line-d">
//                         <ul>
//                           <li className="bank-account-line-d">
//                             Buradan sisteme yeni kredi kartı kaydedebilirsiniz
//                           </li>
//                         </ul>
//                       </div>
//                     </div>

//                     <div className="content">
//                       <ul className="fields-ul">
//                         <li>
//                           <div className="row">
//                             <div className="col-md-4">
//                               <div className="submit-field">
//                                 <h5>Adınız Soyadınız</h5>
//                                 <div className="input-with-icon-left">
//                                   <i className="icon-material-outline-account-circle"></i>
//                                   <input
//                                     value={cardDetails.holder_name}
//                                     onChange={(e) =>
//                                       setCardDetails({
//                                         ...cardDetails,
//                                         holder_name: e.target.value,
//                                       })
//                                     }
//                                     type="text"
//                                     className="input-text with-border"
//                                     placeholder="Adınız Soyadınız"
//                                   />
//                                 </div>
//                               </div>
//                             </div>

//                             <div className="col-md-4">
//                               <div className="submit-field">
//                                 <h5>Kredi Kart Numaranız</h5>
//                                 <div className="input-with-icon-left">
//                                   <i className="icon-line-awesome-bank"></i>
//                                   <input
//                                     onChange={(e) =>
//                                       setCardDetails({
//                                         ...cardDetails,
//                                         card_number: e.target.value,
//                                       })
//                                     }
//                                     value={cardDetails.card_number}
//                                     type="number"
//                                     className="input-text with-border"
//                                     placeholder="Kredi Kartı Numarası"
//                                   />
//                                 </div>
//                               </div>
//                             </div>

//                             <div className="col-md-2">
//                               <div className="submit-field">
//                                 <h5>SKT</h5>
//                                 <div className="input-with-icon-left">
//                                   <i className="icon-line-awesome-bank"></i>
//                                   <input
//                                     onChange={(e) =>
//                                       setCardDetails({
//                                         ...cardDetails,
//                                         expiry_date: e.target.value,
//                                       })
//                                     }
//                                     value={cardDetails.expiry_date}
//                                     type="number"
//                                     className="input-text with-border"
//                                     placeholder="01/27"
//                                   />
//                                 </div>
//                               </div>
//                             </div>

//                             <div className="col-md-2">
//                               <div className="submit-field">
//                                 <h5>CVC</h5>
//                                 <div className="input-with-icon-left">
//                                   <i className="icon-line-awesome-bank"></i>
//                                   <input
//                                     onChange={(e) =>
//                                       setCardDetails({
//                                         ...cardDetails,
//                                         cvc: e.target.value,
//                                       })
//                                     }
//                                     value={cardDetails.cvc}
//                                     type="number"
//                                     className="input-text with-border"
//                                     placeholder="CVC"
//                                   />
//                                 </div>
//                               </div>
//                             </div>
//                           </div>
//                         </li>
//                       </ul>
//                     </div>

//                     <div className="col-md-4">
//                       <button
//                         className="button full-width button-sliding-icon ripple-effect margin-top-30"
//                         type="submit"
//                         onClick={handleCardDetailSubmit}
//                       >
//                         Kredi Kartı Bilgilerimi Kaydet{" "}
//                         <i className="icon-material-outline-arrow-right-alt"></i>
//                       </button>
//                       <br />
//                     </div>
//                   </div>
//                 </div>

//                 {/* Kayıtlı Kredi Kartlarım */}
//                 <div className="col-md-12">
//                   <div className="col-md-12  dashboard-box margin-top-30">
//                     <div className="headline">
//                       <h3>
//                         <i className="icon-material-outline-credit-card"></i>{" "}
//                         Kredi Kartlarım
//                       </h3>
//                       <div className="bank-account-line-d">
//                         <ul>
//                           <li className="bank-account-line-d">
//                             Sisteme kayıtlı kredi kartlarınızı buradan
//                             görüntüleyebilirsiniz
//                           </li>
//                         </ul>
//                       </div>
//                     </div>

//                     <div className="content">
//                       <ul className="dashboard-box-list">
//                         {creditCards.length > 0 ? (
//                           creditCards.map((card, index) => (
//                             <li key={index}>
//                               <div className="invoice-list-item">
//                                 <strong>
//                                   {card.holder_name || "Ad Soyad"}
//                                 </strong>
//                                 <ul>
//                                   <li>
//                                     <span className="paid">
//                                       {card.card_type || "Kredi Kartı"}
//                                     </span>
//                                   </li>
//                                   <li>SKT: {card.expiry_date || "N/A"}</li>
//                                   <li>
//                                     NO:{" "}
//                                     {card.card_number || "****-****-****-****"}
//                                   </li>
//                                 </ul>
//                               </div>
//                               <div className="buttons-to-right single-right-button">
//                                 <a
//                                   href="#"
//                                   className="button red ripple-effect ico"
//                                 >
//                                   <i className="icon-feather-trash-2"></i>
//                                 </a>
//                               </div>
//                             </li>
//                           ))
//                         ) : (
//                           <li>Kayıtlı kredi kartı yok!</li>
//                         )}
//                       </ul>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </Form>
//           )}
//         </Formik>
//       )}
//     </>
//   );
// };

// export default UserInformation;

// -------------------------------

import profileImg from "@/images/user-avatar-placeholder.png";
import loopArrowImg from "@/images/icons/loop-arrow.png";
import Input from "@/components/Input";
import Dropdown from "@/components/Dropdown";
import DropdownWithSearch from "@/components/DropdownWithSearch";
import Textarea from "@/components/TextArea";
import { useEffect, useState } from "react";
import { Formik, Form, FormikHelpers } from "formik";
import { BarLoader } from "react-spinners";
import { toast } from "react-toastify";
import { GENDER_OPTIONS } from "@/libs/constants";

import {
  fetchBankDetails,
  updateBankDetails,
  fetchCreditCardList,
  fetchAccountInfo,
  updateAccountInfo,
  uploadProfileImage,
  fetchPersonalAddress,
} from "@/api/account";

import { fetchProvince, fetchDistrict } from "@/api/public";
import { updateAccountAddress } from "@/api/account";

const UserInformation = () => {
  const [request, setRequest] = useState({
    business_area_id: 0,
    province_id: 0,
    district_id: 0,
    title: "",
    description: "",
    start_date: "",
    end_date: "",
    female_employee_count: 0,
    male_employee_count: 0,
    internship: true,
    disabled: true,
    position: null,
    total_working_hours: 0,
    working_hours: [],
    salary: 0,
    salary_extras: [],
    attributes: [],
  });

  const [districts, setDistricts] = useState<any[]>([]);
  const loadAddressDistricts = async (provinceId: number) => {
    const districtResponse = await fetchDistrict(provinceId);
    setAddressDistricts(
      districtResponse.data.districts.map((district: any) => ({
        value: district.id,
        label: district.name,
      }))
    );
  };


  const [addressLoading, setAddressLoading] = useState(true);
  const [error, setError] = useState(false);
  const [profileImage, setProfileImage] = useState<string>("");
  const [addressInfo, setAddressInfo] = useState({
    address: "",
    province: "",
    district: "",
    province_id: 0,
    district_id: 0,
  });

    const handleSubmit = async (
    values: any,
    formikHelpers: FormikHelpers<any>
  ) => {
    if (
      accountInfo.first_name !== values.firstName ||
      accountInfo.last_name !== values.lastName ||
      accountInfo.dob !== values.dob ||
      accountInfo.gender !== values.gender
    ) {
      const response = await updateAccountInfo({
        first_name: values.firstName,
        last_name: values.lastName,
        dob: values.dob,
        gender: values.gender,
      });
      if (response.status == 0) {
        toast.success("Bilgileriniz başarıyla güncellendi");
      } else {
        toast.error(response.message);
      }
    }
  };

  const [addressDistricts, setAddressDistricts] = useState<any[]>([]);

  const handleFetchAddress = async () => {
    const response = await fetchPersonalAddress();
    if(response.status !== "0"){
      toast.error("Adres bilgileri getirilirken bir hata oluştu");
      return;
    }

    if(response.data === null){
      return
    }

    if(response.data.district_id){
      const districtResponse = await fetchDistrict(response.data.province_id);
      await setAddressDistricts(
        districtResponse.data.districts.map((district: any) => ({
          value: district.id,
          label: district.name,
        }))
      );
    }

    await setAddressInfo({
      address: response.data.address,
      province: response.data.province,
      province_id: response.data.province_id,
      district: response.data.district,
      district_id: response.data.district_id
    });
    setAddressLoading(false);
  };
  

  const [accountInfo, setAccountInfo] = useState<any>({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    dob: "",
    gender: "",
    address: "", // Kullanıcının adresi
  });

  const [bankDetails, setBankDetails] = useState({
    holder_name: "",
    iban: "",
  });

  const [cardDetails, setCardDetails] = useState({
    holder_name: "",
    card_number: "",
    expiry_date: "",
    cvc: "",
  });

  const [creditCards, setCreditCards] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [provinces, setProvinces] = useState([]);

  useEffect(() => {
    fetchBankDetails().then((response) => {
      if (response.data) setBankDetails(response.data);
    });
  }, []);

  const handleCardDetailSubmit = async (e: any) => {
    e.preventDefault();
    // Add your card submission logic here
  };

  const handleBankDetailSubmit = async (e: any) => {
    e.preventDefault();
    const response = await updateBankDetails(
      bankDetails.holder_name,
      bankDetails.iban
    );
    if (response.status === "0") {
      toast.success("Banka detayları başarıyla güncellendi");
    } else {
      toast.error("Banka detayları güncellenirken bir hata oluştu");
    }
  };

  useEffect(() => {
    const fetchAccount = async () => {
      const response = await fetchAccountInfo();
      setAccountInfo(response.data); // Kullanıcı ID'si burada set ediliyor
    };

    const handleFetchProvinces = async () => {
      const response = await fetchProvince();
      setProvinces(
        response.data.provinces.map((province: any) => ({
          value: province.id,
          label: province.name,
        }))
      );
    };

    const fetchCreditCards = async () => {
      const response = await fetchCreditCardList();
      if (response.data) setCreditCards(response.data);
    };
    Promise.all([
      handleFetchProvinces()
    ]).then(() => {
      Promise.all([
        handleFetchAddress(),
        fetchAccount(),
        fetchCreditCards(),
      ]).then(() => {
        setIsLoading(false);
      });
    })


    
  }, []);

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64 = reader.result as string;
        const name = file.name;
        const type = file.type;
        uploadProfileImage(name, type, base64).then((response) => {
          if (response.status === "0") {
            setProfileImage(base64);
            toast.success("Profil resminiz başarıyla güncellendi");
          } else {
            toast.error(response.message);
          }
        });
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSelect = (value: string) => {
    console.log("Selected:", value);
  };

  const handleAddressSubmit = async () => {
    try {
      const response = await updateAccountAddress(

        addressInfo.address,
        addressInfo.province_id, // Seçilen province ID'si
        addressInfo.district_id // Seçilen district ID'si
      );
      
      if (response.status == "0") {
        toast.success("Adres başarıyla güncellendi");
      } else {
        toast.error("Adres güncellenirken bir hata oluştu");
      }
    } catch (error) {
      toast.error("Adres güncellenemedi");
    }
  };

  return (
    <>
      {isLoading ? (
        <BarLoader
          loading={isLoading}
          aria-label="Loading Spinner"
          width={"100%"}
        />
      ) : (
        <Formik
          initialValues={{
            identificationNumber: null,
            firstName: accountInfo.first_name,
            lastName: accountInfo.last_name,
            dob: "",
            gender: "",
            phoneNumber: accountInfo.phone,
            email: accountInfo.email,
            city: "",
            province: "",
          }}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, setFieldValue }) => (
            <Form>
              <div className="row">
                <div className="col-md-12">
                  <div className="col-md-12 dashboard-box margin-top-0">
                    <div className="headline">
                      <h3>
                        <i className="icon-material-outline-account-circle"></i>{" "}
                        Kimlik Bilgilerim
                      </h3>
                    </div>
                    <div className="content with-padding padding-bottom-0">
                      <div className="row">
                        <div className="col-auto">
                          <div
                            className="avatar-wrapper"
                            data-tippy-placement="bottom"
                          >
                            <img
                              className="profile-pic"
                              src={
                                profileImage ||
                                accountInfo.avatar?.url ||
                                profileImg
                              }
                              alt=""
                            />

                            <div className="upload-button">
                              <input
                                id="file-upload"
                                name="file-upload"
                                type="file"
                                accept="image/*"
                                onChange={handleImageChange}
                                placeholder="click me"
                                className="position-absolute top-0 bottom-0"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col">
                          <div className="row">
                            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6">
                              <div className="submit-field">
                                <Input
                                  id="firstName"
                                  name="firstName"
                                  type="text"
                                  placeholder="Ahmet"
                                  label="İsim"
                                  value={accountInfo.first_name}
                                />
                              </div>
                            </div>
                            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6">
                              <div className="submit-field">
                                <Input
                                  id="lastName"
                                  name="lastName"
                                  type="text"
                                  placeholder="TANCI"
                                  label="Soyisim"
                                />
                              </div>
                            </div>

                            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6">
                              <div className="submit-field">
                                <Input
                                  id="dob"
                                  name="dob"
                                  type="text"
                                  placeholder="gg/aa/yyyy"
                                  label="Doğum Tarihi"
                                />
                              </div>
                            </div>
                            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6">
                              <div className="submit-field">
                                <Dropdown
                                  options={GENDER_OPTIONS}
                                  placeholder="Cinsiyet seçin"
                                  onSelect={handleSelect}
                                  className="mb-3"
                                  label="Cinsiyet"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-6">
                              <div className="submit-field">
                                <Input
                                  label="Telefon Numaranız"
                                  id="phoneNumber"
                                  name="phoneNumber"
                                  type="number"
                                  placeholder="(555) 555 55 55"
                                  containerClass="mb-0"
                                />
                                <p className="notification-msg-text">
                                  Teklifler bu telefon numarasına ulaşacak
                                </p>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="submit-field">
                                <Input
                                  label="E-posta Adresiniz"
                                  id="email"
                                  name="email"
                                  type="email"
                                  placeholder="mail@mail.com"
                                  containerClass="mb-0"
                                />
                                <p className="notification-msg-text">
                                  Teklifler bu e-posta adresine ulaşacak
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-4">
                          <button
                            className="button full-width button-sliding-icon ripple-effect margin-top-30"
                            type="submit"
                          >
                            Bilgilerimi Güncelle{" "}
                            <i className="icon-material-outline-arrow-right-alt"></i>
                          </button>
                          <br />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Adres Bilgilerini Güncelleme */}
                {!addressLoading && (<div className="col-md-12">
                  <div className="col-md-12  dashboard-box margin-top-30">
                    <div className="headline">
                      <h3>
                        <i className="icon-line-awesome-bank"></i> Adres
                        Bilgileriniz
                      </h3>
                    </div>

                    <div className="content">
                      <ul className="fields-ul">
                        <li>
                          <div className="row">
                            <div className="col-md-4">
                              <div className="submit-field">
                                <DropdownWithSearch
                                  options={provinces}
                                  selectedValue={addressInfo.province_id}
                                  placeholder="İl"
                                  label="İl"
                                  onSelect={(e) => {
                                    loadAddressDistricts(parseInt(e));
                                    setAddressInfo({
                                      ...addressInfo,
                                      province_id: parseInt(e),
                                    });
                                    setFieldValue("province_id", parseInt(e));
                                  }}
                                />
                              </div>
                            </div>

                            <div className="col-md-4">
                              <div className="submit-field">
                                <DropdownWithSearch
                                key={addressDistricts.length + 'district' + addressInfo.province_id}
                                  options={addressDistricts}
                                  placeholder="İlçe"
                                  label="İlçe"
                                  selectedValue={addressInfo.district_id}
                                  onSelect={(e) => {
                                    setAddressInfo({
                                      ...addressInfo,
                                      district_id: parseInt(e),
                                    });
                                    setFieldValue("district_id", parseInt(e));
                                  }}
                                />
                              </div>
                            </div>

                            <Textarea
                              placeholder="Açık adresinizi yazınız!"
                              className="with-border"
                              value={addressInfo.address}
                              onChange={(e) =>
                                setAddressInfo({
                                  ...addressInfo,
                                  address: e.target.value,
                                })
                              }
                            />
                          </div>
                        </li>
                      </ul>
                    </div>

                    <div className="col-md-4">
                      <button
                        className="button full-width button-sliding-icon ripple-effect margin-top-30"
                        type="button"
                        onClick={handleAddressSubmit}
                      >
                        Adresimi Güncelle{" "}
                        <i className="icon-material-outline-arrow-right-alt"></i>
                      </button>
                      <br />
                    </div>
                  </div>
                </div>)}
                <div className="col-md-12">
                  <div className="col-md-12  dashboard-box margin-top-30">
                    <div className="headline">
                      <h3>
                        <i className="icon-line-awesome-bank"></i> Banka
                        Hesabını Tanımla
                      </h3>
                      <div className="bank-account-line-d">
                        <ul>
                          <li className="bank-account-line-d">
                            Hak ettiğin tüm ücretler tanımladığın banka hesabına
                            aktarılacak
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="content">
                      <ul className="fields-ul">
                        <li>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="submit-field">
                                <h5>Adınız Soyadınız</h5>
                                <div className="input-with-icon-left">
                                  <i className="icon-material-outline-account-circle"></i>
                                  <input
                                    value={bankDetails.holder_name}
                                    onChange={(e) =>
                                      setBankDetails({
                                        ...bankDetails,
                                        holder_name: e.target.value,
                                      })
                                    }
                                    type="text"
                                    className="input-text with-border"
                                    placeholder="Adınız Soyadınız"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="submit-field">
                                <h5>IBAN</h5>
                                <div className="input-with-icon-left">
                                  <i className="icon-line-awesome-bank"></i>
                                  <input
                                    onChange={(e) =>
                                      setBankDetails({
                                        ...bankDetails,
                                        iban: e.target.value,
                                      })
                                    }
                                    value={bankDetails.iban}
                                    type="number"
                                    className="input-text with-border"
                                    placeholder="IBAN"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="col-md-4">
                      <button
                        className="button full-width button-sliding-icon ripple-effect margin-top-30"
                        type="submit"
                        onClick={handleBankDetailSubmit}
                      >
                        Banka Hesabını Kaydet{" "}
                        <i className="icon-material-outline-arrow-right-alt"></i>
                      </button>
                    </div>
                    <br />
                  </div>
                </div>

                {/* Kredi Kartı Bilgileri */}
                <div className="col-md-12">
                  <div className="col-md-12  dashboard-box margin-top-30">
                    <div className="headline">
                      <h3>
                        <i className="icon-material-outline-credit-card"></i>{" "}
                        Kredi Kartı Ekle
                      </h3>
                      <div className="bank-account-line-d">
                        <ul>
                          <li className="bank-account-line-d">
                            Buradan sisteme yeni kredi kartı kaydedebilirsiniz
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="content">
                      <ul className="fields-ul">
                        <li>
                          <div className="row">
                            <div className="col-md-4">
                              <div className="submit-field">
                                <h5>Adınız Soyadınız</h5>
                                <div className="input-with-icon-left">
                                  <i className="icon-material-outline-account-circle"></i>
                                  <input
                                    value={cardDetails.holder_name}
                                    onChange={(e) =>
                                      setCardDetails({
                                        ...cardDetails,
                                        holder_name: e.target.value,
                                      })
                                    }
                                    type="text"
                                    className="input-text with-border"
                                    placeholder="Adınız Soyadınız"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="col-md-4">
                              <div className="submit-field">
                                <h5>Kredi Kart Numaranız</h5>
                                <div className="input-with-icon-left">
                                  <i className="icon-line-awesome-bank"></i>
                                  <input
                                    onChange={(e) =>
                                      setCardDetails({
                                        ...cardDetails,
                                        card_number: e.target.value,
                                      })
                                    }
                                    value={cardDetails.card_number}
                                    type="number"
                                    className="input-text with-border"
                                    placeholder="Kredi Kartı Numarası"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="col-md-2">
                              <div className="submit-field">
                                <h5>SKT</h5>
                                <div className="input-with-icon-left">
                                  <i className="icon-line-awesome-bank"></i>
                                  <input
                                    onChange={(e) =>
                                      setCardDetails({
                                        ...cardDetails,
                                        expiry_date: e.target.value,
                                      })
                                    }
                                    value={cardDetails.expiry_date}
                                    type="number"
                                    className="input-text with-border"
                                    placeholder="01/27"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="col-md-2">
                              <div className="submit-field">
                                <h5>CVC</h5>
                                <div className="input-with-icon-left">
                                  <i className="icon-line-awesome-bank"></i>
                                  <input
                                    onChange={(e) =>
                                      setCardDetails({
                                        ...cardDetails,
                                        cvc: e.target.value,
                                      })
                                    }
                                    value={cardDetails.cvc}
                                    type="number"
                                    className="input-text with-border"
                                    placeholder="CVC"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>

                    <div className="col-md-4">
                      <button
                        className="button full-width button-sliding-icon ripple-effect margin-top-30"
                        type="submit"
                        onClick={handleCardDetailSubmit}
                      >
                        Kredi Kartı Bilgilerimi Kaydet{" "}
                        <i className="icon-material-outline-arrow-right-alt"></i>
                      </button>
                      <br />
                    </div>
                  </div>
                </div>

                {/* Kayıtlı Kredi Kartlarım */}
                <div className="col-md-12">
                  <div className="col-md-12  dashboard-box margin-top-30">
                    <div className="headline">
                      <h3>
                        <i className="icon-material-outline-credit-card"></i>{" "}
                        Kredi Kartlarım
                      </h3>
                      <div className="bank-account-line-d">
                        <ul>
                          <li className="bank-account-line-d">
                            Sisteme kayıtlı kredi kartlarınızı buradan
                            görüntüleyebilirsiniz
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="content">
                      <ul className="dashboard-box-list">
                        {creditCards.length > 0 ? (
                          creditCards.map((card, index) => (
                            <li key={index}>
                              <div className="invoice-list-item">
                                <strong>
                                  {card.holder_name || "Ad Soyad"}
                                </strong>
                                <ul>
                                  <li>
                                    <span className="paid">
                                      {card.card_type || "Kredi Kartı"}
                                    </span>
                                  </li>
                                  <li>SKT: {card.expiry_date || "N/A"}</li>
                                  <li>
                                    NO:{" "}
                                    {card.card_number || "****-****-****-****"}
                                  </li>
                                </ul>
                              </div>
                              <div className="buttons-to-right single-right-button">
                                <a
                                  href="#"
                                  className="button red ripple-effect ico"
                                >
                                  <i className="icon-feather-trash-2"></i>
                                </a>
                              </div>
                            </li>
                          ))
                        ) : (
                          <li>Kayıtlı kredi kartı yok!</li>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export default UserInformation;
