import React, { useRef, useEffect, useState } from "react";
import { StandaloneSearchBox, useJsApiLoader } from "@react-google-maps/api";

const PlacesAutoComplete: React.FC = () => {
  const inputRef = useRef<HTMLInputElement>(null);
  const searchBoxRef = useRef<google.maps.places.SearchBox | null>(null);
  const [isDropdownVisible, setIsDropdownVisible] = useState(true);

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY as string,
    libraries: ["places"],
  });

  useEffect(() => {
    // Handle clicks outside of the autocomplete container
    const handleClickOutside = (event: MouseEvent) => {
      if (inputRef.current && !inputRef.current.contains(event.target as Node)) {
        setIsDropdownVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const pacContainer = document.querySelector('.pac-container') as HTMLElement | null;
    if (pacContainer) {
      if (!isDropdownVisible) {
          pacContainer.classList.add('hidden');
          pacContainer.style.opacity = '0';
      } else {
        pacContainer.classList.remove('hidden');
        pacContainer.style.opacity = '';
      }
    }
  }, [isDropdownVisible]);

  const handlePlaceChanged = () => {
    if (searchBoxRef.current && inputRef.current) {
      const places = searchBoxRef.current.getPlaces();
      if (places && Array.isArray(places)) {
        const [place] = places;
        if (place) {
          console.log("Address:", place.formatted_address);
          console.log("Latitude:", place.geometry?.location?.lat());
          console.log("Longitude:", place.geometry?.location?.lng());
        }
      }
      inputRef.current.value = '';
    }
  };

  const handleInputFocus = () => {
    setIsDropdownVisible(true);
  };

  if (loadError) {
    return <div>Error loading Google Maps</div>;
  }

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <StandaloneSearchBox
      onLoad={(ref: google.maps.places.SearchBox) => (searchBoxRef.current = ref)}
      onPlacesChanged={handlePlaceChanged}
    >
      <div className="position-relative z-0">
        <input
          ref={inputRef}
          type="text"
          className="form-control input-style border-0"
          placeholder="İl, ilçe girin"
          onFocus={handleInputFocus}
        />
        <i className="position-absolute top-0 icon-material-outline-location-on google-map-icon"></i>
      </div>
    </StandaloneSearchBox>
  );
};

export default PlacesAutoComplete;