import { string } from 'yup';
import api from './api'
export const fetchLogin = async (username: string, password: string) => {

    var bodyFormData = new FormData();
    bodyFormData.append("username", username);
    bodyFormData.append("password", password);
    const result = await api.post("/auth/token?w=1", bodyFormData);
    return result
};

export const fetchLoginVerification = async (code: string) => {
    const access_token = JSON.parse(localStorage.getItem('tempToken') || '{}').access_token;
    const result = await api.post('/auth/token/verify-code', { code }, { headers: { Authorization: `Bearer ${access_token}` } });
    return result as any;
};

export const fetchLogout = async () => {
    const result = await api.get('/auth/logout');
    return result as any
}

export const fetchRegisterMailVerification = async (code: string) => {
    const access_token = JSON.parse(localStorage.getItem('tempToken') || '{}').access_token;
    const result = await api.post('/registration/verify-email', { code }, { headers: { Authorization: `Bearer ${access_token}` } });
    return result as any;
}
export const fetchRegisterPhoneVerification = async (code: string) => {
    const access_token = JSON.parse(localStorage.getItem('tempToken')
    || '{}').access_token;
    const result = await api.post('/registration/verify-phone', { code }, { headers: { Authorization: `Bearer ${access_token}` } });
    return result as any;
}
export const fetchLoginPhoneVerification = async (code: string) => {
    const access_token = JSON.parse(localStorage.getItem('tempToken')
    || '{}').access_token;
    const result = await api.post('/auth/verify-phone', { code }, { headers: { Authorization: `Bearer ${access_token}` } });
    return result as any;
}

export const fetchLoginMailVerification = async (code: string) => {
    const access_token = JSON.parse(localStorage.getItem('tempToken')
    || '{}').access_token;
    const result = await api.post('/auth/verify-email', { code }, { headers: { Authorization: `Bearer ${access_token}` } });
    return result as any;
}
export const fetchForgotPasswordMailVerification = async (code: string) => {
    const access_token = JSON.parse(localStorage.getItem('tempToken')
    || '{}').access_token;
    const result = await api.post('/auth/forgotten-password/verify-email', { code }, { headers: { Authorization: `Bearer ${access_token}` } });
    return result as any;
}

export const fetchForgotPasswordCreatePassword = async (new_password: string) => {
    const access_token = JSON.parse(localStorage.getItem('tempToken')
    || '{}').access_token;

    const result = await api.post('/auth/forgotten-password/create-password', { new_password }, { headers: { Authorization: `Bearer ${access_token}` } });
    return result as any;
}

export const fetchRegister = async ( 
        
    first_name: string, last_name: string, email: string, password: string, confirm_password: string, type: 'INDIVIDUAL' | 'COMPANY', company_name?:string, ) => {
    const result = await api.post('/registration', {  first_name, last_name, email, password, confirm_password, ...(company_name && { company_name }) ,type  });
    return result;
};


export const fetchRegisterCompany = async ( 
        
    first_name: string, last_name: string, email: string, password: string, confirm_password: string, type: 'COMPANY', company_name:string, phone:string) => {
    const result = await api.post('/registration', {  first_name, last_name, email, password, confirm_password, company_name ,phone,type  });
    return result;
};

export const fetchPreRegisterCompany = async (

first_name: string, last_name: string, email: string, password: string, confirm_password: string, type: 'COMPANY', company_name:string, phone:string) => {
    const result = await api.post('/registration/pre-register', {  first_name, last_name, email, password, confirm_password, company_name ,phone,type  });
    return result;
};

export const fetchMe = async () => {
    const result = await api.get('/auth/me');
    return result;
}


export const fetchAddColleague = async (first_name: string, last_name: string, email: string, phone:string, password:string, confirm_password:string) => {
     
    console.log("öncesi")
    const result = await api.post('/profile/company/manager/editor', {first_name, last_name, email, phone, password});
    
    console.log("sonrası")

    return result;
}

export const fetchDocuments = async () => {
    const result = await api.get('/documents');
    return result;
}

export const fetchRequestDocument = async (category: string, description: string) => {
    const result = await api.put('/documents', { category, description,type:'REQUEST' });
    return result;
}

export const fetchUploadDocument = async (name:string,media_type:string,payload:string,category:string,start_date:string,end_date:string,description:string) => {
    const result = await api.put('/documents', { name,media_type,payload,category,start_date,end_date,description });
    return result;
}