import React, { useState } from "react";
import { Link } from "react-router-dom";
import Monthly from "./Monthly";
import Yearly from "./Yearly";
import { fetchPlans } from "@/api/public";

const Subscribtion = () => {
  const [selectedPlan, setSelectedPlan] = useState<string>("monthly");
  const [plans, setPlans] = useState<any[]>([]);
  const handleTabChange = (planType: string) => {
    setSelectedPlan(planType);
  };

  React.useEffect(() => {
    const handleLoadPlans = async () => {
      const response = await fetchPlans();
      
      if (response.status === "0") {
        setPlans(response.data);
      }
    };
    handleLoadPlans();
  }
  , []);



  return (
    <>
      <div className="section gray padding-top-60 padding-bottom-45">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-headline centered margin-top-0 margin-bottom-50">
                <h3>Üyelik Planları</h3>
              </div>
            </div>

            <div className="col-md-12">
              <div className="billing-cycle-radios margin-bottom-70">
                

                {/* Monthly and Yearly Payments */}
                {selectedPlan === "monthly" ? <Monthly plans={plans} /> : <Yearly />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Subscribtion;
