import Logo from "../images/logo.png";
import playstoreImg from "../images/get-play-store.png";
import appstoreImg from "../images/get-app-store.svg";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div id="footer">
      <div className="footer-top-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="footer-rows-container">
                <div className="footer-rows-left">
                  <div className="footer-row">
                    <div className="footer-row-inner footer-logo">
                      <img src={Logo} alt="" />
                    </div>
                  </div>
                </div>

                <div className="footer-rows-right">
                  <div className="footer-row">
                    <div className="footer-row-inner">
                      {/* Social Media Links */}
                      <ul className="footer-social-links">
                        <li>
                          <a
                            href="#"
                            data-tippy-placement="bottom"
                            data-tippy-theme="light"
                            data-tippy="true"
                            data-original-title="Facebook"
                          >
                            <i className="icon-brand-facebook-f"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="#"
                            data-tippy-placement="bottom"
                            data-tippy-theme="light"
                            data-tippy="true"
                            data-original-title="Twitter"
                          >
                            <i className="icon-brand-twitter"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="#"
                            data-tippy-placement="bottom"
                            data-tippy-theme="light"
                            data-tippy="true"
                            data-original-title="Google Plus"
                          >
                            <i className="icon-brand-google-plus-g"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="#"
                            data-tippy-placement="bottom"
                            data-tippy-theme="light"
                            data-tippy="true"
                            data-original-title="LinkedIn"
                          >
                            <i className="icon-brand-linkedin-in"></i>
                          </a>
                        </li>
                      </ul>
                      <div className="clearfix"></div>
                    </div>
                  </div>

                  <div className="footer-row">
                    <div className="footer-row-inner inner-download">
                      {/* App Download Buttons */}
                      <div className="footer-row-download">
                        <a href="#">
                          <img
                            className="download-app"
                            src="https://parttimejet.com/static/media/get-app-store.0f13ed4d7e6982226fc7a0ee530359eb.svg"
                            alt="Uygulamamızı App Store'den İndir"
                          />
                        </a>
                        <a href="#">
                          <img
                            className="download-app"
                            src="https://parttimejet.com/static/media/get-play-store.f2ca834bc531c87de6ba.png"
                            alt="Uygulamamızı Play Store'den İndir"
                          />
                        </a>
                      </div>
                      <div className="clearfix"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-middle-section">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <div className="footer-links">
                <h3>Veri Politikamız</h3>
                <ul>
                  <li>
                    <a href="#">
                      <span>Aday Üyelik Aydınlatma Metni</span>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span>Çalışan Aydınlatma Metni</span>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span>Tedarikçi/İş Ortağı</span>
                    </a>
                  </li>

                  <li>
                    <Link to="/employer/company-info/colleague/edit/ColleagueEdit">
                      <a href="#">
                        <span>Çerez Politikası</span>
                      </a>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-md-3">
              <div className="footer-links">
                <h3>Yardım</h3>
                <ul>
                  <li>
                    <a href="#">
                      <span>Sorum Var</span>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span>Önerim Var</span>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span>Sıkça Sorulan Sorular</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-md-2">
              <div className="footer-links">
                <h3>Hakkımızda</h3>
                <ul>
                  <li>
                    <a href="#">
                      <span>Hakkımızda</span>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span>İletişim</span>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span>Reklam Verin</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-md-4">
              <h3>
                <i className="icon-feather-mail"></i> Bültene kayıt ol
              </h3>
              <p>
                Haftalık yayınlanan haber bültenimize abone olmak için e-posta
                adresin ile kayıt ol.
              </p>
              <form action="#" method="get" className="newsletter">
                <input
                  type="text"
                  name="fname"
                  placeholder="E-posta adresinizi girin"
                />
                <button type="submit">
                  <i className="icon-feather-arrow-right"></i>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-bottom-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              © 2024 <strong>ParttimeJET</strong>. Tüm Hakları Saklıdır.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
