import Sidebar from '../../components/Sidebar'
import { Outlet } from 'react-router-dom'
import { companyNavSection } from '../../libs/constants'
import { useModal } from '@/contexts/ModalContext';

const CompanyInfo = () => {
  return (
    <div className="dashboard-container">
      <Sidebar navSections={companyNavSection} />
      <div
        className="dashboard-content-container"
        data-simplebar="init"
        style={{ height: '827px', overflowY: 'auto' }}
      >
        <div
          className="simplebar-track vertical"
          style={{ visibility: 'visible' }}
        >
          <div
            className="simplebar-scrollbar"
            style={{ visibility: 'visible', top: '0px', height: '519px' }}
          ></div>
        </div>
        <div
          className="simplebar-track horizontal"
          style={{ visibility: 'visible' }}
        >
          <div
            className="simplebar-scrollbar"
            style={{ visibility: 'visible', left: '0px', width: '25px' }}
          ></div>
        </div>
        <div
          className="simplebar-scroll-content"
          style={{ paddingRight: '20px', marginBottom: '-20px' }}
        >
          <div className="simplebar-content" style={{ paddingBottom: '20px' }}>
            <div
              className="dashboard-content-inner"
              style={{ minHeight: '827px' }}
            >
              <Outlet/>
              <div
                className="dashboard-footer-spacer"
                style={{ paddingTop: '138px' }}
              ></div>
              <div className="small-footer margin-top-15">
                <div className="small-footer-copyrights">
                © 2024 <strong>ParttimeJET</strong>. Tüm Hakları Saklıdır.
                </div>
                <ul className="footer-social-links">
                  <li>
                    <a
                      href="#"
                      data-tippy-placement="top"
                      data-tippy=""
                      data-original-title="Facebook"
                    >
                      <i className="icon-brand-facebook-f"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      data-tippy-placement="top"
                      data-tippy=""
                      data-original-title="Twitter"
                    >
                      <i className="icon-brand-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      data-tippy-placement="top"
                      data-tippy=""
                      data-original-title="Google Plus"
                    >
                      <i className="icon-brand-google-plus-g"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      data-tippy-placement="top"
                      data-tippy=""
                      data-original-title="LinkedIn"
                    >
                      <i className="icon-brand-linkedin-in"></i>
                    </a>
                  </li>
                </ul>
                <div className="clearfix"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CompanyInfo
