import { fetchForgotPassword } from '@/api/account';
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Form, Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import Input from '@/components/Input';
import { toast } from 'react-toastify';
import { fetchForgotPasswordCreatePassword } from '@/api/user';

const passwordSchema = Yup.object({
  password: Yup.string()
    .min(8, 'Must be at least 8 characters')
    .required('Password is required'),
  reTypePassword: Yup.string()
    .required('Confirm Password is required')
    .oneOf([Yup.ref('password'), ''], 'Passwords must match'),
})

const PasswordRenewal = () => {
  const navigate = useNavigate();
  const handleSubmit = async (
    values: any, 
    { setSubmitting }: any
  ) => {
    
    const response = await fetchForgotPasswordCreatePassword(values.password);
    
    if(response.status === '0')
    {
      toast.success("Şifreniz başarıyla değiştirildi.");
      navigate('/login');
    }else{
      
      toast.error(response.message);
    }
      
  }

  return (
    <Formik
    id="login-form"
    initialValues={{
      password: "",
      reTypePassword: ""
    }}
    onSubmit={handleSubmit}
    validationSchema={passwordSchema}
  >
    {({ errors, touched }) => (
      <Form id="login-form">
      <div className='form-container'>
        <div className="row l-container">
          <div className="col-md-8 offset-md-2">
            <div className="login-register-page">
              <div className="welcome-text">
                <h3>Şifre Yenileme</h3>
                <span>Belirlemek istediğin yeni şifreni gir</span>
              </div>

              <form method="post" id="login-form">
                <div
                  className="input-with-icon-left"
                  title="Yeni şifrenizi girin"
                  data-tippy-placement="bottom"
                >
                  <i className="icon-material-outline-lock"></i>
                  <Input
                    type="password"
                    className="input-text with-border"
                    name="password"
                    id="password"
                    placeholder="Yeni şifre"
                  />
                   {errors.password && touched.password ? (
                        <div className="error-message">{errors.password}</div>
                      ) : null}
                </div>

                <div
                  className="input-with-icon-left"
                  title="Yeni şifrenizi tekrar girin"
                  data-tippy-placement="bottom"
                >
                  <i className="icon-material-outline-lock"></i>
                  <Input
                    type="password"
                    className="input-text with-border"
                    name="reTypePassword"
                    id="reTypePassword"
                    placeholder="Yeni şifrenizi tekrar girin"
                  />
                  {errors.reTypePassword && touched.reTypePassword ? (
                        <div className="error-message">{errors.reTypePassword}</div>
                      ) : null}
                </div>
              </form>

              <button
                className="button full-width button-sliding-icon ripple-effect margin-top-10"
                type="submit"
                form="login-form"
              >
                Şifremi Yenile{' '}
                <i className="icon-material-outline-arrow-right-alt"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </Form>
    )}
  </Formik>
  )
}

export default PasswordRenewal
