import { createSlice } from '@reduxjs/toolkit'
import TokenService from './token'


export interface IUserState {
    isLoggedIn: boolean;
    email: string | null;
    first_Name: string | null;
    last_Name: string | null;
    id: number | null;
    avatar: any | null;
    company: any | null;
    // sex: string | null,
    // birthdate: string | null,
}


const initialState: IUserState = {
    isLoggedIn: false,
    email: null,
    first_Name: null,
    last_Name: null,
    id: null,
    company: null,
    avatar: null,
    // sex: null,
    // birthdate: null,
}

if(TokenService.getAccessToken()) {
    initialState.isLoggedIn = true;
    const user = TokenService.getUser();
    initialState.email = user.email;
    initialState.first_Name = user.first_name;
    initialState.last_Name = user.last_name;
    initialState.id = user.id;
}


export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser: (state, action) => {
            TokenService.setTokens(action.payload.access_token);
            TokenService.setUser(action.payload.user)
            state.isLoggedIn = true;
            state.email = action.payload.user.email; // email güncelleme
            state.first_Name = action.payload.user.first_name; // first_Name güncelleme
            state.last_Name = action.payload.user.last_name; // last_Name güncelleme
     
        }, 
        updateUser: (state, action) => {
            TokenService.setUser(action.payload)
            state.email = action.payload.email; // email güncelleme
            state.first_Name = action.payload.first_name; // first_Name güncelleme
            state.last_Name = action.payload.last_name; // last_Name güncelleme
            state.company = action.payload.company_info;
            state.avatar = action.payload.avatar;
        },
        
        clearUser: (state) => {
            TokenService.clear();
            state.isLoggedIn = false;
            state.email = null; // email temizleme
            state.first_Name = null; // first_Name temizleme
            state.last_Name = null; // last_Name temizleme
        },
        setTempAccessToken: (state, action) => {
            var access_token = action.payload.access_token;
            
            if(!access_token){
                const tempToken = TokenService.getTempToken();
                access_token = tempToken.access_token;
            }

            TokenService.setTempToken({
                access_token,
                phone_number: action.payload.phone_number,
                verification_required: action.payload.verification_required,
                verification_code_expires_in: action.payload.verification_code_expires_in,
                email: action.payload.email,
                verification_code_expiration: action.payload.verification_code_expiration,
            });

        }
    },
})

export const { setUser, clearUser, setTempAccessToken,updateUser } = userSlice.actions

export default userSlice.reducer