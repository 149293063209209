import { createJob } from '@/api/company'
import { fetchBusinesAreaOptions, fetchDistrict, fetchProvince } from '@/api/public'
import Dropdown from '@/components/Dropdown'
import DropdownWithSearch from '@/components/DropdownWithSearch'
import {
  NUMBER_OF_PERSONNEL,
  POSITION_OPTIONS,
  STAFF_GENDER_OPTIONS,
  TAX_PROVINCES_OPTIONS,
  WOMEN_DISTRICTS_OPTIONS,
  WORKING_OPTIONS
} from '@/libs/constants'
import { create } from 'domain'
import { Form, Formik, useFormik } from 'formik'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import * as Yup from "yup";

const CreateJobPosting = () => {
  const [provinces, setProvinces] = useState<any[]>([])
  const [districts, setDistricts] = useState<any[]>([])
  const [businessAreas, setBusinessAreas] = useState<any[]>([])
  const [jobStartDate, setJobStartDate] = useState<string | null>(null)
  const [jobEndDate, setJobEndDate] = useState<string | null>(null)
  const [workingDays, setWorkingDays] = useState<any[]>([])
  const [attributes, setAttributes] = useState<any[]>([
    {
      label: 'JETtasdik',
      name: 'JET_TASDIK',
      checked: false,
      price: 300
    },
    {
      label: 'JETonay',
      name: 'JET_ONAY',
      checked: false,
      price: 300
    },
    {
      label: 'JETçerçeve',
      name: 'JET_CERCEVE',
      checked: false,
      price: 300
    },
    {
      label: 'JETliste',
      name: 'JET_LISTE',
      checked: false,
      price: 300
    },
    {
      label: 'JETvitrin',
      name: 'JET_VITRIN',
      checked: false,
      price: 300
    },
    {
      label: 'JETacil',
      name: 'JET_ACIL',
      checked: false,
      price: 300
    }
  ]);

  useEffect(() => {
    
    if(!jobStartDate || !jobEndDate) return
    
    const startDate = moment(jobEndDate)
    const endDate = moment(jobStartDate)
    
    if(startDate.isBefore(endDate)) {
      toast.error('Başlangıç tarihi bitiş tarihinden önce olamaz')
      return
    }

    var duration = moment.duration(startDate.diff(endDate));
    var daysCount = duration.asDays();
    const days: any[] = []
    for(var i = 0; i <= daysCount; i++) {
      const currentDay = moment(jobStartDate).add(i, 'days')
      days.push({
        date: currentDay.format('YYYY-MM-DD'),
        start_time: '08:00',
        checked: true,
        end_time: '18:00',
        day_name: currentDay.format('dddd')
      });
      }
      setWorkingDays(days)
    }

  , [jobStartDate, jobEndDate])

  const [request, setRequest] = useState({
    "business_area_id": 0,
    "province_id": 0,
    "district_id": 0,
    "title": "",
    "description": "",
    "start_date": "",
    "end_date": "",
    "female_employee_count": 0,
    "male_employee_count": 0,
    "internship": true,
    "disabled": true,
    "position": null,
    "total_working_hours": 0,
    "working_hours": [
    ],
    "salary": 0,
    "salary_extras": [
    ],
    "attributes": [
    ]
  })

  const payAndPublish = async () => {
    try {
      const createRequest = {
        ...request,
        
        attributes: attributes.filter((attribute) => attribute.checked).map((attribute) => ({
          attribute: attribute.name,
          value: '1'
        })),
        salary_extras: [],
        working_hours: workingDays.map((day) => ({
          date: day.date,
          start_time: moment(day.date).set('hour', parseInt(day.start_time.split(':')[0])).set('minute', parseInt(day.start_time.split(':')[1])).toISOString(),
          end_time: moment(day.date).set('hour', parseInt(day.end_time.split(':')[0])).set('minute', parseInt(day.end_time.split(':')[1])).toISOString()
        }))
      }
      const response = await createJob(createRequest)
      if(response.status == '0'){
        toast.success('İş ilanı başarıyla oluşturuldu')
      }else{
        toast.error(response.message)
      }

    } catch (error) {
      console.error(error)
    }
  }




  useEffect(() => {
    const handleFetchProvinces = async () => {
      const provincesResponse = await fetchProvince()

      setProvinces(
        provincesResponse.data.provinces.map((province: any) => ({
          value: province.id,
          label: province.name
        }))
      )
    }
    const handleFetchBusinessAreas = async () => {
      const businessAreasResponse = await fetchBusinesAreaOptions()
      
      setBusinessAreas(businessAreasResponse.data.areas.map((businessArea: any) => ({
        value: businessArea.id,
        label: businessArea.name
      })))
    }
    handleFetchBusinessAreas()

    
    handleFetchProvinces()
    
  }
  , [])

  const loadDistricts = async (provinceId: number) => {
    const districtResponse = await fetchDistrict(provinceId)
    setDistricts(districtResponse.data.districts.map((district: any) => ({
      value: district.id,
      label: district.name
    })))
  }

  const handleSubmit = async (values: any) => {
    console.log(values)
  }

  const signinSchema = Yup.object().shape({
    title: Yup.string().required('İş ilanı başlığı gerekli'),
    description: Yup.string().required('İş ilanı açıklaması gerekli'),
    business_area_id: Yup.string().required('Pozisyon gerekli'),
    province_id: Yup.string().required('İl gerekli'),
    district_id: Yup.string().required('İlçe gerekli'),
    female_employee_count: Yup.number().required('Kadın personel sayısı gerekli').min(0, 'Kadın personel sayısı 0 veya daha büyük olmalı'),
    male_employee_count: Yup.number().required('Erkek personel sayısı gerekli').min(0, 'Erkek personel sayısı 0 veya daha büyük olmalı'),
    salary: Yup.number().required('Maaş gerekli').min(100, 'Maaş 100 veya daha büyük olmalı'),
    start_date: Yup.date().required('Başlangıç tarihi gerekli'),
    end_date: Yup.date().required('Bitiş tarihi gerekli')
  }).test(
    'at-least-one-employee',
    'Kadın veya erkek personel sayısı en az bir tanesi 0\'dan büyük olmalı',
    function (values) {
      const { female_employee_count, male_employee_count } = values;
      return (female_employee_count > 0 || male_employee_count > 0);
    }
  ).test(
    'log',
    'log',
    function (values) {
      console.log(values)
      return true
    }
  )


  // Formik setup
  const formik = useFormik({
    initialValues: request,
    enableReinitialize: true, // Reinitialize form when initialValues change
    validationSchema: signinSchema,
    onSubmit: (values) => {
      console.log("values", values)
      payAndPublish()
    },
    onReset: () => {
      console.log("reset")
    },
    validate: (values) => {
      console.log("values", values)
    }
  });


  return (
   
                  <form id="login-form" onSubmit={formik.handleSubmit}>
    <div className="row">
      <div className="col-md-12">
        <div className="col-md-12 dashboard-box margin-top-0">
          <div className="headline">
            <h3>
              <i className="icon-material-outline-business-center"></i> İş İlanı
              Oluştur
            </h3>
          </div>

          <div className="content">
            <ul className="dashboard-box-list">
              <li>
                <div className="job-listing">
                  <div className="job-listing-details">
                    <div className="job-listing-description">
                      <h3 className="job-listing-title">İş Ayrıntıları</h3>
                      <div className="job-listing-footer">
                        <ul>
                          <li>
                            <i className="icon-material-outline-location-on"></i>{' '}
                            İş ilanı ile ilgili ayrıntıları buradan
                            belirleyebilirsiniz
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-8">
                    <div className="submit-field">
                      <h5>İlan Başlığı</h5>
                      <input type="text" className="with-border" name='title' id='title' onChange={formik.handleChange}
                          onBlur={formik.handleBlur} value={formik.values.title} />
                      {formik.errors.title && formik.touched.title ? (
                        <div className="text-sm text-danger">{formik.errors.title}</div>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="submit-field">
                    <h5>Pozisyon</h5>
                      <DropdownWithSearch
                        
                        options={businessAreas}
                        onSelect={(e) => {
                          setRequest({ ...request, business_area_id: parseInt(e) })
                          formik.setFieldValue('position', parseInt(e))
                        }}
                        placeholder="Pozisyon"
                        selectedValue={formik.values.position}
                      />
                      {formik.errors.position && formik.touched.position ? (
                        <div className="text-sm text-danger">{formik.errors.position}</div>
                      ) : null}
                      
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="submit-field">
                      <DropdownWithSearch
                        options={WORKING_OPTIONS}
                        placeholder="Çalışma Şekli"
                        label="Çalışma Şekli"
                        onSelect={(e) => {}}
                      />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="submit-field">
                      <DropdownWithSearch
                        options={provinces}
                        placeholder="İl"
                        label="İl"
                        onSelect={(e) => {
                          loadDistricts(parseInt(e))
                          setRequest({ ...request, province_id: parseInt(e) })
                          formik.setFieldValue('province_id', parseInt(e))
                        }}
                      />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="submit-field">
                      <DropdownWithSearch
                        options={districts}
                        placeholder="İlçe"
                        label="İlçe"
                        onSelect={(e) => {
                          setRequest({ ...request, district_id: parseInt(e) })
                          formik.setFieldValue('district_id', parseInt(e))
                        }
                        }
                      />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="submit-field">
                      
                      <h5>Aranan Kadın Personel Sayısı</h5>
                      <input type="number" className="with-border" placeholder='0' id="female_employee_count" name="female_employee_count"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.female_employee_count} />
                      {formik.errors.female_employee_count && formik.touched.female_employee_count ? (
                        <div className="text-sm text-danger">{formik.errors.female_employee_count}</div>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="submit-field">
                      
                      <h5>Aranan Erkek Personel Sayısı</h5>
                      <input type="number" className="with-border" placeholder='0' id="male_employee_count" name="male_employee_count" 
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.male_employee_count} />

                      {formik.errors.male_employee_count && formik.touched.male_employee_count ? (
                        <div className="text-sm text-danger">{formik.errors.male_employee_count}</div>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="submit-field">
                      <h5>Maaş</h5>
                      <div className="row">
                        <div className="col-xl-12">
                          <div className="input-with-icon">
                            <input
                              className="with-border"
                              type="text"
                              placeholder="Saatlik Ücret"
                              id="salary"
                              name="salary"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.salary}
                            />
                            <i className="currency">TL</i>

                          </div>
                          {formik.errors.salary && formik.touched.salary ? (
                        <div className="text-sm text-danger">{formik.errors.salary}</div>
                      ) : null}
                        </div>
                        
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="submit-field">
                      <h5>İşin Tanımı</h5>
                      <textarea
                        cols={30}
                        rows={5}
                        className="with-border"
                        name="description"
                        id="description"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.description}
                      ></textarea>
                      {formik.errors.description && formik.touched.description ? (
                        <div className="text-sm text-danger">{formik.errors.description}</div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="job-listing">
                  <div className="job-listing-details">
                    <div className="job-listing-description">
                      <h3 className="job-listing-title">
                        <a href="#">Ek ücretler</a>
                      </h3>
                      <div className="job-listing-footer margin-bottom-10">
                        <ul>
                          <li>
                            <i className="icon-brand-accessible-icon"></i> Servis ücreti, yemek ücreti, ikramiye gibi ek ücretleri buradan belirleyebilirsiniz
                          </li>
                        </ul>
                      </div>
                      <div className="row payment-form-row">
                        <div className="col-md-6 margin-top-10">
                          <div className="countdown green">
                            <div className="switches-list">
                              <div className="row">
                                <div className="col-xl-6 col-lg-6 col-md-3 col-sm-3 payments-m">
                                <div className="switch-container">
                                <label className="switch sw">
                                  <input type="checkbox" onChange={(e) => setRequest({...request, disabled: e.target.checked})} />
                                  <span className="switch-button"></span> Servis ücreti
                                  
                                </label>
                                </div>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-3 col-sm-3">
                                <div className="input-with-icon">
                                  <input
                                    className="with-border"
                                    type="number"
                                    placeholder="Ücret"
                                  />
                                  <i className="currency">TL</i>
                                </div>
                                </div>
                             </div>
                            </div>
                          </div>
                        </div>



                        <div className="col-md-6 margin-top-10">
                          <div className="countdown green">
                            <div className="switches-list">
                              <div className="row">
                                <div className="col-xl-6 col-lg-6 col-md-3 col-sm-3 payments-m">
                                <div className="switch-container">
                                <label className="switch sw">
                                  <input type="checkbox" onChange={(e) => setRequest({...request, disabled: e.target.checked})} />
                                  <span className="switch-button"></span> Yemek ücreti
                                  
                                </label>
                              </div>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-3 col-sm-3">
                                <div className="input-with-icon">
                                  <input
                                    className="with-border"
                                    type="number"
                                    placeholder="Ücret"
                                  />
                                  <i className="currency">TL</i>
                                </div>
                                </div>
                             </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 margin-top-10">
                          <div className="countdown green">
                            <div className="switches-list">
                              <div className="row">
                                <div className="col-xl-6 col-lg-6 col-md-3 col-sm-3 payments-m">
                                <div className="switch-container">
                                <label className="switch sw">
                                  <input type="checkbox" onChange={(e) => setRequest({...request, disabled: e.target.checked})} />
                                  <span className="switch-button"></span> İş kıyafeti ücreti
                                  
                                </label>
                              </div>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-3 col-sm-3">
                                <div className="input-with-icon">
                                  <input
                                    className="with-border"
                                    type="number"
                                    placeholder="Ücret"
                                  />
                                  <i className="currency">TL</i>
                                </div>
                                </div>
                             </div>
                            </div>
                          </div>
                        </div>
                        
                        <div className="col-md-6 margin-top-10">
                          <div className="countdown green">
                            <div className="switches-list">
                              <div className="row">
                                <div className="col-xl-6 col-lg-6 col-md-3 col-sm-3 payments-m">
                                <div className="switch-container">
                                <label className="switch sw">
                                  <input type="checkbox" onChange={(e) => setRequest({...request, disabled: e.target.checked})} />
                                  <span className="switch-button"></span> Ek ödeme/ikramiye
                                  
                                </label>
                              </div>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-3 col-sm-3">
                                <div className="input-with-icon">
                                  <input
                                    className="with-border"
                                    type="number"
                                    placeholder="Ücret"
                                  />
                                  <i className="currency">TL</i>
                                </div>
                                </div>
                             </div>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="job-listing">
                  <div className="job-listing-details">
                    <div className="job-listing-description">
                      <h3 className="job-listing-title">
                        <a href="#">Ek Bilgiler</a>
                      </h3>
                      <div className="job-listing-footer margin-bottom-10">
                        <ul>
                          <li>
                            <i className="icon-brand-accessible-icon"></i> İlan
                            ile ilgili ek bilgileri buradan seçebilirsiniz
                          </li>
                        </ul>
                      </div>
                      <div className="row payment-form-row">
                        <div className="col-md-4 margin-top-10">
                          <div className="countdown green">
                            <div className="switches-list">
                              <div className="switch-container">
                                <label className="switch">
                                  <input type="checkbox" onChange={(e) => setRequest({...request, disabled: e.target.checked})} />
                                  <span className="switch-button"></span> İlan
                                  engelli çalışana uygun mu?
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4 margin-top-10">
                          <div className="countdown green">
                            <div className="switches-list">
                              <div className="switch-container">
                                <label className="switch">
                                  <input type="checkbox" onChange={(e) => setRequest({...request, internship: e.target.checked})} />
                                  <span className="switch-button"></span> İlan
                                  staja uygun mu?
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </li>

              <li>
                <div className="job-listing">
                  <div className="job-listing-details">
                    <div className="job-listing-description">
                      <h3 className="job-listing-title">
                        <a href="#">Çalışma düzeni</a>
                      </h3>
                      <div className="margin-top-10">
                        <div className="">
                          <div className="row">
                            <div className="col-md-6 margin-top-10">
                              <div className="submit-field">
                                <h5>İşe Başlama Tarihi</h5>
                                <input type="date" className="with-border" onChange={(e) => {
                                  setRequest({...request, start_date: e.target.value})
                                  setJobStartDate(e.target.value);
                                  formik.setFieldValue('start_date', e.target.value)
                                }} 
                                onBlur={formik.handleBlur}
                                
                                id="start_date" name="start_date" />
                                {formik.errors.start_date && formik.touched.start_date ? (
                        <div className="text-sm text-danger">{formik.errors.start_date}</div>
                      ) : null}
                              </div>
                            </div>
                            <div className="col-md-6 margin-top-10 ">
                              <div className="submit-field">
                                <h5>İşten Çıkış Tarihi</h5>
                                <input type="date" className="with-border" onChange={(e) => {
                                  setRequest({...request, end_date: e.target.value})
                                  setJobEndDate(e.target.value);
                                  formik.setFieldValue('end_date', e.target.value)
                                }} id="end_date" name="end_date" 
                                onBlur={formik.handleBlur}
                                />
                                {formik.errors.end_date && formik.touched.end_date ? (
                        <div className="text-sm text-danger">{formik.errors.end_date}</div>
                      ) : null}
                              </div>
                            </div>
                          </div>


                          <div className="">
                            <div className="row payment-form-row">
                            {workingDays.map((day, index) => (
                              <>
                              <div key={'working_days' + day.date} className="col-lg-2 col-md-4 .col-sm-4 margin-top-10">
                                <div className="countdown yellow margin-bottom-15">
                                  <div className="switches-list">
                                    <div className="switch-container">
                                      <label className="switch">
                                        <input type="checkbox" checked 
                                        onChange={(e) => {
                                          const newWorkingDays = [...workingDays]
                                          newWorkingDays[index].checked = e.target.checked
                                          setWorkingDays(newWorkingDays)
                                        }} />
                                        <span className="switch-button"></span>{' '}
                                        {day.date}
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-2 col-md-4 .col-sm-4">
                                <div className="submit-field margin-top-12">
                                  <div className="bidding-fields">
                                    <div className="bidding-field">
                                      <div className="qtyButtons">
                                        <div className="qtyDec"></div>
                                        <input
                                          type="text"
                                          name="qtyInput"
                                          value={day.start_time}
                                          onChange={(e) => {
                                            const newWorkingDays = [...workingDays]
                                            newWorkingDays[index].start_time = e.target.value
                                            setWorkingDays(newWorkingDays)
                                          }
                                          }
                                        />
                                        <div className="qtyInc"></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-2 col-md-4 .col-sm-4">
                                <div className="submit-field margin-top-12">
                                  <div className="bidding-fields">
                                    <div className="bidding-field">
                                      <div className="qtyButtons">
                                        <div className="qtyDec"></div>
                                        <input
                                          type="text"
                                          name="qtyInput"
                                          value={day.end_time}
                                          onChange={(e) => {
                                            const newWorkingDays = [...workingDays]
                                            newWorkingDays[index].end_time = e.target.value
                                            setWorkingDays(newWorkingDays)
                                          }}
                                        />
                                        <div className="qtyInc"></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              </>
                            ))}

                            </div>
                          </div>
                        </div>
                        
                      </div>
                    </div>
                  </div>
                </div>
              </li>

            
              <li>
                <div className="job-listing">
                  <div className="job-listing-details">
                    <div className="job-listing-description">
                      <h3 className="job-listing-title">
                        <a href="#">İlanını Öne Çıkar</a>
                      </h3>
                      <div className="job-listing-footer margin-bottom-10">
                        <ul>
                          <li>
                            <i className="icon-brand-accessible-icon"></i>{' '}
                            İlanının öne çıkaracak ek özellikleri buradan
                            seçebilirsiniz
                          </li>
                        </ul>
                      </div>
                      <div className="row payment-form-row">
                        {attributes.map((attribute, index) => (
                          <div className="col-md-3 margin-top-10">
                            <div className="countdown green">
                              <div className="switches-list">
                                <div className="switch-container">
                                  <label className="switch">
                                    <input type="checkbox" onChange={(e) => {
                                      const newAttributes = [...attributes]
                                      newAttributes[index].checked = e.target.checked
                                      setAttributes(newAttributes)
                                    }} />
                                    <span className="switch-button"></span>{' '}
                                    {attribute.label}
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}

                        <div className='row'>


                        <div className="col-lg-3	col-md-5 col-sm-5 margin-top-10">
                          <div className="submit-field">
                            <h5>İlan öne çıkarma toplam maliyeti:</h5>
                            <div className="input-with-icon">
                              <input
                                value={attributes.filter((attribute) => attribute.checked).reduce((acc, curr) => acc + curr.price, 0)}
                                className="with-border"
                                type="text"
                                placeholder="İlan öne çıkarma toplam maliyeti"
                              />
                              <i className="currency">TL</i>
                            </div>
                          </div>
                        </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="col-md-3">
        <button
          type='submit'
          className="button full-width button-sliding-icon ripple-effect margin-top-30"
          

        >
          Öde ve İlanı Yayınla{' '}
          <i className="icon-material-outline-arrow-right-alt"></i>
        </button>
      </div>
    </div>
    </form>
    
                
  )
}

export default CreateJobPosting
