import playstoreImg from "@/images/get-play-store.png";
import appstoreImg from "@/images/get-app-store.svg";
import { Link } from "react-router-dom";
import { useModal } from "@/contexts/ModalContext";

const ApplyForJobModal = () => {
  const { hideModal } = useModal();
  return (
    <div className="modal-container">
      <div
        id="small-dialog"
        className="zoom-anim-dialog dialog-with-tabs modal-content"
      >
        <div className="sign-in-form">
          <ul className="popup-tabs-nav" style={{ pointerEvents: "none" }}>
            <li className="active">
              <a href="#tab">Uygulamamızı İndir</a>
            </li>
          </ul>

          <div className="popup-tabs-container">
            <div className="popup-tab-content margin-top-10" id="tab" style={{}}>
              <div className="welcome-text">
                <h3>ParttimeJET Uygulamasını İndir</h3>
              </div>

              <form method="post" id="apply-now-form">
                <div className="row">
                  <div className="col-md-12">
                    <div className="notification error download">
                      <p>
                        İş ilanının ayrıntılarını görebilmek ve başvuru
                        yapabilmek için <strong>ParttimeJET</strong>{" "}
                        uygulamasını indirmeniz gerekmektedir.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-12 margin-bottom-15">
                    <div className="get-app">
                      <div className="get-app-center">
                        <Link to="#">
                          <img
                            src={appstoreImg}
                            height="55"
                            alt="Uygulamamızı Apple Store'den İndir"
                          />
                        </Link>
                        <a href="#">
                          <img
                            src={playstoreImg}
                            height="55"
                            alt="Uygulamamızı Google Play'den İndir"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </form>

              <button
                className="button margin-top-35 full-width button-sliding-icon ripple-effect"
                type="button"
                form="apply-now-form"
                style={{ width: "30px" }}
                onClick={() => hideModal("apply-for-job-modal")}
              >
                Kapat <i className="icon-material-outline-arrow-right-alt"></i>
              </button>
            </div>
          </div>
        </div>

        <button
          title="Close (Esc)"
          type="button"
          className="mfp-close"
          onClick={() => hideModal("apply-for-job-modal")}
        ></button>
      </div>
    </div>
  );
};

export default ApplyForJobModal;
