import Input from "@/components/Input";
import { Switch } from "@/components/Switch";
import { signupSchemaStage1 } from "@/libs/schemes/signup";
import { ISignup } from "@/types/user";
import { Form, Formik, FormikHelpers } from "formik";
import { useState } from "react";
import { Link } from "react-router-dom";

import { useNavigate } from "react-router-dom";
import TokenService from "@/store/token";
import store, { setUser } from "@/store";
import { fetchMe, fetchPreRegisterCompany, fetchRegister, fetchRegisterCompany } from "@/api/user";
import { toast } from "react-toastify";
import { setTempAccessToken } from "@/store/user";

const Register = () => {
  const [registerStage, setRegisterStage] = useState(1);


  const [error, setError] = useState(false);
  const navigate = useNavigate();

  // const handleSubmit: (
  //   values: ISignup,
  //   formikHelpers: FormikHelpers<ISignup>
  // ) => void | Promise<any> = async (values, { setSubmitting }) => {
  //   setError(true);
  //   // setError(false)
  //   // setSubmitting(false)
  // };
  const handleSubmit = async (
    values: ISignup, // Burada ISignup türünü kullanıyoruz
    { setSubmitting }: FormikHelpers<ISignup>
  ) => {
    console.log("clicked");
    setError(false);

    try {
      // if(registerStage === 1){
      //   const response: any = await fetchRegisterCompany(
      //     values.first_name,
      //     values.last_name,
      //     values.email,
      //     values.password,
      //     values.confirm_password,
      //     (values.type = "COMPANY"),
      //     values.company_name,
      //     values.phone.toString()
      //   );
      //   console.log("Fetch Register Response:", response);
  
      //   if (response.status == "0") {
      //     setRegisterStage(2);
      //   } else {
      //     setError(true);
      //     toast.error(response.message);
      //   }
      // }else 
      
      if(registerStage === 1){
        const response: any = await fetchRegisterCompany(
          values.first_name,
          values.last_name,
          values.email,
          values.password,
          values.confirm_password,
          (values.type = "COMPANY"),
          values.company_name,
          values.phone.toString()
        );
        console.log("Fetch Register Response:", response);
  
        if (response.status == "0") {
          if(response.data.verification_required == 'MAIL'){
            store.dispatch(setTempAccessToken(response.data));
            navigate("/register/verify-email");
          }else{
            TokenService.setTokens(response.data.access_token);
  
            const meResponse = await fetchMe();
            TokenService.setUser(meResponse.data);
            store.dispatch(setUser({ user: meResponse.data, ...response.data }));
            navigate("/");
          }
  
        } else {
          setError(true);
          toast.error(response.message);
        }
      }

      
    } catch (error) {
      setError(true);
      console.error("Beklenmeyen bir hata oluştu:", error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={{
        company_name: "",
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        confirm_password: "",
        userAgreement: false,
        privacyPolicy: false,
        terms: false,
        type: "COMPANY",
        phone: "",
      }}
      validationSchema={signupSchemaStage1}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, setFieldValue, getFieldMeta }) => (
        <Form className="form-container">
          <div className="row r-container">
            <div className="col-md-8 offset-md-2">
              <div className="login-register-page">
                <div className="welcome-text">
                  <h3 style={{ fontSize: "26px" }}>Hesap Oluştur</h3>
                  <span>
                    Zaten bir hesabın var mı?{" "}
                    <Link to="/login">Giriş Yap</Link>
                  </span>
                </div>

              {registerStage === 1 && (

                <div id="register-account-form">
                  <div
                    className="input-with-icon-left"
                    title="Şirket Ünvanınızı Girin"
                    data-tippy-placement="bottom"
                  >
                    <Input
                      type="text"
                      name="company_name"
                      id="company_name"
                      placeholder="Şirket Ünvanı"
                      startIcon="icon-material-outline-business"
                    />
                  </div>

                  <div
                    className="input-with-icon-left"
                    title="Adınızı girin"
                    data-tippy-placement="bottom"
                  >
                    <Input
                      type="text"
                      name="first_name"
                      id="first_name"
                      placeholder="Adınız"
                      startIcon="icon-material-outline-account-circle"
                    />
                  </div>

                  <div
                    className="input-with-icon-left"
                    title="Soyadınızı girin"
                    data-tippy-placement="bottom"
                  >
                    <Input
                      type="text"
                      name="last_name"
                      id="last_name"
                      placeholder="Soyadınız"
                      startIcon="icon-material-outline-account-circle"
                    />
                  </div>

                  <div
                    className="input-with-icon-left"
                    title="Email adresinizi girin"
                    data-tippy-placement="bottom"
                  >
                    <Input
                      type="text"
                      name="email"
                      id="email"
                      placeholder="Email adresiniz"
                      startIcon="icon-material-baseline-mail-outline"
                    />
                  </div>

                  <div
                    className="input-with-icon-left"
                    title="Telefon numaranız"
                    data-tippy-placement="bottom"
                  >
                    <Input
                                    id="phone"
                                    name="phone"
                                    type="number"
                                    placeholder="(555) 555 55 55"
                                    startIcon="icon-line-awesome-phone-square"
                                  />
                  </div>

                  <div
                    className="input-with-icon-left"
                    title="Şifreniz en az 8 karakter içermelidir"
                    data-tippy-placement="bottom"
                  >
                    <Input
                      type="password"
                      name="password"
                      id="password"
                      placeholder="Şifreniz"
                      startIcon="icon-material-outline-lock"
                    />
                  </div>

                  <div
                    className="input-with-icon-left"
                    title="Şifrenizi tekrar girin"
                    data-tippy-placement="bottom"
                  >
                    <Input
                      type="password"
                      name="confirm_password"
                      id="confirm_password"
                      placeholder="Şifrenizi tekrar girin"
                      startIcon="icon-material-outline-lock"
                    />
                  </div>
                </div>)}
                {registerStage === 1 && (
                <div className="row">
                  <div className="col-md-12">
                    <div className="switches-list">
                      <div className="switch-container">
                        <Switch
                          name="userAgreement"
                          value="userAgreement"
                          onChange={(e) =>
                            setFieldValue("userAgreement", e.target.checked)
                          }
                          checked={
                            getFieldMeta("userAgreement").value as boolean
                          }
                          label="ParttimeJET kullanıcı sözleşmesini onaylıyorum"
                        />
                        {getFieldMeta("userAgreement").touched &&
                          getFieldMeta("userAgreement").error && (
                            <p className="switch-error">
                              {getFieldMeta("userAgreement").error}
                            </p>
                          )}
                      </div>
                      <div className="switch-container">
                        <Switch
                          name="privacyPolicy"
                          value="privacyPolicy"
                          onChange={(e) =>
                            setFieldValue("privacyPolicy", e.target.checked)
                          }
                          checked={
                            getFieldMeta("privacyPolicy").value as boolean
                          }
                          label="ParttimeJET gizlilik politikasını onaylıyorum"
                        />
                        {getFieldMeta("privacyPolicy").touched &&
                          getFieldMeta("privacyPolicy").error && (
                            <p className="switch-error">
                              {getFieldMeta("privacyPolicy").error}
                            </p>
                          )}
                      </div>
                      <div className="switch-container">
                        <Switch
                          name="terms"
                          value="terms"
                          onChange={(e) =>
                            setFieldValue("terms", e.target.checked)
                          }
                          checked={getFieldMeta("terms").value as boolean}
                          label="Açık rıza metni kapsamında kişisel verilerimin işlenmesini onaylıyorum"
                        />
                        {getFieldMeta("terms").touched &&
                          getFieldMeta("terms").error && (
                            <p className="switch-error">
                              {getFieldMeta("terms").error}
                            </p>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
                )}
                <button
                  className="button full-width button-sliding-icon ripple-effect margin-top-10"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Kayıt Ol{" "}
                  <i className="icon-material-outline-arrow-right-alt"></i>
                </button>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default Register;
