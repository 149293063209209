// import React, { useEffect, useState } from "react";
// import { fetchColleagues } from "@/api/company";
// import userAvatar02 from "@/images/user-avatar-small-02.jpg";
// import { BarLoader } from "react-spinners";
// import { RoleOptions } from "@/libs/constants";
// import { Link } from "react-router-dom";

// const Colleague = () => {
//   type Colleague = {
//     id: number;
//     user: User;
//     company_id: number;
//     role: keyof typeof RoleOptions;
//     company: Company;
//   };

//   type User = {
//     id: number;
//     first_name: string;
//     last_name: string;
//     email: string;
//     phone: string;
//     avatar_id?: number;
//   };

//   type Company = {
//     business_area_id: number;
//   };

//   const [colleagues, setColleagues] = useState<Colleague[]>([]);
//   const [isLoading, setIsLoading] = useState(true);
//   const [error, setError] = useState<string | null>(null);

//   useEffect(() => {
//     const handleFetchColleagues = async () => {
//       try {
//         const response = await fetchColleagues();
//         setColleagues(response.data.company_managers);
//         setIsLoading(false);
//         setIsLoading(false); // Tüm veriler yüklendiğinde loading durumunu false yapıyoruz
//       } catch (err) {
//         const errorMessage = (err as Error).message;
//         setError(errorMessage);
//         setIsLoading(false);
//         setIsLoading(false); // Hata oluşursa da loading durumunu false yapıyoruz
//       }
//     };

//     handleFetchColleagues();
//   }, []);

//   return (
//     <>
//       {isLoading ? (
//         <BarLoader
//           loading={isLoading}
//           aria-label="Loading Spinner"
//           width={"100%"}
//         />
//       ) : (
//         <div>
//           <div className="row">
//             <div className="col-xl-12">
//               <div className="dashboard-box margin-top-0">
//                 <div
//                   className="headline"
//                   style={{
//                     display: "flex",
//                     justifyContent: "space-between",
//                     alignItems: "center",
//                   }} // Flexbox düzeni eklendi
//                 >
//                   <h3 style={{ margin: 0 }}>
//                     <i className="icon-material-outline-supervisor-account"></i>{" "}
//                     Çalışma Arkadaşlarım
//                   </h3>
//                   {/* <h3 style={{ margin: 0 }}>
//                     <a
//                       className="popup-with-zoom-anim button ripple-effect text-white"
//                       style={{
//                         display: "flex",
//                         alignItems: "center",
//                         cursor: "pointer",
//                       }}
//                     >
//                       <i className="icon-feather-user-plus"></i> Çalışma
//                       Arkadaşı Ekle
//                     </a>
//                   </h3> */}

//                   <h3 style={{ margin: 0 }}>
//                     <Link to="add-colleague">
//                       <button
//                         className="icon-feather-user-plus"
//                         style={{
//                           display: "flex",
//                           alignItems: "center",

//                           backgroundColor: "rgb(253, 191, 0)", // Arkaplan rengi ayarlandı
//                           color: "#fff",
//                           border: "none",
//                           padding: "10px 20px",
//                           borderRadius: "5px",
//                           cursor: "pointer",
//                         }}
//                       >
//                         <i className="icon-feather-user-plus" />{" "}
//                         <span> Çalışma Arkadaşı Ekle</span>
//                       </button>
//                     </Link>
//                   </h3>
//                 </div>

//                 <div className="content">
//                   <ul className="dashboard-box-list">
//                     {colleagues.map((colleague, index) => (
//                       <li key={index}>
//                         <div className="freelancer-overview manage-candidates">
//                           <div className="freelancer-overview-inner">
//                             {/* Avatar */}
//                             <div className="freelancer-avatar">
//                               <div className="verified-badge"></div>
//                               <a href="#">
//                                 <img
//                                   src={userAvatar02}
//                                   alt={`${colleague.user.first_name} ${colleague.user.last_name}`}
//                                 />
//                               </a>
//                             </div>

//                             {/* Name */}
//                             <div className="freelancer-name">
//                               <h4>
//                                 <a href="#">
//                                   {colleague.user.first_name}{" "}
//                                   {colleague.user.last_name}
//                                 </a>
//                                 <span className="freelancer-detail-city">
//                                   {colleague.company.business_area_id}
//                                 </span>
//                               </h4>

//                               {/* Details */}
//                               <span className="freelancer-detail-item">
//                                 <a href={`mailto:${colleague.user.email}`}>
//                                   <i className="icon-feather-mail"></i>{" "}
//                                   {colleague.user.email}
//                                 </a>
//                               </span>
//                               <br />
//                               <span className="freelancer-detail-item">
//                                 <i className="icon-feather-phone"></i>{" "}
//                                 {colleague.user.phone
//                                   ? colleague.user.phone
//                                   : "Telefon numarası henüz tanımlanmamış!"}
//                               </span>
//                               <br />
//                               <span className="freelancer-detail-item">
//                                 <i className="icon-feather-user"></i>{" "}
//                                 {RoleOptions[colleague.role]}
//                               </span>

//                               {/* Buttons */}
//                               <div className="buttons-to-right always-visible margin-top-25 margin-bottom-0">
//                                 <a className="popup-with-zoom-anim button ripple-effect text-white">
//                                   <i className="icon-feather-edit"></i> Düzenle
//                                 </a>
//                                 <a className="popup-with-zoom-anim button dark ripple-effect text-white">
//                                   <i className="icon-feather-mail"></i> Mesaj
//                                   Gönder
//                                 </a>
//                                 <a
//                                   href="#"
//                                   className="button gray ripple-effect ico"
//                                   title="Başvuruyu Sil"
//                                   data-tippy-placement="top"
//                                 >
//                                   <i className="icon-feather-trash-2"></i>
//                                 </a>
//                               </div>
//                             </div>
//                           </div>
//                         </div>
//                       </li>
//                     ))}
//                   </ul>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       )}
//     </>
//   );
// };

// export default Colleague;

// ---------------------------------------------------------------

// import React, { useEffect, useState } from "react";
// import { fetchColleagues } from "@/api/company";
// import userAvatar02 from "@/images/user-avatar-small-02.jpg";
// import { BarLoader } from "react-spinners";
// import { RoleOptions } from "@/libs/constants";
// import { Link } from "react-router-dom";
// import { useModal } from "@/contexts/ModalContext";
// import noAvatar from "@/images/no-avatar.png";

// const Colleague = () => {
//   const { showModal } = useModal();

//   type Colleague = {
//     id: number;
//     user: User;
//     company_id: number;
//     role: keyof typeof RoleOptions;
//     company: Company;
//   };

//   type User = {
//     id: number;
//     first_name: string;
//     last_name: string;
//     email: string;
//     phone: string;
//     avatar_id?: number;
//   };

//   type Company = {
//     business_area_id: number;
//   };

//   const [colleagues, setColleagues] = useState<Colleague[]>([]);
//   const [isLoading, setIsLoading] = useState(true);
//   const [error, setError] = useState<string | null>(null);

//   useEffect(() => {
//     const handleFetchColleagues = async () => {
//       try {
//         const response = await fetchColleagues();
//         setColleagues(response.data.company_managers);
//         setIsLoading(false); // Tüm veriler yüklendiğinde loading durumunu false yapıyoruz
//       } catch (err) {
//         const errorMessage = (err as Error).message;
//         setError(errorMessage);
//         setIsLoading(false); // Hata oluşursa da loading durumunu false yapıyoruz
//       }
//     };

//     handleFetchColleagues();
//   }, []);

//   return (
//     <>
//       {isLoading ? (
//         <BarLoader
//           loading={isLoading}
//           aria-label="Loading Spinner"
//           width={"100%"}
//         />
//       ) : (
//         <div>
//           <div className="row">
//             <div className="col-md-12">
//               <div className="dashboard-box margin-top-0">
//                 <div
//                   className="headline"
//                   style={{
//                     display: "flex",
//                     justifyContent: "space-between",
//                     alignItems: "center",
//                   }} // Flexbox düzeni eklendi
//                 >
//                   <h3 style={{ margin: 0 }}>
//                     <i className="icon-material-outline-supervisor-account"></i>{" "}
//                     Çalışma Arkadaşlarım
//                   </h3>
//                   {/* Link kullanılarak add-colleague rotasına geçiş yapılıyor */}
//                   <Link to="/employer/company-info/add-colleague">
//                     <a className="popup-with-zoom-anim button ripple-effect text-white">
//                       <i className="icon-feather-user-plus"></i> Çalışma
//                       Arkadaşı Ekle
//                     </a>
//                   </Link>
//                 </div>

//                 <div className="content">
//                   <ul className="dashboard-box-list">
//                     {colleagues.map((colleague, index) => (
//                       <li key={index}>
//                         <div className="freelancer-overview manage-candidates">
//                           <div className="freelancer-overview-inner">
//                             {/* Avatar */}
//                             <div className="freelancer-avatar">
//                               <div className="verified-badge"></div>
//                               <a href="#">
//                                 <img
//                                   src={noAvatar}
//                                   alt={`${colleague.user.first_name} ${colleague.user.last_name}`}
//                                 />
//                               </a>
//                             </div>

//                             {/* Name */}
//                             <div className="freelancer-name">
//                               <h4>
//                                 <a href="#">
//                                   {colleague.user.first_name}{" "}
//                                   {colleague.user.last_name}
//                                 </a>
//                                 <span className="freelancer-detail-city">
//                                   {colleague.company.business_area_id}
//                                 </span>
//                               </h4>

//                               {/* Details */}
//                               <span className="freelancer-detail-item">
//                                 <a href={`mailto:${colleague.user.email}`}>
//                                   <i className="icon-feather-mail"></i>{" "}
//                                   {colleague.user.email}
//                                 </a>
//                               </span>
//                               <br />
//                               <span className="freelancer-detail-item">
//                                 <i className="icon-feather-phone"></i>{" "}
//                                 {colleague.user.phone
//                                   ? colleague.user.phone
//                                   : "Telefon numarası henüz tanımlanmamış!"}
//                               </span>
//                               <br />
//                               <span className="freelancer-detail-item">
//                                 <i className="icon-feather-user"></i>{" "}
//                                 {RoleOptions[colleague.role]}
//                               </span>

//                               {/* Buttons */}
//                               <div className="buttons-to-right always-visible margin-top-25 margin-bottom-0">
//                                 <a className="popup-with-zoom-anim button ripple-effect text-white">
//                                   <i className="icon-feather-edit"></i> Düzenle
//                                 </a>
//                                 <a
//                                   className="popup-with-zoom-anim button dark ripple-effect text-white"
//                                   onClick={() =>
//                                     showModal("message-modal", {
//                                       user_id: colleague.user.id, // userId yerine user_id olarak gönderiyoruz
//                                       userName: `${colleague.user.first_name} ${colleague.user.last_name}`, // Kullanıcı adı ve soyadı
//                                     })
//                                   }
//                                 >
//                                   <i className="icon-feather-mail"></i> Mesaj
//                                   Gönder
//                                 </a>

//                                 <a
//                                   href="#"
//                                   className="button red ripple-effect ico"
//                                   title="Çalışma Arkadaşını Sil"
//                                   data-tippy-placement="top"
//                                 >
//                                   <i className="icon-feather-trash-2"></i>
//                                 </a>
//                               </div>
//                             </div>
//                           </div>
//                         </div>
//                       </li>
//                     ))}
//                   </ul>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       )}
//     </>
//   );
// };

// export default Colleague;

// -----------------------------------------------------

import React, { useEffect, useState } from "react";
import { fetchColleagues } from "@/api/company";
import userAvatar02 from "@/images/user-avatar-small-02.jpg";
import { BarLoader } from "react-spinners";
import { RoleOptions } from "@/libs/constants";
import { Link, useNavigate } from "react-router-dom"; // useNavigate import edildi
import { useModal } from "@/contexts/ModalContext";
import noAvatar from "@/images/no-avatar.png";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

const Colleague = () => {
  const { showModal } = useModal();
  const navigate = useNavigate(); // useNavigate hook'u kullanılıyor

  type Colleague = {
    id: number;
    user: User;
    company_id: number;
    role: keyof typeof RoleOptions;
    company: Company;
  };

  type User = {
    id: number;
    first_name: string;
    last_name: string;
    email: string;
    phone: string;
    avatar_id?: number;
    avatar?: any;
    verified?: boolean;
  };

  type Company = {
    business_area_id: number;
  };

  const [colleagues, setColleagues] = useState<Colleague[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const handleFetchColleagues = async () => {
      try {
        const response = await fetchColleagues();
        setColleagues(response.data.company_managers);
        setIsLoading(false); // Tüm veriler yüklendiğinde loading durumunu false yapıyoruz
      } catch (err) {
        const errorMessage = (err as Error).message;
        setError(errorMessage);
        setIsLoading(false); // Hata oluşursa da loading durumunu false yapıyoruz
      }
    };

    handleFetchColleagues();
  }, []);

  const handleEditClick = (userId: number) => {
    // Düzenle butonuna tıklanınca yönlendirilecek rota
    navigate(`/colleague/edit/${userId}`);
  };

  return (
    <>
      {isLoading ? (
        <BarLoader
          loading={isLoading}
          aria-label="Loading Spinner"
          width={"100%"}
        />
      ) : (
        <div>
          <div className="row">
            <div className="col-md-12">
              <div className="dashboard-box margin-top-0">
                <div
                  className="headline"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }} // Flexbox düzeni eklendi
                >
                  <h3 style={{ margin: 0 }}>
                    <i className="icon-material-outline-supervisor-account"></i>{" "}
                    Çalışma Arkadaşlarım
                  </h3>
                  {/* Link kullanılarak add-colleague rotasına geçiş yapılıyor */}
                  <Link to="/employer/company-info/add-colleague">
                    <a className="popup-with-zoom-anim button ripple-effect text-white">
                      <i className="icon-feather-user-plus"></i> Çalışma
                      Arkadaşı Ekle
                    </a>
                  </Link>
                </div>

                <div className="content">
                  <ul className="dashboard-box-list">
                    {colleagues.map((colleague, index) => (
                      <li key={index}>
                        <div className="freelancer-overview manage-candidates">
                          <div className="freelancer-overview-inner">
                            {/* Avatar */}
                            <div className="freelancer-avatar">
                              {colleague.user.verified && (
                                <div className="verified-badge"></div>
                              )}

                              <a href="#">
                                <img
                                  src={colleague.user.avatar?.url || noAvatar}
                                  alt={`${colleague.user.first_name} ${colleague.user.last_name}`}
                                />
                              </a>
                            </div>

                            {/* Name */}
                            <div className="freelancer-name">
                              <h4>
                                <a href="#">
                                  {colleague.user.first_name}{" "}
                                  {colleague.user.last_name}
                                </a>
                                <span className="freelancer-detail-city">
                                  {colleague.company.business_area_id}
                                </span>
                              </h4>

                              {/* Details */}
                              <span className="freelancer-detail-item">
                                <a href={`mailto:${colleague.user.email}`}>
                                  <i className="icon-feather-mail"></i>{" "}
                                  {colleague.user.email}
                                </a>
                              </span>
                              <br />
                              <span className="freelancer-detail-item">
                                <i className="icon-feather-phone"></i>{" "}
                                {colleague.user.phone
                                  ? colleague.user.phone
                                  : "Telefon numarası henüz tanımlanmamış!"}
                              </span>
                              <br />
                              <span className="freelancer-detail-item">
                                <i className="icon-feather-user"></i>{" "}
                                {RoleOptions[colleague.role]}
                              </span>

                              {/* Buttons */}
                              <div className="buttons-to-right always-visible margin-top-25 margin-bottom-0">
                                <a
                                  className="popup-with-zoom-anim button ripple-effect text-white"
                                  onClick={
                                    () => handleEditClick(colleague.user.id) // Düzenle butonuna basılınca handleEditClick çağrılıyor
                                  }
                                >
                                  <i className="icon-feather-edit"></i> Düzenle
                                </a>
                                <a
                                  className="popup-with-zoom-anim button dark ripple-effect text-white"
                                  onClick={() =>
                                    showModal("message-modal", {
                                      user_id: colleague.user.id, // userId yerine user_id olarak gönderiyoruz
                                      userName: `${colleague.user.first_name} ${colleague.user.last_name}`, // Kullanıcı adı ve soyadı
                                    })
                                  }
                                >
                                  <i className="icon-feather-mail"></i> Mesaj
                                  Gönder
                                </a>

                                <a
                                  href="#"
                                  className="button red ripple-effect ico"
                                  title="Çalışma Arkadaşını Sil"
                                  data-tippy-placement="top"
                                  onClick={() => {
                                    MySwal.fire({
                                      title:
                                        "Çalışma Arkadaşını Silmek İstediğinize Emin Misiniz?",
                                      icon: "warning",
                                      showCancelButton: true,
                                      confirmButtonColor: "#d33",
                                      cancelButtonColor: "#3085d6",
                                      confirmButtonText: "Evet, Sil!",
                                      cancelButtonText: "İptal",
                                    }).then((result) => {
                                      if (result.isConfirmed) {
                                        MySwal.fire(
                                          "Silindi!",
                                          "Çalışma arkadaşınız başarıyla silindi.",
                                          "success"
                                        );
                                      }
                                    });
                                  }}
                                >
                                  <i className="icon-feather-trash-2"></i>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Colleague;
