import * as Yup from "yup";

const signupSchemaColleague = Yup.object({
  first_name: Yup.string()
    .min(3, "İsim en az 3 haneli olmalı")
    .max(20, "İsim en fazla 20 haneli olmalı")
    .required("İsim gerekli")
    .trim()
    .matches(
      /^[a-zA-Z]{4,}(?: [a-zA-Z]+){0,2}$/,
      "Özel karakterler kullanılamaz"
    ),
  last_name: Yup.string()
    .min(3, "Soyisim en az 3 haneli olmalı")
    .max(20, "Soyisim en fazla 20 haneli olmalı")
    .required("Soyisim gerekli")
    .trim()
    .matches(
      /^[a-zA-Z]{4,}(?: [a-zA-Z]+){0,2}$/,
      "Özel karakterler kullanılamaz"
    ),
  email: Yup.string()
    .email("Email formatı uygun değil. Örn: ornek@mail.com")
    .required("Email adresi gerekli"),

  phone: Yup.string()
    .matches(
      /^[1-9]\d{9}$/, // Türkiye için "5333333333" formatında telefon numarası regex'i
      "Telefon formatı uygun değil. Örnek format: 533-333-3333"
    )
    .required("Telefon numarası gerekli"),
});

export { signupSchemaColleague };
