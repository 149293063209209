import Dropdown from "@/components/Dropdown";
import { Form } from "@/components/Form";
import Input from "@/components/Input";
import Textarea from "@/components/TextArea";
import {
  SUBJECT_ACTIVITY_OPTIONS,
  TAX_DISTRICTS_OPTIONS,
  TAX_PROVINCES_OPTIONS,
  WOMEN_DISTRICTS_OPTIONS,
} from "@/libs/constants";
import React from "react";
import CompanyInformation from "./CompanyInformation";
import CompanyInvoice from "./CompanyInvoice";
import CompanyAuth from "./CompanyAuth";
import CompanyLocation from "./CompanyLocation";

const CorporateInformation = () => {
  return (
    <div className="row">
      <div className="col-md-12">
        <CompanyInformation />

        <CompanyInvoice />

        <CompanyAuth />

        <CompanyLocation />
      </div>
    </div>
  );
};

export default CorporateInformation;
