import axios from 'axios';
import api from './api'; // Adjust the import based on your project structure
import  { AxiosResponse } from 'axios';
import Colleague from '@/pages/companyInfo/Colleague';

interface ICompanyData {
    name: string;
    email: string;
    description: string;
    rating: number;
    business_area: {
      name: string;
      icon: string;
    };
    phone: string;
    website: string;
  }
  
  interface IApiResponse {
    data: ICompanyData;
    status: string;
    message: string;
    error_code: string | null;
  }

// Define TypeScript interfaces for type safety
interface BusinessArea {
  name: string;
  icon: string;
}

interface CompanyInfoData {
  name: string;
  email: string;
  description: string;
  rating: number;
  business_area: BusinessArea;
  phone: string;
  website: string;
}

interface CompanyInfoResponse {
  data: CompanyInfoData;
  status: string;
  message: string;
  error_code: string | null;
}


  export const fetchCompanyInfo = async (): Promise<CompanyInfoData | null> => {
    try {
      const response = await api.get<CompanyInfoResponse>("/profile/company/info"); 
      const result = response as any;
  
      // Check if the response has the expected structure
      if (result) {
        console.log("API Response Data:", result.data); // Debug: Log the response data
        return result.data; // Return the data for further use
      } else {
        console.error("Unexpected response format", result);
        return null; // Return null if the response format is not as expected
      }
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        console.error('Axios error:', error.message);
        if (error.response) {
          console.error('Response data:', error.response.data);
          console.error('Response status:', error.response.status);
          console.error('Response headers:', error.response.headers);
        }
      } else {
        console.error('Unexpected error:', error);
      }
      return null; // Return null on error
    }
  };



 export const updateCompanyInfo = async (companyData: any): Promise<IApiResponse | null> => {
    try {
      // Define the request payload in the specified format
      const requestBody =companyData;
  
      // Make the PUT request
      const response: AxiosResponse<IApiResponse> = await api.put<any>(
        '/profile/company/info', // Replace with your actual endpoint URL
        requestBody, // The payload for the PUT request
      );
  
      // Handle successful response
      console.log('Response:', response.data);
      return response.data; // Return the API response data
    } catch (error: any) {
      // Handle errors
      if (axios.isAxiosError(error)) {
        console.error('Axios error:', error.message);
        if (error.response) {
          console.error('Response data:', error.response.data);
          console.error('Response status:', error.response.status);
          console.error('Response headers:', error.response.headers);
        }
      } else {
        console.error('Unexpected error:', error);
      }
      return null; // Return null if an error occurred
    }
  };
  



  export const fetchCompanyLocations = async () => {
    const response = await api.get('/profile/company/locations');
    return response as any;
  }

  export const updateCompanyLocations = async (locations: any) => {
    const response = await api.put('/profile/company/locations', locations);
    return response as any;
  }

  export const fetchInvoiceInfo = async () => {
    const response = await api.get('/profile/company/tax-info');
    return response as any;
  }

  export const updateCompanyInvoice = async (invoice: any) => {
    const response = await api.put('/profile/company/tax-info', invoice);
    return response as any;
  }

  export const updateCompanyInvoiceFile = async (name:string, mediaType:string, payload:string) => {
    
    const response = await api.post('/profile/company/tax-info/file', {
      name,
      media_type: mediaType,
      payload,
    });
    return response as any;
  }

  export const createJob = async (jobPosting: any) => {
    const response = await api.post('/job/', jobPosting);
    return response as any;
  }


  export const fetchColleagues = async () => {
    const response = await api.get('/profile/company/manager');
    return response as any;
  }


  export const fetchDashboardData = async () => {
    const response = await api.get('job/stats');
    return response as any;
  }



  // API'den iş ilanlarını getiren fonksiyon



  interface ApiResponse {
    data: {
      jobs: Job[];
    };
    status: string;
    message: string;
    error_code: string;
  }
  interface Job {
    id: number;
    title: string;
    salary: number;
    province: {
      name: string;
    };
    created_at: string;
  }
  
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Company {
    id: number;
    name: string;
  }

  // API'den iş ilanlarını getiren fonksiyon
  export const fetchCompanyJobAds = async (
    page: number = 0,
    pageSize: number = 100,
    searchQuery: string = ""
  ): Promise<ApiResponse> => {
    try {
      const response = await api.get<ApiResponse>("/job/", {
        headers: {
          page: page.toString(),
          pagesize: pageSize.toString(),
          "page-search": searchQuery,
        },
      });
      
      return response.data as any;
    } catch (error) {
      console.error("API isteği sırasında hata oluştu:", error);
      throw error;
    }
  };
  


// Candidate Search 


// Define the function to send the search request
interface SearchCandidatesPayload {
  province_id: number;
  business_area_id: number;
  district_id: number;
  age_min: number;
  age_max: number;
  start_time: string;
  end_time: string;
  salary_min: number;
  salary_max: number;
  rating_min: number;
  education_level: string;
  language_level: string;
  experienced_candidate: boolean;
  disabled_candidate: boolean;
  retired_candidate: boolean;
  driving_license: boolean;
  military_service: boolean;
  turkish_citizen: boolean;
  only_photo_candidate: boolean;
  only_verified_candidate: boolean;
  only_favorite_candidate: boolean;
}

export const searchCandidates = async (payload: SearchCandidatesPayload) => {
  try {
    const response = await api.post("/job/candidates",payload);
    
    // Handle success, returning data or logging it
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error("Error fetching candidates:", error.response?.data);
    } else {
      console.error("An unexpected error occurred", error);
    }
    throw error;  // Optionally rethrow to be handled in calling function
  }
};

// Example of how to call this function with a payload
const payload: SearchCandidatesPayload = {
  province_id: 0,
  business_area_id: 0,
  district_id: 0,
  age_min: 18,
  age_max: 35,
  start_time: "2024-10-13T02:06:17.372Z",
  end_time: "2024-10-13T02:06:17.372Z",
  salary_min: 3000,
  salary_max: 6000,
  rating_min: 3,
  education_level: "PRIMARY_SCHOOL",
  language_level: "BEGINNER",
  experienced_candidate: true,
  disabled_candidate: false,
  retired_candidate: false,
  driving_license: true,
  military_service: true,
  turkish_citizen: true,
  only_photo_candidate: true,
  only_verified_candidate: false,
  only_favorite_candidate: false,
};



