import React from "react";

const CompanyAuth = () => {
  return (
    <>
      <div className="col-md-12 dashboard-box margin-top-30">
        <div className="headline">
          <h3>
            <i className="icon-line-awesome-comment-o"></i> Yetki
          </h3>
          <p className="authority-text">
            Tüm bilgileri tamamla, onaylı firma ol ve iş gücüne ulaş
          </p>
        </div>
        <div className="content with-padding padding-bottom-0">
          <div className="row">
            <div className="col-md-12">
              <div className="submit-field">
                <div className="uploadButton margin-top-8">
                  <input
                    className="uploadButton-input"
                    type="file"
                    accept="image/*, application/pdf"
                    id="upload"
                    multiple={true}
                  />
                  <label
                    className="uploadButton-button ripple-effect"
                    htmlFor="upload"
                  >
                    Yetki Belgesi Ekle
                  </label>
                  <span className="uploadButton-file-name">
                    *Yetki belgenizi yükleyin
                  </span>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <button
                      className="button full-width button-sliding-icon ripple-effect margin-top-30"
                      type="submit"
                      form="login-form"
                    >
                      Bilgilerimi Güncelle{" "}
                      <i className="icon-material-outline-arrow-right-alt"></i>
                    </button>
                  </div>
                </div>
              </div>
              <br />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompanyAuth;
