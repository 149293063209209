// import React, { useCallback, useEffect, useState, useRef } from "react";
// import "./multiRangeSlider.css";

// interface MultiRangeSliderProps {
//   min: number;
//   max: number;
//   onChange: (values: { min: number; max: number }) => void;
// }

// const MultiRangeSlider: React.FC<MultiRangeSliderProps> = ({
//   min,
//   max,
//   onChange,
// }) => {
//   const [minVal, setMinVal] = useState<number>(min);
//   const [maxVal, setMaxVal] = useState<number>(max);
//   const range = useRef<HTMLDivElement>(null);

//   const getPercent = useCallback(
//     (value: number) => ((value - min) / (max - min)) * 100,
//     [min, max]
//   );

//   useEffect(() => {
//     const minPercent = getPercent(minVal);
//     const maxPercent = getPercent(maxVal);

//     if (range.current) {
//       range.current.style.left = `${minPercent}%`;
//       range.current.style.width = `${maxPercent - minPercent}%`;
//     }
//   }, [minVal, maxVal, getPercent]);

//   useEffect(() => {
//     onChange({ min: minVal, max: maxVal });
//   }, [minVal, maxVal, onChange]);

//   const handleMinChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//     const value = Math.min(Number(event.target.value), maxVal - 1);
//     setMinVal(value);
//   };

//   const handleMaxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//     const value = Math.max(Number(event.target.value), minVal + 1);
//     setMaxVal(value);
//   };

//   return (
//     <div className="range-container">
//       <input
//         type="range"
//         min={min}
//         max={max}
//         value={minVal}
//         onChange={handleMinChange}
//         className="thumb thumb--left"
//         style={{ zIndex: minVal > max - 100 ? 5 : undefined }}
//       />
//       <input
//         type="range"
//         min={min}
//         max={max}
//         value={maxVal}
//         onChange={handleMaxChange}
//         className="thumb thumb--right"
//       />

//       <div className="slider">
//         <div className="slider__track" />
//         <div ref={range} className="slider__range" />
//         <div
//           className="range-tooltip"
//           role="presentation"
//         >
//           <div className="tooltip-arrow"></div>
//           <div className="tooltip-inner">{minVal} - {maxVal}</div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default MultiRangeSlider;

import React, { useCallback, useEffect, useState, useRef } from "react";
import "./multiRangeSlider.css";

interface MultiRangeSliderProps {
  min: number;
  max: number;
  onChange: (values: { min: number; max: number }) => void;
}

const MultiRangeSlider: React.FC<MultiRangeSliderProps> = ({
  min,
  max,
  onChange,
}) => {
  const [minVal, setMinVal] = useState<number>(min);
  const [maxVal, setMaxVal] = useState<number>(max);
  const range = useRef<HTMLDivElement>(null);

  const getPercent = useCallback(
    (value: number) => ((value - min) / (max - min)) * 100,
    [min, max]
  );

  useEffect(() => {
    const minPercent = getPercent(minVal);
    const maxPercent = getPercent(maxVal);

    if (range.current) {
      range.current.style.left = `${minPercent}%`;
      range.current.style.width = `${maxPercent - minPercent}%`;
    }
  }, [minVal, maxVal, getPercent]);

  useEffect(() => {
    onChange({ min: minVal, max: maxVal });
  }, [minVal, maxVal, onChange]);

  const handleMinChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = Math.min(Number(event.target.value), maxVal - 1);
    setMinVal(value);
  };

  const handleMaxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = Math.max(Number(event.target.value), minVal + 1);
    setMaxVal(value);
  };

  return (
    <div className="range-container">
      <input
        type="range"
        min={min}
        max={max}
        value={minVal}
        onChange={handleMinChange}
        className="thumb thumb--left"
        style={{ zIndex: minVal > max - 100 ? 5 : undefined }}
      />
      <input
        type="range"
        min={min}
        max={max}
        value={maxVal}
        onChange={handleMaxChange}
        className="thumb thumb--right"
      />

      <div className="slider">
        <div className="slider__track" />
        <div ref={range} className="slider__range" />
        <div className="range-tooltip" role="presentation">
          <div className="tooltip-arrow"></div>
          <div className="tooltip-inner">
            {minVal} - {maxVal}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MultiRangeSlider;
