import MainLayout from './components/MainLayout'
import RoutesConfig from './routes'
import { Provider } from 'react-redux'
import store from './store'
import tr from 'javascript-time-ago/locale/tr'
import TimeAgo from "javascript-time-ago";
TimeAgo.addDefaultLocale(tr)

function App() {
  return (
    <Provider store={store}>
    <MainLayout>
      <RoutesConfig />
    </MainLayout>
    </Provider>
  )
}

export default App
