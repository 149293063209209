import React, { useState, useEffect } from "react";
import { fetchCompanyJobAds } from "@/api/company";

interface ApiResponse {
  data: {
    jobs: Job[];
  };
  status: string;
  message: string;
  error_code: string;
}
interface Job {
  id: number;
  title: string;
  salary: number;
  province: {
    name: string;
  };
  created_at: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface Company {
  id: number;
  name: string;
}

const ActiveJobs = () => {
  const [jobs, setJobs] = useState<Job[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const response = await fetchCompanyJobAds(0, 100, "") as any; // API isteği başlatılır
        setJobs(response.jobs); // API'den gelen iş ilanlarını state'e set ediyoruz
        setLoading(false);
      } catch (err) {
        
        setError("Veriler alınırken hata oluştu");
        setLoading(false);
      }
    };

    fetchJobs();
  }, []);

  if (loading) return <div>Yükleniyor...</div>;
  if (error) return <div>{error}</div>;

  return (
    <>
    <div className="row no-message-box">
      <div className="col-md-12">
        <div className="dashboard-box margin-top-0">
          <div className="headline">
            <h3>
              <i className="icon-line-awesome-calendar-check-o"></i>{' '}
              Aktif İlanlar
            </h3>
          </div>

          <div className="content">
            <ul className="dashboard-box-list">
              <li>
                <div className="job-listing">
                  <div className="job-listing-details">
                    <div className="job-listing-description">
                      <h3 className="job-listing-title">
                        <a>Üzgünüz, henüz hiç aktif ilanınız yok.</a>
                      </h3>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

    </div>

      <style>{`
        .listings-container {
          display: grid;
          grid-template-columns: 1fr; /* Tek sütun */

        }

        .job-listing {
          display: flex;
          flex-direction: column;

        }

        .job-listing-details {
          display: flex;
          align-items: center;
        }


      `}</style>
    </>
  );
};

export default ActiveJobs;
