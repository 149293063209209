import bgImage from "@/images/single-job.jpg";
import companyLogo3 from "@/images/company-logo-03a.png";
import trImg from "@/images/flags/tr.svg";
import playstoreImg from "@/images/get-play-store.png";
import appstoreImg from "@/images/get-app-store.svg";
import { useModal } from "@/contexts/ModalContext";
import { useState } from "react";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { fetchJobDetailPublic } from "@/api/jobs";
import { toast } from "react-toastify";
import { BarLoader } from "react-spinners";
import moment from "moment";
import ReactTimeAgo from "react-time-ago";

const JobApplication = () => {
  const { showModal } = useModal();
  const [isBookmarked, setIsBookmarked] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const handleClick = () => {
    setIsBookmarked(!isBookmarked);
  };

  const params:any= useParams()

  
  const [text, setText] = useState("");
  const [isCopied, setIsCopied] = useState(false);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setText(event.target.value);
  };

  const navigate = useNavigate();
  const [jobDetail, setJobDetail] = useState<any>(null);
  
  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(window.location.href)
      .then(() => {
        setIsCopied(true); // Set copied state to true
        setTimeout(() => setIsCopied(false), 2000); // Reset after 2 seconds
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };


  React.useEffect(() => {
    const handleJobDetail = async (id: string) => {
      setIsLoading(true);
      const response = await fetchJobDetailPublic(id);
      if (response.status === "0") {
        setJobDetail(response.data);
      } else {
        toast.error(response.message);
        navigate("/");
      }
      setIsLoading(false);
    }
    handleJobDetail(params.id);
    
  }, [params]);


  return (
    isLoading ? <BarLoader color="#0e4688" loading={true} width="100%" height={4} /> :

    <>
      <div
        className="single-page-header"
        style={{ backgroundImage: `url(${bgImage})` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="single-page-header-inner">
                <div className="left-side">
                  <div className="header-image">
                    <a href="single-company-profile.html">
                      <img src={companyLogo3} alt="" />
                    </a>
                  </div>
                  <div className="header-details">
                    <h3>{jobDetail.business_area.name}</h3>
                    <h5>{jobDetail.company.name}</h5>
                    <ul>
                      <li>
                        <i className="icon-material-outline-business"></i>{" "}
                        İstanbul
                      </li>
                      <li>
                        <div className="star-rating" data-rating="4.9">
                          <span className="star"></span>
                          <span className="star"></span>
                          <span className="star"></span>
                          <span className="star"></span>
                          <span className="star"></span>
                        </div>
                      </li>
                      <li>
                        <img className="flag" src={trImg} alt="" /> Türkiye
                      </li>
                      {jobDetail.company.verifed && (
                        <li>
                        <div className="verified-badge-with-title">
                          Doğrulanmış İşveren
                        </div>
                      </li>
                      )}
                    </ul>
                  </div>
                </div>
                <div className="right-side">
                  <div className="salary-box">
                    <div className="salary-type">Saat Aralığı</div>
                    <div className="salary-amount">{moment(jobDetail.working_hours[0].start_time).format('hh:mm')} - {moment(jobDetail.working_hours[0].end_time).format('hh:mm')}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="background-image-container"
          style={{ backgroundImage: `url(${bgImage})` }}
        ></div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-xl-8 col-lg-8 content-right-offset">
            <div className="single-page-section">
              <h3 className="margin-bottom-0">{jobDetail.title}</h3>
              <p>
               {jobDetail.description}
              </p>
              
            </div>


            <div className="row">
              <div className="col-md-12">
                <div className="notification error download">
                  <p>
                    İş ilanının ayrıntılarını görebilmek için{" "}
                    <strong>ParttimeJET</strong> uygulamasını indirmeniz
                    gerekmektedir.
                  </p>
                </div>
              </div>
              <div className="col-md-12 margin-bottom-15">
                <div className="get-app">
                  <div className="get-app-center">
                    <a href="">
                      <img
                        src={appstoreImg}
                        height="55"
                        alt="Uygulamamızı Apple Store'den İndir"
                      />
                    </a>
                    <a href="">
                      <img
                        src={playstoreImg}
                        height="55"
                        alt="Uygulamamızı Google Play'den İndir"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-4 col-lg-4">
            <div className="sidebar-container">
              <a
                href="#"
                onClick={() => showModal("apply-for-job-modal")}
                className="apply-now-button popup-with-zoom-anim text-white"
              >
                İş Başvurusu Yap{" "}
                <i className="icon-material-outline-arrow-right-alt"></i>
              </a>

              <div className="sidebar-widget">
                <div className="job-overview">
                  <div className="job-overview-headline">İş Ayrıntıları</div>
                  <div className="job-overview-inner">
                    <ul>
                      <li>
                        <i className="icon-material-outline-location-on"></i>
                        <span>Adres</span>
                        <h5>İstanbul, Türkiye</h5>
                      </li>
                      <li>
                        <i className="icon-material-outline-business-center"></i>
                        <span>Çalışma Şekli</span>
                        <h5>Tam Zamanlı</h5>
                      </li>
                      <li>
                        <i className="icon-material-outline-local-atm"></i>
                        <span>Maaş Aralığı</span>
                        <h5>20.000₺ - 30.000₺</h5>
                      </li>
                      <li>
                        <i className="icon-material-outline-access-time"></i>
                        <span>Yayınlanma Tarihi</span>
                        <h5><ReactTimeAgo date={jobDetail.updated_at}/></h5>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="sidebar-widget">
                <h3>Paylaş</h3>

                <div className="copy-url">
                  <input
                    id="copy-text"
                    type="text"
                    value={window.location.href}
                    className="with-border"
                    placeholder="Enter text here"
                  />
                  <button
                    className={`ripple-effect ${
                      isCopied ? "copied-url-button" : "copy-url-button"
                    }`}
                    onClick={copyToClipboard}
                    title="Kopyala"
                    data-tippy-placement="top"
                  >
                    <i className="icon-material-outline-file-copy"></i>
                  </button>
                </div>

                <div className="share-buttons margin-top-25">
                  <div className="share-buttons-trigger">
                    <i className="icon-feather-share-2"></i>
                  </div>
                  <div className="share-buttons-content">
                    <span>
                      Beğendin mi? <strong>İlanı Paylaş!</strong>
                    </span>
                    <ul className="share-buttons-icons">
                      <li>
                        <a
                          href={"https://www.facebook.com/sharer/sharer.php?u=" + encodeURIComponent(window.location.href)}
                          data-button-color="#3b5998"
                          target="_blank"
                          title="Facebook'da Paylaş"
                          data-tippy-placement="top"
                        >
                          <i className="icon-brand-facebook-f"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href={"http://twitter.com/share?text=" + encodeURIComponent(jobDetail.title) + "&url=" + encodeURIComponent(window.location.href)}
                          data-button-color="#1da1f2"
                          target="_blank"
                          title="Twitter'da Paylaş"
                          data-tippy-placement="top"
                        >
                          <i className="icon-brand-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href={"https://www.linkedin.com/shareArticle?url=" + encodeURIComponent(window.location.href) + "&title=" + encodeURIComponent(jobDetail.title)}
                          target="_blank"
                          data-button-color="#0077b5"
                          title="LinkedIn'de Paylaş"
                          data-tippy-placement="top"
                        >
                          <i className="icon-brand-linkedin-in"></i>
                        </a>
                      </li>
                     
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default JobApplication;