import { useModal } from '@/contexts/ModalContext'
import React, { useState } from 'react'

const AcceptanceModal = () => {
  const { hideModal } = useModal()
  const [selectedOption, setSelectedOption] = useState<string | null>(null)

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(event.target.value)
  }
  return (
    <div className="modal-container">
      <div id="small-dialog-1" className="zoom-anim-dialog dialog-with-tabs modal-content">
        {/* Tabs */}
        <div className="sign-in-form">
          <ul className="popup-tabs-nav" style={{ pointerEvents: 'none' }}>
            <li className="active">
              <a href="#tab1">Başvuruyu Kabul Et</a>
            </li>
          </ul>
          <div className="popup-tabs-container">
            {/* Tab */}
            <div className="popup-tab-content margin-top-10" id="tab" style={{}}>
              {/* Welcome Text */}
              <div className="welcome-text">
                <h3>Ali Limon'un İş Başvurusunu Kabul Et</h3>
                <div className="bid-acceptance margin-top-15">30.000₺/Aylık</div>
              </div>
              <form id="terms">
                <div className="radio">
                  <input
                    id="radio-1"
                    name="radio"
                    type="radio"
                    required
                    value="option1"
                    checked={selectedOption === 'option1'}
                    onChange={handleRadioChange}
                  />
                  <label htmlFor="radio-1">
                    <span className="radio-label"></span> Kullanıcı sözleşmesini
                    okudum ve onaylıyorum.
                  </label>
                </div>
              </form>
              {/* Button */}
              <button
                className="margin-top-15 button full-width button-sliding-icon ripple-effect"
                type="submit"
                form="terms"
                style={{ width: '30px' }}
              >
                İş Başvurusunu Kabul Et{' '}
                <i className="icon-material-outline-arrow-right-alt"></i>
              </button>
            </div>
          </div>
        </div>
        <button
          title="Close (Esc)"
          type="button"
          className="mfp-close"
          onClick={() => hideModal('acceptance-modal')}
        ></button>
      </div>
    </div>
  )
}

export default AcceptanceModal
