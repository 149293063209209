import Input from "@/components/Input";
import { signupSchemaStage1 } from "@/libs/schemes/signup";
import { IPassword, ISignup } from "@/types/user";
import { FormikHelpers, Formik, Form } from "formik";
import { useState } from "react";

const PasswordRenewal = () => {
  const [error, setError] = useState(false);

  const handleSubmit: (
    values: IPassword,
    formikHelpers: FormikHelpers<IPassword>
  ) => void | Promise<any> = async (values, { setSubmitting }) => {
    setError(true);
    // setError(false)
    // setSubmitting(false)
  };
  return (
    <Formik
      initialValues={{
        password: "",
        confirm_password: "",
      }}
      validationSchema={signupSchemaStage1}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <Form className="form-container">
          <div className="container">
            <div className="row l-container">
              <div className="col-md-8 offset-md-2">
                <div className="login-register-page">
                  <div className="welcome-text">
                    <h3>Şifre Yenileme</h3>
                    <span>Belirlemek istediğin yeni şifreni gir</span>
                  </div>
                  <div id="login-form">
                    <div
                      className="input-with-icon-left"
                      title="Yeni şifrenizi girin"
                      data-tippy-placement="bottom"
                    >
                      <Input
                        name="password"
                        id="password"
                        placeholder="Yeni şifre"
                        type="password"
                        startIcon="icon-material-outline-lock"
                      />
                    </div>

                    <div
                      className="input-with-icon-left"
                      title="Yeni şifrenizi tekrar girin"
                      data-tippy-placement="bottom"
                    >
                      <Input
                        name="reTypePassword"
                        id="reTypePassword"
                        placeholder="Yeni şifrenizi tekrar girin"
                        type="password"
                        startIcon="icon-material-outline-lock"
                      />
                    </div>
                  </div>

                  <button
                    className="button full-width button-sliding-icon ripple-effect margin-top-10"
                    type="submit"
                  >
                    Şifremi Yenile{" "}
                    <i className="icon-material-outline-arrow-right-alt"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default PasswordRenewal;
