import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { BarLoader } from "react-spinners";
import { toast } from "react-toastify";

// Doğrulama için Yup şeması
const validationSchema = Yup.object({
  first_name: Yup.string().required("İsim zorunludur"),
  last_name: Yup.string().required("Soyisim zorunludur"),
  email: Yup.string()
    .email("Geçerli bir email adresi giriniz")
    .required("Email zorunludur"),
});

const ColleagueEditPage = () => {
  const { id } = useParams<{ id: string }>();
  const [initialValues, setInitialValues] = useState({
    first_name: "Ahmet",
    last_name: "Tancı",
    email: "ahmet.tanci@example.com",
  });
  const [isLoading, setIsLoading] = useState(false); // Statik sayfa için yükleme varsayılan olarak false
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    // Backend hazır olduğunda API çağrısını bu kısımda yapabiliriz
    // const fetchData = async () => {
    //   try {
    //     const response = await fetchColleagueById(id);
    //     const colleague = response.data;
    //     setInitialValues({
    //       first_name: colleague.first_name || "",
    //       last_name: colleague.last_name || "",
    //       email: colleague.email || "",
    //     });
    //     setIsLoading(false);
    //   } catch (err) {
    //     const errorMessage = (err as Error).message;
    //     setError(errorMessage);
    //     setIsLoading(false);
    //   }
    // };
    // fetchData();
  }, [id]);

  const handleSubmit = async (values: typeof initialValues) => {
    // Backend olmadığından şu an yorum satırına alındı
    // try {
    //   await updateColleague(id, values); // Güncelleme isteği
    //   toast.success("Kullanıcı başarıyla güncellendi!");
    // } catch (err) {
    //   toast.error("Güncelleme sırasında bir hata oluştu.");
    // }
    toast.info("Güncelleme API'si henüz entegre edilmedi.");
  };

  if (isLoading) {
    return <BarLoader loading={isLoading} width={"100%"} />;
  }

  if (error) {
    return <div>Bir hata oluştu: {error}</div>;
  }

  return (
    <div className="container">
      <h2>
        {initialValues.first_name} {initialValues.last_name} Düzenle
      </h2>
      <Formik
        initialValues={initialValues} // Başlangıç değerleri statik
        validationSchema={validationSchema} // Doğrulama şeması
        enableReinitialize={true} // Form değerlerini başlat
        onSubmit={handleSubmit} // Form gönderme işlemi
      >
        {({ isSubmitting }) => (
          <Form>
            <div className="form-group">
              <label htmlFor="first_name">İsim</label>
              <Field
                type="text"
                id="first_name"
                name="first_name"
                className="form-control"
              />
              <ErrorMessage
                name="first_name"
                component="div"
                className="text-danger"
              />
            </div>

            <div className="form-group">
              <label htmlFor="last_name">Soyisim</label>
              <Field
                type="text"
                id="last_name"
                name="last_name"
                className="form-control"
              />
              <ErrorMessage
                name="last_name"
                component="div"
                className="text-danger"
              />
            </div>

            <div className="form-group">
              <label htmlFor="email">Email</label>
              <Field
                type="email"
                id="email"
                name="email"
                className="form-control"
              />
              <ErrorMessage
                name="email"
                component="div"
                className="text-danger"
              />
            </div>

            <button
              type="submit"
              className="btn btn-primary"
              disabled={isSubmitting}
            >
              {isSubmitting ? "Güncelleniyor..." : "Güncelle"}
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ColleagueEditPage;
