import * as Yup from "yup";

const signupSchemaStage1 = Yup.object({
  first_name: Yup.string()
    .min(3, "Adınız en az 3 harfli olmalıdır")
    .max(20, "Adınız en fazla 20 harfli olmalıdır")
    .required("Ad gereklidir")
    .trim()
    .matches(
      /^[a-zA-ZçÇğĞşŞıİöÖüÜ]{3,}(?: [a-zA-ZçÇğĞşŞıİöÖüÜ]+){0,2}$/,
      "Adınız özel karakterler içeremez"
    ),
  last_name: Yup.string()
    .min(3, "Soyadınız en az 3 harfli olmalıdır")
    .max(20, "Soyadınız en fazla 20 harfli olmalıdır")
    .required("Soyad gereklidir")
    .trim()
    .matches(
      /^[a-zA-ZçÇğĞşŞıİöÖüÜ]{3,}(?: [a-zA-ZçÇğĞşŞıİöÖüÜ]+){0,2}$/,
      "Soyadınız özel karakterler içeremez"
    ),
  email: Yup.string()
    .email("Uygun email formatı girilmedi")
    .required("Email gereklidir"),
  password: Yup.string()
    .min(8, "Şifreniz en az 8 haneli olmalıdır")
    .required("Şifre gereklidir"),
  confirm_password: Yup.string()
    .required("Şifre tekrarı gereklidir")
    .oneOf([Yup.ref("password"), ""], "Şifreler uyuşmadı"),
  company_name: Yup.string()
    .min(3, "Şirket ünvanı en az 3 harfli olmalıdır")
    .max(20, "Şirket ünvanı en fazla 20 harfli olmalıdır")
    .required("Şirket ünvanı gereklidir")
    .trim()
    .matches(
      /^[a-zA-ZçÇğĞşŞıİöÖüÜ]{3,}(?: [a-zA-ZçÇğĞşŞıİöÖüÜ]+){0,2}$/,
      "Şirket ünvanı özel karakterler içeremez"
    ),

  userAgreement: Yup.boolean()
    .oneOf([true], "Kullanıcı sözleşmesi onaylanmalıdır")
    .required("Kullanıcı sözleşmesi onaylanmalıdır"),
  privacyPolicy: Yup.boolean()
    .oneOf([true], "Gizlilik politikası onaylanmalıdır")
    .required("Gizlilik politikası onaylanmalıdır"),
  terms: Yup.boolean()
    .oneOf([true], "Açık rıza metni onaylanmalıdır")
    .required("Açık rıza metni onaylanmalıdır"),
});

export { signupSchemaStage1 };
