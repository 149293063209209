// import React, { createContext, useState, useContext, ReactNode } from "react";

// interface ModalContextType {
//   showModal: (id: string) => void;
//   hideModal: (id: string) => void;
//   isModalVisible: (id: string) => boolean;

// }
// interface ModalContextType {
//   showModal: (
//     id: string,
//     data?: any,
//     component?: React.ComponentType | React.ReactNode
//   ) => void;
//   hideModal: (id: string) => void;
//   isModalVisible: (id: string) => boolean;
// }

// const ModalContext = createContext<ModalContextType | undefined>(undefined);

// export const ModalProvider: React.FC<{ children: ReactNode }> = ({
//   children,
// }) => {
//   const [modals, setModals] = useState<Record<string, boolean>>({});

//   const showModal = (id: string) => {
//     setModals((prevModals) => ({ ...prevModals, [id]: true }));
//   };

//   const hideModal = (id: string) => {
//     setModals((prevModals) => ({ ...prevModals, [id]: false }));
//   };

//   const isModalVisible = (id: string) => !!modals[id];

//   return (
//     <ModalContext.Provider value={{ showModal, hideModal, isModalVisible }}>
//       {children}
//     </ModalContext.Provider>
//   );
// };

// export const useModal = (): ModalContextType => {
//   const context = useContext(ModalContext);
//   if (context === undefined) {
//     throw new Error("useModal must be used within a ModalProvider");
//   }
//   return context;
// };

import React, { createContext, useState, useContext, ReactNode } from "react";

interface ModalContextType {
  showModal: (id: string, data?: any, component?: React.ReactNode) => void;
  hideModal: (id: string) => void;
  isModalVisible: (id: string) => boolean;
  modalData: any; // Dinamik veriyi saklamak için kullanılan state
  modalComponent: React.ReactNode | null; // Dinamik component'i saklamak için kullanılan state
}

const ModalContext = createContext<ModalContextType | undefined>(undefined);

export const ModalProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [modals, setModals] = useState<Record<string, boolean>>({});
  const [modalData, setModalData] = useState<any>(null); // Dinamik veriyi tutmak için state
  const [modalComponent, setModalComponent] = useState<React.ReactNode | null>(
    null
  ); // Dinamik component'i tutmak için state

  // showModal fonksiyonunu data ve component parametreleri ile güncelledik
  const showModal = (id: string, data?: any, component?: React.ReactNode) => {
    setModals((prevModals) => ({ ...prevModals, [id]: true }));
    setModalData(data); // modalData'yı güncelle
    setModalComponent(component ?? null); // modalComponent'i güncelle
  };

  // hideModal fonksiyonu modal kapatıldığında modalData'yı ve modalComponent'i sıfırlar
  const hideModal = (id: string) => {
    setModals((prevModals) => ({ ...prevModals, [id]: false }));
    setModalData(null); // Modal kapatıldığında modalData'yı sıfırlıyoruz
    setModalComponent(null); // Modal kapatıldığında modalComponent'i sıfırlıyoruz
  };

  const isModalVisible = (id: string) => !!modals[id];

  return (
    <ModalContext.Provider
      value={{
        showModal,
        hideModal,
        isModalVisible,
        modalData,
        modalComponent, // Context'e modalComponent'i ekliyoruz
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export const useModal = (): ModalContextType => {
  const context = useContext(ModalContext);
  if (context === undefined) {
    throw new Error("useModal must be used within a ModalProvider");
  }
  return context;
};
