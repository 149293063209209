// import { useModal } from "@/contexts/ModalContext";

// const MessageModal = () => {
//   const { hideModal } = useModal();
//   return (
//     <div className="modal-container">
//       <div
//         id="small-dialog-2"
//         className="zoom-anim-dialog dialog-with-tabs modal-content"
//       >
//         {/* Tabs */}
//         <div className="sign-in-form">
//           <ul className="popup-tabs-nav" style={{ pointerEvents: "none" }}>
//             <li className="active">
//               <a href="#tab2">Mesaj Gönder</a>
//             </li>
//           </ul>
//           <div className="popup-tabs-container">
//             {/* Tab */}
//             <div className="popup-tab-content" id="tab2">
//               {/* Welcome Text */}
//               <div className="welcome-text">
//                 <h3>Ali Limon'a Mesaj Gönder</h3>
//               </div>
//               {/* Form */}
//               <form method="post" id="send-pm">
//                 <textarea
//                   name="textarea"
//                   cols={10}
//                   placeholder="Mesaj içeriği"
//                   className="with-border"
//                   required
//                 />
//               </form>
//               {/* Button */}
//               <button
//                 className="button full-width button-sliding-icon ripple-effect"
//                 type="submit"
//                 form="send-pm"
//                 style={{ width: "30px" }}
//               >
//                 Gönder <i className="icon-material-outline-arrow-right-alt"></i>
//               </button>
//             </div>
//           </div>
//         </div>
//         <button
//           title="Close (Esc)"
//           type="button"
//           className="mfp-close"
//           onClick={() => hideModal("message-modal")}
//         ></button>
//       </div>
//     </div>
//   );
// };

// export default MessageModal;

import React, { useState } from "react";
import { useModal } from "@/contexts/ModalContext";
import { sendMessageToColleague } from "@/api/account"; // API fonksiyonunu içe aktar
import { toast } from "react-toastify";

const MessageModal = () => {
  const { hideModal, modalData } = useModal(); // modalData, user_id ve diğer bilgileri içerir.
  const [message, setMessage] = useState(""); // Mesaj içeriğini tutmak için state oluşturduk

  const handleSendMessage = async () => {
    try {
      // Mesaj gönderme işleminin başladığını konsola yazıyoruz
      console.log("Mesaj gönderme işlemi başlatıldı.");

      // API'ye istek yapılıyor ve yanıt alınıyor
      const response = await sendMessageToColleague(modalData.user_id, message);
      console.log("API Yanıtı:", response);

      // Yanıtın başarılı olup olmadığını kontrol ediyoruz
      if (response && response.status === "0") {
        toast.success("Mesaj başarıyla gönderildi!");
        hideModal("message-modal");
      } else {
        // Yanıt mesajı yoksa veya yanıt başarılı değilse hata mesajı gösteriyoruz
        const errorMessage =
          response?.message || "Mesaj gönderme başarısız oldu!";
        toast.error(errorMessage);
      }
    } catch (error) {
      console.error("Mesaj gönderme hatası:", error);
      toast.error("Mesaj gönderilirken bir hata oluştu!");
    }
  };

  return (
    <div className="modal-container">
      <div
        id="small-dialog-2"
        className="zoom-anim-dialog dialog-with-tabs modal-content"
      >
        {/* Tabs */}
        <div className="sign-in-form">
          <ul className="popup-tabs-nav" style={{ pointerEvents: "none" }}>
            <li className="active">
              <a href="#tab2">Mesaj Gönder</a>
            </li>
          </ul>
          <div className="popup-tabs-container">
            {/* Tab */}
            <div className="popup-tab-content" id="tab2">
              {/* Welcome Text */}
              <div className="welcome-text">
                {/* modalData ile dinamik kullanıcı adını gösteriyoruz */}
                <h3>
                  {modalData
                    ? `${modalData.userName} adlı kişiye mesaj gönder`
                    : "Mesaj Gönder"}
                </h3>
              </div>
              {/* Form */}
              <form method="post" id="send-pm">
                <textarea
                  name="textarea"
                  cols={10}
                  placeholder="Mesaj içeriği"
                  className="with-border"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)} // Mesaj içeriğini güncelliyoruz
                  required
                />
              </form>
              {/* Button */}
              <button
                className="button full-width button-sliding-icon ripple-effect"
                type="button"
                form="send-pm"
                onClick={handleSendMessage} // Butona tıklandığında handleSendMessage fonksiyonu çalışır
                style={{ width: "30px" }}
              >
                Gönder <i className="icon-material-outline-arrow-right-alt"></i>
              </button>
            </div>
          </div>
        </div>
        <button
          title="Close (Esc)"
          type="button"
          className="mfp-close"
          onClick={() => hideModal("message-modal")}
        ></button>
      </div>
    </div>
  );
};

export default MessageModal;
