import { fetchPlans } from '@/api/public';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';

const CheckoutPlan = () => {
  const params = useParams();
  const [selectedPrice, setSelectedPrice] = useState(0);
  const [plan, setPlan] = useState<any>();

  useEffect(() => {
    const handleLoadPlan = async () => {
      const response = await fetchPlans();

      const p = response.data.find((p: any) => p.id == params.id);
      if (p) {
        setSelectedPrice(p.monthly_price);
        setPlan(response.data.find((p: any) => p.id == params.id));
      }
      
      
    };
    handleLoadPlan();
  }, [params.id]);

  if (!plan) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div id="titlebar" className="gradient">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2>Satın Al</h2>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-xl-8 col-lg-8 content-right-offset">
            <h3>Ödeme Planı</h3>

            <div className="billing-cycle margin-top-25">
              <div className="radio">
                <input
                  id="radio-5"
                  name="radio-payment-type"
                  type="radio"
                  defaultChecked
                  onChange={() => setSelectedPrice(plan.monthly_price)}
                />
                <label htmlFor="radio-5">
                  <span className="radio-label"></span>
                  Aylık Ödeme
                  <span className="billing-cycle-details">
                    <span className="regular-price-tag">{plan.monthly_price}₺ / aylık</span>
                  </span>
                </label>
              </div>

              {/* Radio */}
              <div className="radio">
                <input id="radio-6" name="radio-payment-type" type="radio" onChange={() => setSelectedPrice(plan.yearly_price)} />
                <label htmlFor="radio-6">
                  <span className="radio-label"></span>
                  Yıllık Ödeme
                  <span className="billing-cycle-details">
                    <span className="discounted-price-tag">
                      {plan.yearly_price}₺ / yıllık
                    </span>
                    
                  </span>
                </label>
              </div>
            </div>

            {/* Hedline */}
            <h3 className="padding-top-50">Ödeme Yöntemi</h3>

            {/* Payment Methods Accordion */}
            <div className="payment margin-top-30">
              <div className="payment-tab payment-tab-active">
              <div className="boxed-widget-headline">
              <h3>Kredi Kartı İle Öde</h3></div>
                <div className="payment-tab-trigger">
                  <input
                    type="radio"
                    name="cardType"
                    id="creditCart"
                    value="creditCard"
                    defaultChecked
                  />
                  <label htmlFor="creditCart">Kredi Kartı ile öde</label>
                  <img
                    className="payment-logo"
                    src="https://i.imgur.com/IHEKLgm.png"
                    alt=""
                  />
                </div>

                <div className="payment-tab-content">
                  <div className="row payment-form-row">
                    <div className="col-md-6">
                      <div className="card-label">
                        <input
                          id="nameOnCard"
                          name="nameOnCard"
                          required
                          type="text"
                          placeholder="Kart Sahibinin Adı"
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="card-label">
                        <input
                          id="cardNumber"
                          name="cardNumber"
                          placeholder="Kredi Kartı Numarası"
                          required
                          type="text"
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="card-label">
                        <input
                          id="expiryDate"
                          placeholder="Ay"
                          required
                          type="text"
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="card-label">
                        <input
                          id="expiryYear"
                          placeholder="Yıl"
                          required
                          type="text"
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="card-label">
                        <input
                          id="cvv"
                          required
                          type="text"
                          placeholder="CVV"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="payment-tab payment-tab-active">
                  <div className='row'>
                    <div className="col-xl-12 col-lg-12 margin-top-0">
                      <div className="boxed-widget summary margin-top-0">
                        <div className="boxed-widget-headline">
                          <h3>Kayıtlı Kredi Kartın İle Öde</h3></div>
                          <div className="boxed-widget-inner">
                          <div className="payment-tab-trigger">
                          <input type="radio" name="cardType" id="SavedCard1" value="creditCard"/>
                            <label htmlFor="SavedCard1"><strong>Prince Lakin</strong></label>
                            <img
                              className="payment-logo"
                              src="https://i.imgur.com/IHEKLgm.png"
                              alt=""
                            />
                                      <div className="invoice-list-item">
                                      <ul><li><span className="paid">Kredi Kartı</span></li><li>SKT: N/A</li><li>NO: 1234-****-****-5678</li></ul>
                                      </div>
                          </div>
                          <div className="payment-tab-trigger">
                          <input type="radio" name="cardType" id="SavedCard2" value="creditCard"/>
                            <label htmlFor="SavedCard2"><strong>Prince Lakin</strong></label>
                            <img
                              className="payment-logo"
                              src="https://i.imgur.com/IHEKLgm.png"
                              alt=""
                            />
                                      <div className="invoice-list-item">
                                      <ul><li><span className="paid">Kredi Kartı</span></li><li>SKT: N/A</li><li>NO: 1234-****-****-5678</li></ul>
                                      </div>
                          </div>
                          </div></div>
                    </div>
                  </div>
              </div>
              
            </div>
            {/* Payment Methods Accordion / End */}

            <a
              href="pages-order-confirmation.html"
              className="button big ripple-effect margin-top-40 margin-bottom-65"
            >
              Ödeme Yap
            </a>
          </div>

          {/* Summary */}
          <div className="col-xl-4 col-lg-4 margin-top-0 margin-bottom-60">
            {/* Summary */}
            <div className="boxed-widget summary margin-top-0">
              <div className="boxed-widget-headline">
                <h3>Ödeme Özeti</h3>
              </div>
              <div className="boxed-widget-inner">
                <ul>
                  <li>
                    {plan.name} <span>{selectedPrice}₺</span>
                  </li>
                 
                  <li className="total-costs">
                    Toplam Ödeme <span>{selectedPrice}₺</span>
                  </li>
                </ul>
              </div>
            </div>
            {/* Summary / End */}

            {/* Checkbox */}
            <div className="checkbox margin-top-30">
              <input type="checkbox" id="two-step" />
              <label htmlFor="two-step">
                <span className="checkbox-icon"></span>
                <a href="#">Kullanıcı Sözleşmesini </a> ve{' '}
                <a href="#">Satın Alma Sözleşmesini</a> onaylıyorum.
              </label>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CheckoutPlan
