import React, { useState } from "react";

interface DropdownWithSearchProps {
  options: { value: string; label: string }[];
  placeholder: string;
  onSelect: (value: string) => void;
  selectedValue?: any;
  label?: string;
}

const DropdownWithSearch: React.FC<DropdownWithSearchProps> = ({
  options,
  placeholder,
  onSelect,
  selectedValue,
  label,
}) => {
  const [selectedOption, setSelectedOption] = useState(
    options.find((option) => option.value === selectedValue)?.value || ""
  );
  const [selectedOptionLabel, setSelectedOptionLabel] = useState(
    options.find((option) => option.value === selectedValue)?.label || ""
  );

  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (a: any) => {
    setSelectedOption(a.value);
    onSelect(a.value);
    setSelectedOptionLabel(a.label);
    setIsOpen(false); // Close the dropdown after selection
  };

  // const filteredOptions = options.filter((option) =>
  //   option.label.toLowerCase().startsWith(searchTerm.toLowerCase())
  // )
  const filteredOptions = options.filter((option) =>
    option.label
      ? option.label.toLowerCase().startsWith(searchTerm.toLowerCase())
      : false
  );

  return (
    <>
      {label && <label className="label-input">{label}</label>}

      <div
        className={`btn-group bootstrap-select with-border ${
          isOpen ? "open" : ""
        }`}
      >
        <button
          type="button"
          className="btn dropdown-toggle bs-placeholder btn-default"
          onClick={toggleDropdown}
          aria-expanded={isOpen ? "true" : "false"}
          role="button"
          title={placeholder}
        >
          <span className="filter-option pull-left">
            {selectedOptionLabel || placeholder}
          </span>
          &nbsp;
          <span className="bs-caret">
            <span className="caret"></span>
          </span>
        </button>
        <div
          className={`dropdown-menu ${isOpen ? "open" : ""}`}
          role="combobox"
        >
          <div className="bs-searchbox">
            <input
              type="text"
              className="form-control"
              autoComplete="off"
              role="textbox"
              aria-label="Search"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <ul
            className="dropdown-menu inner"
            role="listbox"
            aria-expanded={isOpen ? "true" : "false"}
          >
            {filteredOptions.length > 0 ? (
              filteredOptions.map((option, index) => (
                <li
                  key={index}
                  className={
                    searchTerm &&
                    option.label
                      .toLowerCase()
                      .startsWith(searchTerm.toLowerCase())
                      ? "active"
                      : ""
                  }
                >
                  <a
                    tabIndex={0}
                    className=""
                    onClick={() => handleSelect(option)}
                    role="option"
                    aria-disabled="false"
                    aria-selected={
                      selectedOption === option.value ? "true" : "false"
                    }
                  >
                    <span className="text">{option.label}</span>
                    {selectedOption === option.value && (
                      <span className="glyphicon glyphicon-ok check-mark"></span>
                    )}
                  </a>
                </li>
              ))
            ) : (
              <li className="no-results">
                "{searchTerm}" uygun sonuç bulunamadı
              </li>
            )}
          </ul>
        </div>
      </div>
    </>
  );
};

export default DropdownWithSearch;
