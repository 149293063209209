import React, { useEffect, useState } from "react";
import userAvatarPlaceholder from "@/images/user-avatar-big-01.jpg";
import trFlag from "@/images/flags/tr.svg";
import Dropdown from "@/components/Dropdown";
import {
  POSITION_OPTIONS,
  sortOptions,
  CandidateSearch_City,
} from "@/libs/constants";
import MultiRangeSlider from "@/components/MultiRangeSlider";
import { Link } from "react-router-dom";
import { searchCandidates } from "@/api/company";

// Utility to get current time in HH:MM:SS format
const getCurrentTimeFormatted = (): string => {
  const date = new Date();
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");
  return `${hours}:${minutes}:${seconds}`;
};

// Utility to add hours to current time
const addHoursToCurrentTime = (hoursToAdd: number): string => {
  const date = new Date();
  date.setHours(date.getHours() + hoursToAdd);
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");
  return `${hours}:${minutes}:${seconds}`;
};

const SearchWorkspace = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState<string>(
    sortOptions[0].value
  );
  const [candidates, setCandidates] = useState<any[]>([]); // State to store candidates
  const [city, setCity] = useState<number | null>(null); // Example state to track city selection
  const [position, setPosition] = useState<string | null>(null); // Example state to track position selection
  const [salaryRange, setSalaryRange] = useState<{ min: number; max: number }>({
    min: 3000,
    max: 6000,
  });

  const handleSearch = async () => {
    try {
      const payload = {
        province_id: city || 0, // Assuming city is stored as an id
        business_area_id: position ? parseInt(position) : 0, // Assuming position is stored as a string id
        district_id: 0,
        age_min: 18,
        age_max: 35,
        start_time: "02:06:17.372Z", // Dynamic current time
        end_time: "02:06:17.372Z", // Dynamic end time, 4 hours later
        salary_min: salaryRange.min,
        salary_max: salaryRange.max,
        rating_min: 3,
        education_level: "PRIMARY_SCHOOL",
        language_level: "BEGINNER",
        experienced_candidate: true,
        disabled_candidate: false,
        retired_candidate: false,
        driving_license: true,
        military_service: true,
        turkish_citizen: true,
        only_photo_candidate: true,
        only_verified_candidate: false,
        only_favorite_candidate: false,
      };

      // Fetch candidates using the API function
      const response = await searchCandidates(payload);
      setCandidates(response.data.working_schedule_model); // Set candidates data in state
    } catch (error) {
      console.error("Error fetching candidates:", error);
    }
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (value: string) => {
    setSelectedValue(value);
    setIsOpen(false); // Optionally close the dropdown when an option is selected
  };

  return (
    <div className="row">
      <div className="col-md-3">
        <div className="sidebar-container">
          {/* Sidebar filters */}
          <div className="sidebar-widget">
            <h3>Şehir</h3>
            <Dropdown
              options={CandidateSearch_City}
              placeholder="Şehir"
              label=""
              onSelect={(value) => setCity(Number(value))} // Set city from dropdown
            />
          </div>

          <div className="sidebar-widget">
            <h3>Cinsiyet</h3>
            <div className="keywords-container">
              <div className="keyword-input-container">
                <div className="radio padding-right-4">
                  <input
                    id="radio-1"
                    name="radio"
                    type="radio"
                    defaultChecked
                  />
                  <label htmlFor="radio-1">
                    <span className="radio-label"></span> Tümü
                  </label>
                </div>
                <div className="radio padding-right-4 mx-3">
                  <input id="radio-2" name="radio" type="radio" />
                  <label htmlFor="radio-2">
                    <span className="radio-label"></span> Erkek
                  </label>
                </div>
                <div className="radio">
                  <input id="radio-3" name="radio" type="radio" />
                  <label htmlFor="radio-3">
                    <span className="radio-label"></span> Kadın
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div className="sidebar-widget">
            <h3>Yaş Aralığı</h3>
            <MultiRangeSlider
              min={18}
              max={65}
              onChange={({ min, max }) => {
                // You can track min and max age here if needed
              }}
            />
          </div>

          <div className="sidebar-widget">
            <h3>Pozisyon</h3>
            <Dropdown
              options={POSITION_OPTIONS}
              placeholder="Pozisyon"
              label=""
              onSelect={(value) => setPosition(value)} // Set position from dropdown
            />
          </div>

          <div className="sidebar-widget">
            <h3>Çalışma Şekli</h3>
            <div className="switches-list">
              <div className="switch-container">
                <label className="switch">
                  <input type="checkbox" />
                  <span className="switch-button"></span>
                  Uzaktan Çalışma
                </label>
              </div>
              <div className="switch-container">
                <label className="switch">
                  <input type="checkbox" />
                  <span className="switch-button"></span>
                  Tam Zamanlı
                </label>
              </div>
            </div>
          </div>

          <div className="sidebar-widget">
            <h3>Maaş Aralığı</h3>
            <MultiRangeSlider
              min={15000}
              max={100000}
              onChange={({ min, max }) => setSalaryRange({ min, max })} // Set salary range from slider
            />
          </div>

          <div className="sidebar-widget">
            <h3>Çalışma Saatleri</h3>
            <MultiRangeSlider
              min={8}
              max={24}
              onChange={({ min, max }) => {
                // You can track working hours here if needed
              }}
            />
          </div>

          <div className="col">
            <button
              className="button full-width button-sliding-icon ripple-effect margin-top-30"
              type="button"
              onClick={handleSearch} // Trigger search on button click
            >
              Arama Yap{" "}
              <i className="icon-material-outline-arrow-right-alt"></i>
            </button>
          </div>
        </div>
      </div>

      <div className="col-md-9 content-left-offset">
        <h3 className="page-title">Aday Listesi</h3>

        <div className="notify-box margin-top-15">
          <div className="switch-container">
            <label className="switch">
              <input type="checkbox" />
              <span className="switch-button"></span>
              <span className="switch-text">
                İş başvurusu yapıldığında e-posta ile bilgilendirme yap
              </span>
            </label>
          </div>

          {/* <div className="sort-by">
            <span>Sırala:</span>
            <select className="selectpicker hide-tick">
              <option>Yüksek Puanlar</option>
              <option>Çok Değerlendirilenler</option>
              <option>Ücret(Yüksekten Düşüğe)</option>
              <option>Ücret(Düşükten Yükseğe)</option>
              <option>Yaş(Yüksekten Düşüğe)</option>
              <option>Yaş(Düşükten Yükseğe)</option>
            </select>
          </div> */}
        </div>

        {/* Freelancers List Container */}
        <div className="freelancers-container freelancers-list-layout margin-top-35">
          {candidates.length === 0 ? (
            <p>Aday listesi yükleniyor...</p>
          ) : (
            candidates.map((candidate) => (
              <div className="freelancer" key={candidate.id}>
                <div className="freelancer-overview">
                  <div className="freelancer-overview-inner">
                    {/* Bookmark Icon */}
                    <span className="bookmark-icon"></span>

                    {/* Avatar */}
                    <div className="freelancer-avatar">
                      <div className="verified-badge"></div>
                      <a href="#">
                        <img
                          src={
                            candidate.user.avatar?.url || userAvatarPlaceholder
                          }
                          alt=""
                        />
                      </a>
                    </div>

                    {/* Name */}
                    <div className="freelancer-name">
                      <h4>
                        <a href="#">
                          {candidate.user.first_name} {candidate.user.last_name}{" "}
                          <img
                            className="flag"
                            src={trFlag}
                            alt=""
                            title="Türkiye"
                            data-tippy-placement="top"
                          />
                        </a>
                      </h4>
                      <span>{candidate.user.role}</span>
                      <div className="freelancer-rating">
                        {candidate.user.rate > 0 ? (
                          <div
                            className="star-rating"
                            data-rating={candidate.user.rate}
                          ></div>
                        ) : (
                          <span className="company-not-rated margin-bottom-5">
                            Henüz yeterli değerlendirme almadı
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                {/* Details */}
                <div className="freelancer-details">
                  <div className="freelancer-details-list">
                    <ul>
                      <li>
                        Şehir{" "}
                        <strong>
                          <i className="icon-material-outline-location-on"></i>{" "}
                          {candidate.locations[0]?.province?.name} /{" "}
                          {candidate.locations[0]?.district?.name}
                        </strong>
                      </li>
                      <li>
                        Maaş <strong>{candidate.min_salary}₺/aylık</strong>
                      </li>
                      <li>
                        İş Bitirme{" "}
                        <strong>%{candidate.user.review_count}</strong>
                      </li>
                    </ul>
                  </div>
                  <Link to={`/employer/FreelancerProfile/${candidate.user.id}`}>
                    <a
                      href="#"
                      className="button button-sliding-icon ripple-effect"
                    >
                      Profili İncele{" "}
                      <i className="icon-material-outline-arrow-right-alt"></i>
                    </a>
                  </Link>
                </div>
              </div>
            ))
          )}
        </div>

        {/* Pagination */}
        <div className="clearfix"></div>
        <div className="row">
          <div className="col-md-12">
            <div className="pagination-container margin-top-40 margin-bottom-60">
              <nav className="pagination">
                <ul>
                  <li className="pagination-arrow">
                    <a href="#" className="ripple-effect">
                      <i className="icon-material-outline-keyboard-arrow-left"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#" className="current-page ripple-effect">
                      1
                    </a>
                  </li>
                  <li>
                    <a href="#" className="ripple-effect">
                      2
                    </a>
                  </li>
                  <li>
                    <a href="#" className="ripple-effect">
                      3
                    </a>
                  </li>
                  <li className="pagination-arrow">
                    <a href="#" className="ripple-effect">
                      <i className="icon-material-outline-keyboard-arrow-right"></i>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchWorkspace;
