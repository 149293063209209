import OtpCode from '@/components/Otp';
import TokenService from '../../../store/token'
import { useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import store, { setUser } from '@/store';
import { fetchForgotPasswordMailVerification, fetchLoginMailVerification, fetchLoginPhoneVerification, fetchMe, fetchRegisterMailVerification, fetchRegisterPhoneVerification } from '@/api/user';
import { debug } from 'console';
import { setTempAccessToken } from '@/store/user';

const ConfirmationCode = ({type, action}:any) => {
  const [tempToken, setTempToken] = useState(TokenService.getTempToken())

  const navigate = useNavigate();


  
  const handleOtpSubmit = (otp: string[]) => {
    const code = otp.join('');
    const handleRegisterMailVerification = async () => {
      const response = await fetchRegisterMailVerification(code);
      
      
      if(response.data.verification_required == 'SMS'){
        
        store.dispatch(setTempAccessToken(response.data));
        navigate('/register/verify-phone');
      }else if(response.data.verification_required == 'NONE'){
        
        store.dispatch(setUser(response.data));
        navigate('/');
      }else if(response.status !== '0'){
        toast.error('Hatalı kod girdiniz. Lütfen tekrar deneyin.');
      }
    }



    const handleRegisterPhoneVerification = async () => {
      const response = await fetchRegisterPhoneVerification(code);
      
      
      if(response.data.verification_required == 'NONE'){
        store.dispatch(setUser(response.data));
        navigate('/');
      }else if(response.data.verification_required == 'EMAIL'){
        store.dispatch(setTempAccessToken(response));
        navigate('/register/verify-email');
      }else if(response.data.verification_required == 'SMS'){
        store.dispatch(setTempAccessToken(response));
        navigate('/register/verify-phone');
      }else if(response.status !== '0'){
        toast.error('Hatalı kod girdiniz. Lütfen tekrar deneyin.');
      }

    }


    const handleLoginPhoneVerification = async () => {
      const response = await fetchLoginPhoneVerification(code);
        
      if(response.verification_required == 'SMS'){
        store.dispatch(setTempAccessToken(response));
        navigate('/login/verify-phone');
      }else if(response.verification_required == 'EMAIL'){
        store.dispatch(setTempAccessToken(response));
        navigate('/login/verify-email');
      }else if(response.verification_required == 'NONE'){
        store.dispatch(setUser(response));
        navigate('/');
      }else if(response.status !== '0'){
        toast.error('Hatalı kod girdiniz. Lütfen tekrar deneyin.');
      }
    }


    const handleLoginMailVerification = async () => {
      const response = await fetchLoginMailVerification(code);
      
      if(response.verification_required == 'SMS'){
        store.dispatch(setTempAccessToken(response));
        navigate('/login/verify-phone');
      }else if(response.verification_required == 'EMAIL'){
        store.dispatch(setTempAccessToken(response));
        navigate('/login/verify-email');
      }else if(response.verification_required == 'NONE'){
        store.dispatch(setUser(response));
        navigate('/');        
      }else if(response.status !== '0'){
        toast.error('Hatalı kod girdiniz. Lütfen tekrar deneyin.');
      }
    }

    const handleForgotPassword = async () => {
      const response = await fetchForgotPasswordMailVerification(code);
      
      if(response.status === '0'){
        navigate('/forgot-password/reset');
      }else{
        toast.error('Hatalı kod girdiniz. Lütfen tekrar deneyin.');
      }
    }

    if(action === 'login'){
      if(type === 'phone')
        handleLoginPhoneVerification();

      else if(type === 'mail')
        handleLoginMailVerification();


    }else if(action === 'register'){
      if(type === 'phone')
        handleRegisterPhoneVerification();
    
      else if(type === 'mail')
        handleRegisterMailVerification();
      
    }else if(action === 'forgot-password'){
      handleForgotPassword();
    }


  };

  return (
    <div>
      <OtpCode
        key={'otp_code' + type + action}
        otpLength={6}
        onSubmit={handleOtpSubmit}
        title="Hesabını onayla"
        subtitle={type === 'phone' ? "Telefon numaranıza gönderdiğimiz 6 haneli onay kodunu girin." : "Email adresinize gönderdiğimiz 6 haneli onay kodunu girin."}
        description={type === 'phone' ? "Onay kodunun gönderildiği telefon numarası:" : "Onay kodunun gönderildiği email adresi:"}
        email={type === 'phone' ? tempToken?.phone_number : tempToken?.email}
        expirationTime={tempToken?.verification_code_expiration}
      />
    </div>
  );
}

export default ConfirmationCode