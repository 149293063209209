import { useEffect } from "react";
import feather from "feather-icons";
import { Link } from "react-router-dom";
import Logo from "../images/logo.png";
import MenuDropdown from "./MenuDropdown";
import NotificationDropdown from "./NotificationDropdown";
import EmailDropdown from "./EmailDropdown";
import { useMenu } from "../contexts/MenuContext";
import { useSelector } from "react-redux";
import { RootState } from "@/store";
import LogInRegister from "./LogInRegister";
import TokenService from "@/store/token";

const Navbar = () => {
  // const user = useSelector((state: RootState) => state.user);
  const isLoggedIn = useSelector((state: RootState) => state.user.isLoggedIn);

  const { showMenu, setShowMenu } = useMenu();
  // useEffect(() => {
  //   // Initialize Feather icons
  //   feather.replace();
  // }, []);

  useEffect(() => {
    // Feather ikonlarını DOM'a eklendikten sonra yeniden değiştirme
    feather.replace();
  }, [isLoggedIn]);

  // useEffect(() => {
  //   console.log("user state changed", user.isLoggedIn);
  // }, [user.isLoggedIn]);

  useEffect(() => {
    console.log("user state changed", isLoggedIn);
  }, [isLoggedIn]);

  return (
    <header id="header-container" className="fullwidth">
      <div id="header">
        <div className="container">
          <div className="left-side">
            <div id="logo">
              <Link to="/">
                <img src={Logo} alt="" />
              </Link>
            </div>

            <nav id="navigation">
              <ul id="responsive">
                <li>
                  <Link to="/" className="current">
                    <a href="#">Anasayfa</a>
                  </Link>
                </li>
                <li>
                  <Link to="/job-list">
                    <a href="#">İş İlanları</a>
                  </Link>
                </li>
                {isLoggedIn && (
                  <li>
                    <Link to="/employer/company-info/create-job-posting">
                      <a href="#">İlan Ver</a>
                    </Link>
                  </li>
                )}

                <li>
                  <Link to="/contact">
                    <a href="#">İletişim</a>
                  </Link>
                </li>
              </ul>
            </nav>
            <div className="clearfix"></div>
          </div>

          <div className="right-side">
            {/* <NotificationDropdown />
              <EmailDropdown /> */}
            {/* <div className="header-notifications">
                <div className="header-notifications-trigger"> */}
            {isLoggedIn && <NotificationDropdown />}
            {isLoggedIn && <EmailDropdown />}
            {/* </div>
              </div> */}
            {/* <LogInRegister /> */}
            {!isLoggedIn && <LogInRegister />}
            {isLoggedIn && <MenuDropdown />}

            <span
              className="mmenu-trigger"
              onClick={() => setShowMenu(!showMenu)}
            >
              <button className="hamburger hamburger--collapse" type="button">
                <span className="hamburger-box">
                  <span className="hamburger-inner"></span>
                </span>
              </button>
            </span>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
