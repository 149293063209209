import Input from "@/components/Input";
import { signupSchemaStage1 } from "@/libs/schemes/signup";
import { ISingupColleague } from "@/types/user";
import { Form, Formik, FormikHelpers } from "formik";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import TokenService from "@/store/token";
import store, { setUser } from "@/store";
import { fetchMe, fetchAddColleague } from "@/api/user";
import { toast } from "react-toastify";
import { setTempAccessToken } from "@/store/user";

import { BarLoader } from "react-spinners";
import { signupSchemaColleague } from "@/libs/schemes/signupColleague";

const ColleagueAdd = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(true);
  const navigate = useNavigate();

  const handleSubmit = async (
    values: ISingupColleague,
    { setSubmitting }: FormikHelpers<ISingupColleague>
  ) => {
    setError(false);
    try {
      console.log("try öncesi");
      console.log(
        values.first_name,
        values.last_name,
        values.email,
        values.phone,
        values.password
      );
      const response: any = await fetchAddColleague(
        values.first_name,
        values.last_name,
        values.email,
        values.phone,
        values.password,
        values.confirm_password
      );
      console.log("try sonrası");

      if (response.status === "0") {
        store.dispatch(setTempAccessToken(response.data));
        navigate("/employer/company-info/colleague");
        // setIsLoading(false);
      } else {
        setError(true);
        toast.error(response.message);
        // setIsLoading(false);
      }
    } catch (error) {
      console.log("Catch");
      setError(true);
      console.error("Beklenmeyen bir hata oluştu:", error);
      // setIsLoading(false);
    } finally {
      setSubmitting(false);
      console.log("final");
      // setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading ? (
        <BarLoader
          loading={isLoading}
          aria-label="Loading Spinner"
          width={"100%"}
        />
      ) : (
        <div className="col-md-12 dashboard-box margin-top-0 margin-bottom-50">
          <div
            className="headline"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h3>
              <i className="icon-feather-user-plus"></i> Çalışma Arkadaşını Ekle
            </h3>
          </div>
          <Formik
            initialValues={{
              first_name: "",
              last_name: "",
              email: "",
              phone: "",
              password: "",
              confirm_password: "",
            }}
            validationSchema={signupSchemaColleague}
            onSubmit={handleSubmit} // Formik onSubmit fonksiyonu
          >
            {({ isSubmitting }) => (
              <Form className="form-container">
                <div className="col-md-8 offset-md-2">
                  <div className="login-register-page">
                    <div id="register-account-form">
                      <h5 className="add-co-h">Çalışma Arkadaşı Adı</h5>
                      <div
                        className="input-with-icon-left"
                        title="Adınızı girin"
                        data-tippy-placement="bottom"
                      >
                        <Input
                          type="text"
                          name="first_name"
                          id="first_name"
                          placeholder="Adını Giriniz"
                          startIcon="icon-material-outline-account-circle"
                        />
                      </div>
                      <h5 className="add-co-h">Çalışma Arkadaşı Soyadı</h5>
                      <div
                        className="input-with-icon-left"
                        title="Soyadınızı girin"
                        data-tippy-placement="bottom"
                      >
                        <Input
                          type="text"
                          name="last_name"
                          id="last_name"
                          placeholder="Soyadını Giriniz"
                          startIcon="icon-material-outline-account-circle"
                        />
                      </div>
                      <h5 className="add-co-h">
                        Çalışma Arkadaşı Email Adresi
                      </h5>
                      <div
                        className="input-with-icon-left"
                        title="Email adresinizi girin"
                        data-tippy-placement="bottom"
                      >
                        <Input
                          type="text"
                          name="email"
                          id="email"
                          placeholder="E-mail Adresini Giriniz"
                          startIcon="icon-material-baseline-mail-outline"
                        />
                      </div>
                      <h5 className="add-co-h">
                        Çalışma Arkadaşı Telefon Numarası
                      </h5>
                      <div
                        className="input-with-icon-left"
                        title="Telefon numaranız"
                        data-tippy-placement="bottom"
                      >
                        <Input
                          id="phone"
                          name="phone"
                          type="phone"
                          placeholder="(555) 555 55 55"
                          startIcon="icon-line-awesome-phone-square"
                        />
                      </div>
                      <div
                        className="input-with-icon-left"
                        title="Şifreniz en az 8 karakter içermelidir"
                        data-tippy-placement="bottom"
                      >
                        <Input
                          type="password"
                          name="password"
                          id="password"
                          placeholder="Şifreniz"
                          startIcon="icon-material-outline-lock"
                        />
                      </div>
                      <div
                        className="input-with-icon-left"
                        title="Şifrenizi tekrar girin"
                        data-tippy-placement="bottom"
                      >
                        <Input
                          type="password"
                          name="confirm_password"
                          id="confirm_password"
                          placeholder="Şifrenizi tekrar girin"
                          startIcon="icon-material-outline-lock"
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-5">
                        <button
                          className="button full-width button-sliding-icon ripple-effect margin-top-10"
                          type="submit"
                          disabled={isSubmitting}
                          // onClick={handleSubmit}
                        >
                          Çalışma Arkadaşı Ekle{" "}
                          <i className="icon-material-outline-arrow-right-alt"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </>
  );
};

export default ColleagueAdd;
