import { fetchForgotPassword } from '@/api/account';
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Form, Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import Input from '@/components/Input';
import { toast } from 'react-toastify';

const signinSchema = Yup.object({
  email: Yup.string()
    .email("Geçersiz email adresi")
    .required("Email adresiniz gerekli!")
});


const Login = () => {
  const navigate = useNavigate();
  const handleSubmit = async (
    values: any, 
    { setSubmitting }: any
  ) => {
    
    const response = await fetchForgotPassword(values.email);
    
    if(
      response.status === '0' &&
      response.data.access_token){
      localStorage.setItem('tempToken', JSON.stringify(response.data));
      navigate('/forgot-password/verification');
    }else{
      
      toast.error(response.message);
    }
      
  };


  return (
    <Formik
    id="login-form"
    initialValues={{
      email: ""
    }}
    onSubmit={handleSubmit}
    validationSchema={signinSchema}
  >
    {({ errors, touched }) => (
      <Form id="login-form">
      <div className='form-container'>
        <div className="row l-container">
          <div className="col-md-8 offset-md-2">
            <div className="login-register-page">
              <div className="welcome-text">
                <h3>Şifremi Unuttum</h3>
                <span>
                  Hesabın yok mu? <Link to="/register">Üye Ol!</Link>
                </span>
              </div>

              <form method="post" id="login-form">
                <div
                  className="input-with-icon-left"
                  title="Email adresinizi girin"
                  data-tippy-placement="bottom"
                >
                  <i className="icon-material-baseline-mail-outline"></i>
                  <Input
                        type="text"
                        className="input-text with-border"
                        name="email"
                        id="email"
                        placeholder="Email Adresinizi Giriniz!"
                        required
                      />
                     
                  
                </div>
              </form>

              <button
                className="button full-width button-sliding-icon ripple-effect margin-top-10"
                type="submit"
                
              >
                Yenileme Kodu Gönder{' '}
                <i className="icon-material-outline-arrow-right-alt"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      </Form>
    )}
  </Formik>
  )
}

export default Login
