import axios from 'axios';
import api from './api'; // Adjust the import based on your project structure

export const fetchPublicJobs = async (data:any) => {
    const result = await api.post('/job/public/search', data);
    return result as any;
}

export const fetchJobDetailPublic = async (job_id: string) => {
    const result = await api.get(`/job/public/${job_id}`);
    return result as any;
}