import React from "react";
import { Link } from "react-router-dom";

const Monthly = ({ plans }: any) => {
  console.log(plans)
  if (!plans)
    return <div></div>;

  return (
    <>
    
      <div className="pricing-plans-container">
        {plans.map((plan: any, index: number) => (
          <div className={`pricing-plan ${index === 1 ? "recommended" : ""}`}>
            {index === 1 && <div className="recommended-badge">Önerilen</div>}
            <h3>{plan.name}</h3>
            <p className="margin-top-10">{plan.description}</p>
            <div className="pricing-plan-label billed-monthly-label">
            <strong>{plan.monthly_price}₺</strong> / aylık
          </div>
            <div className="pricing-plan-features">
              <strong>Plan İçeriği</strong>
              <ul>
                <li>{plan.max_job_post} İş İlanı</li>
                <li>{plan.max_resume_view} CV Görüntüleme</li>
                <li>{plan.location_count} Konum</li>
                <li>{plan.search_by_location ? "Konuma Göre Arama" : "Konuma Göre Arama Yok"}</li>

              </ul>
            </div>
            {plan.monthly_price > 0 && (
              <Link to={'/checkout-plan/' + plan.id} className="button full-width margin-top-20">
              Satın Al
            </Link>
            )}

          </div>
        ))}


      </div>
    </>
  );
};

export default Monthly;
